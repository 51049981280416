import React from "react";
import { connect } from "react-redux";
import {
  setVendorSubTabNo,
  setVendorTabNo,
} from "../../redux/actions/vendor/vendorActions";
import VendorScreen from "../../components/screen/vendor/VendorScreen";
import {
  getCalculatedSurvey,
  getNewArticles,
  getPublicReports,
} from "../../redux/actions/riskEvaluator/riskEvaluatorActions";
import {
  addMember,
  editMember,
  viewMember,
} from "../../redux/actions/member/member";
import { shareSurvey } from "../../redux/actions/brm/BrmActions";
import { getSurveyeQuestions } from "../../redux/actions/corporateSurvey/corporateSurveyActions";
import { setOrganizationDetails } from "../../redux/actions/organization/organizationAction";
import { updateVendorOrganization } from "../../redux/actions/vendorActions/vendorAction";

function VendorCont(props) {
  return <VendorScreen {...props} />;
}
export const mapStateToProps = (store) => ({
  login: store.login,
  brmData: store.brmData,
  corporateSurvey: store.corporateSurvey,
  riskEvaluator: store.riskEvaluator,
  vendor: store.vendorReducer,
});

export const mapDispatchToProps = (dispatch) => ({
  setVendorTabNo: (payload) => {
    dispatch(setVendorTabNo(payload));
  },
  setVendorSubTabNo: (payload) => {
    dispatch(setVendorSubTabNo(payload));
  },
  getNewArticles: (token, isin, limit, skip,filter) => {
    dispatch(getNewArticles(token, isin, limit, skip,filter));
  },

  getPublicReports: (token, organization_id) => {
    dispatch(getPublicReports(token, organization_id));
  },
  viewMember: (token, organization_id) => {
    dispatch(viewMember(token, organization_id));
  },
  editMember: (token, user_id, organization_id, role, category) => {
    dispatch(editMember(token, user_id, organization_id, role, category));
  },
  addMember: (
    token,
    bank_id,
    organization_id,
    name,
    email,
    role,
    category,
    type,
    url
  ) => {
    dispatch(
      addMember(
        token,
        bank_id,
        organization_id,
        name,
        email,
        role,
        category,
        type,
        url
      )
    );
  },
  shareSurvey: (token, organization_id, email) => {
    dispatch(shareSurvey(token, organization_id, email));
  },
  getCalculatedSurvey: (token, organization_id) => {
    dispatch(getCalculatedSurvey(token, organization_id));
  },
  getSurveyeQuestions: (token, organization_id) => {
    dispatch(getSurveyeQuestions(token, organization_id));
  },
  setOrganizationDetails: (payload) => {
    dispatch(setOrganizationDetails(payload));
  },
 
  updateVendorOrganization: (
    token,
    organization_id,
    name,
    isin,
    year_of_assessment,
    logo,
    sectors,
    operation,
    no_of_employees,
    address,
    masterSectors,
    revenue, turnoverData,
    country_code
  ) => {
    dispatch(
      updateVendorOrganization(
        token,
        organization_id,
        name,
        isin,
        year_of_assessment,
        logo,
        sectors,
        operation,
        no_of_employees,
        address,
        masterSectors,
        revenue,  turnoverData,
        country_code
      )
    );
  },
  
});
export default connect(mapStateToProps, mapDispatchToProps)(VendorCont);
