import React from "react";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptUpdatedList from "./EsgGptUpdatedList";
import EsgGptCompanyForm from "./EsgGptCompanyForm";


function EsgGptAddNewCompany(props) {
  return (
    <EsgGptlayout EsgCompanyList={<EsgGptUpdatedList />} login={props.login} logOut={props.logOut}>
      <EsgGptCompanyForm/>
    </EsgGptlayout>
  );
}

export default EsgGptAddNewCompany;
