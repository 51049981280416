import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";

export function CompanyTitle({ title }) {
  return (
    <Typography
      style={{
        color: "#15314E",
        fontSize: "22px",
        fontWeight: 600,
        lineHeight: "20px",
        padding: "10px 0px 10px 10px",
      }}
    >
      {title}
    </Typography>
  );
}

export function CompanyDetailRow({ heading, info, is_premium }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        padding: "5px",
        flexWrap: "wrap",
        gap: "15px",
        
      }}
    >
      <div style={{ width: "20%" }}>
        <Typography
          style={{
            color: "#15314E",
            fontSize: "13px",
            fontWeight: 500,
            lineHeight: "20px",
            padding: "10px 0px 10px 10px",
          }}
        >
          {heading}
        </Typography>
      </div>
      <div style={{ width: "5%" }}>
        <Typography
          style={{
            color: "#15314E",
            fontSize: "13px",
            fontWeight: 500,
            lineHeight: "20px",
            padding: "10px 0px 10px 10px",
          }}
        >
          :
        </Typography>
      </div>
      <div style={{ width: "68%", filter: is_premium ? "blur(0px)" : "blur(5px)" }}>
        <Typography
          style={{
            color: "#15314E",
            fontSize: "13px",
            fontWeight: 500,
            lineHeight: "20px",
            padding: "10px 00px 10px 10px",
            wordBreak: "break-word",
          }}
        >
          {info}
        </Typography>
      </div>
    </div>
  );
}

function EsgGptNewCompanyDetails({ height = "100%", is_premium }) {
  const { newOrganization } = useSelector((state) => state.esgGptReducer);
  const pageTopRef = useRef(null);
  const scrollToTop = () => {
    pageTopRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[newOrganization])
  return (
    <div
      style={{
        height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <div ref={pageTopRef} />
      <CompanyTitle title={newOrganization?.name} />

      <Divider />

      <CompanyDetailRow heading={"Sector"} info={newOrganization?.sector} is_premium={true} />

      <Divider />

      <CompanyDetailRow heading={"Industry"} info={newOrganization?.industry} is_premium={true} />

      <Divider />

      <CompanyDetailRow
        heading={"Revenue"}
        info={newOrganization?.revenue || ""}
        is_premium={is_premium}
      />

      <Divider />
    </div>
  );
}

export default EsgGptNewCompanyDetails;
