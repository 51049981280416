import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

// redux imports
// import { useSelector } from "react-redux";

// icons
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  rootSelect: {
    padding: "5px 14px",
  },
}));

const ResponseSectionTopCard = ({selectedResCompany, total_no_of_questions, surveyStatus}) => {
  const classes = useStyles();
  // from redux
//   const { selectedResCompany?, total_no_of_questions } = useSelector(
//     (state) => state.riskDashboard
//   );
  const [dropdownValue, setDropdownValue] = useState("");

  // --------------- inner functions ---------------
  useEffect(() => {
    let frequency =
      selectedResCompany?.frequency[selectedResCompany?.frequency.length - 1];
    setDropdownValue(frequency);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResCompany]);
  // Function to handle dropdown value change
  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  // Function to generate options for popup

  const MakeCard = ({ heading, value, type }) => {
    const key = `${heading}-${value}`;
    console.log(value, type)
    return (
      <Box
        key={key}
        sx={{
          m: 1,
          width: "260px",
          height: "96px",
          borderRadius: "8px",
          padding: "16px 0px 16px 0px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            marginBottom: 10,
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "21px",
            color: "#15314E",
          }}
        >
          {heading}
        </Typography>
        {type === "status" ? (
          <Box
            sx={{
              padding: "4px 8px 4px 8px",
              borderRadius: "80px",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              height: "24px",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: value ? "#154328" : "#8C7038",
              backgroundColor: value ? "#BFE2CD" : "#FFEFD0",
            }}
          >
            <FiberManualRecordIcon
              style={{ width: 8, height: 8, marginRight: 5 }}
            />
            {value ? "Complete" : "Incomplete"}
          </Box>
        ) : type === "common" ? (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        ) : type === "popup-quoter" || type === "popup-monthly" ? (
          <Select
            value={dropdownValue}
            onChange={(e) => {
              handleDropdownChange(e);
            }}
            variant="outlined"
            classes={{ root: classes.rootSelect }}
            style={{
              width: "80%",
              maxHeight: "30px",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#15314E",
              boxShadow: "#C0D4E9",
              backgroundColor: "#FEFEFE",
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  padding: "0px 6px",
                },
              },
            }}
          >
            {selectedResCompany?.frequency.map((frequency) => (
              <MenuItem
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    dropdownValue === frequency.name
                      ? "#EBF1F8"
                      : "transparent",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
                key={frequency.name}
                value={frequency}
              >
                {frequency.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        )}
      </Box>
    );
  };

  // -----------------------------------------------

console.log(selectedResCompany);
  return (
    <Box
      sx={{
        m: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <MakeCard
        heading="Role of the stakeholder"
        value={selectedResCompany?.role}
        type={"common"}
      />
      <MakeCard
        heading="Survey status"
        value={surveyStatus}
        type={"status"}
      />
      <MakeCard
        heading="No. of Survey Questions"
        value={total_no_of_questions}
        type={"common"}
      />
      <MakeCard
        heading={`Frequency: ${selectedResCompany?.frequency[0]?.type}`}
        value={null}
        type={
          selectedResCompany?.frequency[0].type === "Monthly"
            ? "popup-monthly"
            : "popup-quoter"
        }
      />
    </Box>
  );
};

export default ResponseSectionTopCard;
