import React, { useEffect, useState } from "react";
import CustomTextField from "../../UI/textfield/textfield";
import {
  Typography,
  makeStyles,
  RadioGroup,
  Select,
  FormControl,
  Grid,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import CustomTextfield from "../../UI/textfield/textfield";
import RadioButton from "../../UI/radioButton/radioButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubmittedResponse,
  getSurveyRespondedUser,
  getSurveyResponses,
  setResponseCompany,
  setSelectedSection,
  setSubmittedQuestions,
} from "../../../redux/actions/riskdashboard/riskDashboardActions";
import QuestionBody from "../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";
import { setSelectedOption } from "../../../redux/actions/corporateSurvey/corporateSurveyActions";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import OptimizeInitialRender from "../riskAssessment/brmDataRight.js/OptimizeInitialRender";
import NoData from "../NoData/NoData";

const responseStyles = makeStyles((theme) => ({
  questionCont: {
    border: "none",
    borderRadius: 5,

    width: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    padding: "16px 0px",
  },
  question: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    border: "1px solid #E9E9E9",
    backgroundColor: "#FCFCFC",
    borderRadius: "6px",
    marginLeft: 5,
    width: "80%",
  },
  weightage: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    border: "1px solid #EBF1F8",
    backgroundColor: "#EBF1F8",
    borderRadius: "6px",
    marginLeft: 5,
    width: "18%",
    textAlign: "center",
    height: "fit-content",
  },
  userName: {
    padding: 6,
    margin: "8px 5px",
    fontSize: 13,
    backgroundColor: "#EBF1F8",
    borderRadius: 5,
    cursor: "pointer",
  },
}));
export const Wrapper = ({
  users,
  children,
  hidesearch = false,
  width = "22%",
  selected,
  setSelected,
  handleUserClick,
}) => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState(users);
  // console.log(users);
  useEffect(() => {
    setUsersList(users);
  }, [users]);
  const classes = responseStyles();
  const searchUsers = (e) => {
    if (e.target.value.trim() === "") setUsersList(users);
    else {
      const filtered = users.filter((user) =>
        user.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUsersList(filtered);
    }
  };
  return (
    <div style={{ display: "flex", padding: "10px 0px", width: "100%" }}>
      <div style={{ width: width, backgroundColor: "#FBFBFB" }}>
        {!hidesearch && (
          <CustomTextField
            variant="outlined"
            size="small"
            placeholder="search"
            fullwidth
            onChange={searchUsers}
          />
        )}
        <div
          className="scroll"
          style={{ height: "48vh", marginTop: 8, paddingBottom: 12 }}
        >
          {usersList?.length > 0 ? (
            usersList?.map((user, i) => (
              <Typography
                className={classes.userName}
                style={{
                  fontWeight: selected === i ? 500 : 400,
                }}
                role="button"
                onClick={() => {
                  setSelected(i);
                  handleUserClick(user, user?._id);
                }}
              >
                {user?.name ? user?.name : user?.title}
              </Typography>
            ))
          ) : (
            <Typography>No Users Found</Typography>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
export const survey = [
  { name: "Self Assessment", isSelected: true },
  { name: "Controversies", isSelected: false },
  { name: "Banking Policy", isSelected: false },
];

export const Question = ({
  question,
  type,
  options,
  weightage = 0,
  questionId,
  submittedAnswer = null,
  removeEditOption = false,
  comment = false,
}) => {
  const classes = responseStyles();
  // const dispatch = useDispatch();
  const { publishAnswer } = useSelector((state) => state?.corporateSurvey);

  const [checkBoxOption, setCheckBoxOption] = React.useState([]);

  const handleAnswer = publishAnswer.find((a) => a?.question_id === questionId);

  return (
    <div className={classes.questionCont}>
      <div style={{ display: "flex", width: "100%" }}>
        <Typography className={classes.question}>{question}</Typography>
        <Typography className={classes.weightage}>
          {weightage} Weightage
        </Typography>
      </div>
      <div
        style={{
          width: "100%",
          padding: "25px 25px",
        }}
      >
        <QuestionBody
          questionType={type}
          submittedAnswer={handleAnswer}
          // use response answer props in response after submitted
          responseAnswer={submittedAnswer}
          answers={options}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          removeEditOption={removeEditOption}
        />
      </div>
      {comment && (
        <div
          style={{
            width: "100%",
            padding: "25px 25px",
          }}
        >
          <QuestionBody questionType={"Comment box"} />
        </div>
      )}
    </div>
  );
};

export const Tile = ({
  text,
  value,
  selectedResCompany,
  dropdownValue,
  handleDropdownChange,
}) => {
  return (
    <div
      style={{
        border: "none",
        borderRadius: 5,
        backgroundColor: "#F6F6F6",
        width: "25%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
      }}
    >
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 400,
          padding: "3px 0px",
          wordBreak: "break-all",
        }}
      >
        {text}
      </Typography>
      {text === "Survey Status" ? (
        <div
          style={{
            backgroundColor: value ? "#BFE2CD" : "#FFEFD0",
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
            padding: "3px 8px",
          }}
        >
          <div
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              marginRight: 2,
              backgroundColor: "#154328",
            }}
          />
          <Typography
            style={{
              fontSize: 11,
              fontWeight: 600,
              color: value ? "#154328" : "#8C7038",
            }}
          >
            {value ? "Complete" : "In Complete"}
          </Typography>
        </div>
      ) : text.includes("Frequency") ? (
        <>
          <Select
            value={dropdownValue}
            onChange={(e) => {
              handleDropdownChange(e);
            }}
            variant="outlined"
            classes={{
              root: {
                padding: "5px 14px",
              },
            }}
            style={{
              width: "80%",
              maxHeight: "30px",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#15314E",
              boxShadow: "#C0D4E9",
              backgroundColor: "#FEFEFE",
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  padding: "0px 6px",
                },
              },
            }}
          >
            {selectedResCompany?.frequency.map((frequency) => (
              <MenuItem
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    dropdownValue === frequency.name
                      ? "#EBF1F8"
                      : "transparent",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
                key={frequency.name}
                value={frequency}
              >
                {frequency.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <Typography
          style={{ fontSize: 12, fontWeight: 600, padding: "3px 0px" }}
        >
          {value}
        </Typography>
      )}
    </div>
  );
};

function SelfAssessmentResponse() {
  const classes = responseStyles();
  let questions = [
    {
      question: "what is the question?",
      options: [
        ["option", true],
        ["option1", true],
        ["option2", false],
      ],
      weightage: 1,
    },
    {
      question: "what is the question?",
      options: [
        ["option", true],
        ["option1", true],
        ["option2", false],
      ],
      weightage: 4,
    },
  ];
  const users = [
    { name: "me", isSelected: true },
    { name: "test", isSelected: false },
    { name: "user", isSelected: false },
    { name: "master", isSelected: true },
    { name: "branch", isSelected: false },
  ];
  const {
    selectedResponse,
    selectedResCompany,
    total_no_of_questions,
    submittedQuestions,
  } = useSelector((state) => state.riskDashboard);
  const { login, riskDashboard } = useSelector((state) => state);
  const [selected, setSelected] = useState(0);
  const [dropdownValue, setDropdownValue] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getSurveyRespondedUser(
        login.token,
        localStorage.getItem("organization_id")
      )
    );
  }, [login.token]);
  useEffect(() => {
    const freq =
      selectedResCompany?.frequency[selectedResCompany?.frequency.length - 1];
    // dispatch(setResponseCompany(selectedResCompany));
    setDropdownValue(freq);
    // dispatch(getSubmittedResponse(token,selectedResponse.organization_id));
    freq?._id &&
      selectedResCompany &&
      dispatch(
        getSubmittedResponse(
          login.token,
          selectedResponse?.organization_id ||
            localStorage.getItem("organization_id"),
          freq?._id,
          selectedResCompany?._id,
          freq?.version
        )
      );
  }, [selectedResCompany]);

  useEffect(() => {
    return () => {
      dispatch(setResponseCompany(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompanyClick = (company, id) => {
    dispatch(setSubmittedQuestions([], 0, false));
    const freq = company.frequency[company.frequency.length - 1];
    dispatch(setResponseCompany(company));
    setDropdownValue(freq);
    // dispatch(getSubmittedResponse(token,selectedResponse.organization_id));
    // dispatch(
    //   getSubmittedResponse(
    //     login.token,
    //     selectedResponse?.organization_id,
    //     freq._id,
    //     id,
    //     freq.version
    //   )
    // );
    // setSelectedCompany(id === selectedCompany ? null : id);
  };
  const useStyles = makeStyles((theme) => ({
    rootSelect: {
      padding: "5px 14px",
    },
  }));
  const selectStyles = useStyles();
  return (
    // <Wrapper users={survey} hidesearch width="13%">
    <Wrapper
      selected={selected}
      setSelected={setSelected}
      users={riskDashboard.surveyRespondedUsers}
      handleUserClick={handleCompanyClick}
      
    >
      {selectedResCompany ?
       <div style={{ width: "78%" }}>
        <div style={{ backgroundColor: "#FBFBFB", padding: "8px 0px" }}>
          <div
            style={{
              padding: "2px 5px",
              color: "#15314E",
              borderBottom: "#EBF1F8",
              marginBottom: 5,
              fontSize: 13,
              fontWeight: 500,
            }}
          >
            {selectedResCompany?.name}
          </div>
          <div style={{ display: "flex", gap: 5 }}>
            <Tile
              text={"Role of the Satkeholder"}
              value={selectedResCompany?.role}
            />
            <Tile
              text={"Survey Status"}
              value={selectedResCompany?.is_completed}
            />
            <Tile
              text={"No. of Survey Questions"}
              value={total_no_of_questions}
            />
            <Tile
              text={`Frequency : ${selectedResCompany?.frequency[0]?.type}`}
              value={"26"}
              selectedResCompany={selectedResCompany}
              dropdownValue={dropdownValue}
              handleDropdownChange={(e) => {
                setDropdownValue(e.target.value);
                getSubmittedResponse(
                  login.token,
                  selectedResponse?.organization_id ||
                    localStorage.getItem("organization_id"),
                  e.target.value?._id,
                  selectedResCompany?._id,
                  e.target.value?.version
                );
              }}
            />
          </div>
        </div>
        <div className="scroll" style={{ height: "45vh" }}>
          <OptimizeInitialRender chunkSize={1}>
            {submittedQuestions?.map((question) => {
              return (
                <div>
                  <BlueBackgroundCard heading={question?.title} />
                  <OptimizeInitialRender chunkSize={1}>
                    {question?.questions.map((question) => (
                      <Question
                        question={question.question}
                        options={question.answers}
                        type={question.question_type}
                        weightage={question.weightage}
                        submittedAnswer={question.submitted_answer}
                        removeEditOption={true}
                        comment={question?.comment}
                      />
                    ))}
                  </OptimizeInitialRender>
                </div>
              );
            })}
          </OptimizeInitialRender>
        </div>
      </div> 
     : <div style={{ width: "78%" }}>
        <NoData message={"Please select a User"} />
      </div>}
    </Wrapper>
    // </Wrapper>
  );
}

export default SelfAssessmentResponse;

// {!type || type === "radio" ? (
//   <RadioGroup
//     value={"order"}
//     style={{ marginTop: 10 }}
//     // onChange={(e) => handleChange(e, que?.order)}
//     // name={que?._id + ""}
//   >
//     {options &&
//       options.map((ans, j) => {
//         return (
//           <div key={j} style={{ width: "100%" }}>
//             <div
//               key={j}
//               style={{ display: "flex", alignItems: "center" }}
//             >
//               <RadioButton value={ans[1]} id={ans[0] + ""} />
//               {/* <CustomCheckBox
//                 color="tertiary"
//                 checked={opt[1]}
//                 disabled={opt[1]}
//               /> */}
//               <Typography
//                 style={{
//                   fontSize: 13,
//                   fontWeight: 400,
//                   padding: "3px 0px",
//                 }}
//               >
//                 {ans[0]}
//               </Typography>
//             </div>
//             {/* <FormControlLabel
//               style={{
//                 width: "100%",
//                 padding: type === "textField" && "15px 0px",
//               }}
//               value={ans[0]}
//               control={
//                 <>
//                   {(!type || type === "radio") && (
//                     // <TextField
//                     //   // className={clsx(classes.root, classes.checked)}
//                     //   onChange={(e) => {
//                     //     // checkBoxChange(e, i, j);
//                     //   }}
//                     //   name={que.id + ""}
//                     //   id={ans[1] + ""}
//                     // />
//                     <RadioButton value={ans[1]} id={ans[0] + ""} />
//                     //  <FormControlLabel value="female" control={<Radio />} label="Female" />
//                   )}
//                 </>
//               }
//               label={ans[0]}
//               // onChange={(e) => {
//               //   addSurvey(e, i);
//               // }}
//             /> */}
//           </div>
//         );
//       })}
//   </RadioGroup>
// ) : (
//   <>
//     {type === "textField" && (
//       <div style={{ padding: "15px 0px" }}>
//         <CustomTextfield
//           style={{
//             width: "50vw",
//           }}
//           name="userName"
//           label="Answer"
//           variant="outlined"
//           size="small"
//           fullWidth
//           // value={data.question}
//         />
//       </div>
//     )}
//     {type === "multi select" && (
//       <Grid item xs={12} md={12} style={{ padding: "15px 0px" }}>
//         {/* <Typography
//                                 style={{
//                                   fontSize: "14px",
//                                   marginBottom: "5px",
//                                   color: "#9A9A9A",
//                                 }}
//                               >
//                                 Select File
//                               </Typography> */}

//         <Select
//           defaultValue={"Select an Option"}
//           style={{
//             border: "1px solid #C0D4E9",
//             backgroundColor: "#FEFEFE",
//             borderRadius: "8px",
//             height: "40px",
//             width: "50vw",
//             marginTop: "15px",
//           }}
//           // value={collect.value}
//           onChange={(event) => {
//             const {
//               target: { value },
//             } = event;

//             // setCollect((prev) => {
//             //   return {
//             //     ...prev,
//             //     question_id: que?._id,
//             //     value: value,
//             //   };
//             // });
//             // setDisclosureSort(value);
//           }}
//           input={<OutlinedInput />}
//         >
//           {options.map((item) => {
//             return (
//               <MenuItem key={item[0]} value={item[0]}>
//                 {item[0]}
//               </MenuItem>
//             );
//           })}
//         </Select>
//       </Grid>
//     )}
//     {type === "location" && (
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           height: `400px`,
//           width: "800px",
//         }}
//       >
//         <Typography> latitude: , longitude: </Typography>
//         {/* <GoogleMaps {...props}/> */}
//       </div>
//     )}
//     {type === "date" && (
//       <Grid item xs={12} md={12} className={classes.grid4}>
//         <Typography
//           style={{
//             fontSize: "14px",
//             marginBottom: "5px",
//             color: "#9A9A9A",
//           }}
//         >
//           Select File
//         </Typography>
//         <CustomTextfield
//           name="date"
//           variant="outlined"
//           type="date"
//           size="small"
//           fullWidth
//           value={""}
//         />
//       </Grid>
//     )}
//     {type === "file" && (
//       <Grid item xs={12} md={12} style={{ padding: "15px 0px" }}>
//         <Typography
//           style={{
//             fontSize: "14px",
//             marginBottom: "5px",
//             color: "#9A9A9A",
//           }}
//         >
//           Select File
//         </Typography>
//         <CustomTextfield
//           name="userName"
//           variant="outlined"
//           type="file"
//           size="small"
//           fullWidth
//           // value={collect.value}
//           onChange={(event) => {
//             const {
//               target: { value },
//             } = event;

//             // setDisclosureSort(value);
//           }}
//         />
//       </Grid>
//     )}
//   </>
// )}
// {/* {options?.map((opt) => (
//   <div style={{ display: "flex", alignItems: "center" }}>
//     <CustomCheckBox color="tertiary" checked={opt[1]} disabled={opt[1]} />
//     <Typography
//       style={{ fontSize: 13, fontWeight: 400, padding: "3px 0px" }}
//     >
//       {opt[0]}
//     </Typography>
//   </div>
// ))} */}
