import encrypt from "../../../redux/actions/shared/sharedActions";
import UNIVERSAL from "../../../config";
import { set_loader, unset_loader } from "../loader/loader_action";
import {
  SET_ACKNOWLEDGED,
  SET_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_NEW_ORGANIZATION_LOGO,
  SET_CHAT_QUESTION,
  SET_CHAT_ANSWER,
  SET_CHATS_CONVERSATION,
  SET_CHAT_GROUP,
  SET_SELECTED_CHAT,
  SET_SEARCHED_ORGANIZATION,
  SET_SEARCHED_TEXT,
  SET_DISCLAIMER,
  SET_HELP_CENTER_DRAWER,
} from "../../../constants/esgGptConstant";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { socket, socketCompany } from "../../../socket";
import { setChatTokens } from "../login/loginAction";

export const setSearchedOrg = (payload) => {
  return {
    type: SET_SEARCHED_ORGANIZATION,
    payload,
  };
};
export const setDisclaimer = (payload) => {
  return {
    type: SET_DISCLAIMER,
    payload,
  };
};
export const setSearcheText = (payload) => {
  return {
    type: SET_SEARCHED_TEXT,
    payload,
  };
};
export const setAcknowledged = (payload) => {
  return {
    type: SET_ACKNOWLEDGED,
    payload,
  };
};

export const setOrganization = (payload) => {
  return {
    type: SET_ORGANIZATION,
    payload,
  };
};

export const setNewCompanyDetails = (payload) => {
  return {
    type: SET_NEW_ORGANIZATION,
    payload,
  };
};

export const setNewCompanyLogo = (payload) => {
  return {
    type: SET_NEW_ORGANIZATION_LOGO,
    payload,
  };
};

export const setChatQuestion = (payload) => {
  return {
    type: SET_CHAT_QUESTION,
    payload,
  };
};

export const setChatAnswer = (payload) => {
  return {
    type: SET_CHAT_ANSWER,
    payload,
  };
};

export const setChatConversations = (payload) => {
  return {
    type: SET_CHATS_CONVERSATION,
    payload,
  };
};

export const setChatGroups = (payload) => {
  return {
    type: SET_CHAT_GROUP,
    payload,
  };
};

export const setSelectedChat = (payload) => {
  return {
    type: SET_SELECTED_CHAT,
    payload,
  };
};

export const setHelpCenterDrawer = (payload) => {
  return {
    type: SET_HELP_CENTER_DRAWER,
    payload,
  };
};

export const createOrganizationEsgGpt = (
  token,
  name,
  isin,
  sector,
  industry,
  industry_code,
  logo
) => {
  return (dispatch) => {
    dispatch(set_loader);
    var formData = new FormData();
    const data = encrypt({
      "user-token": token,
      name,
      isin,
      sector,
      industry,
      industry_code,
      // logo,
    });

    formData.append("data", data);
    formData.append("logo", logo);

    return fetch(
      UNIVERSAL.BASEURL + "/organization/create_organization_esg_gpt",
      {
        method: "POST",

        body: formData,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (!responseJson.result.already_exists) {
            dispatch(setAcknowledged(responseJson?.status));
            dispatch(setNewCompanyDetails(responseJson?.result));
            dispatch(viewOrganizationEsgGpt(token));
          } else {
            dispatch(
              set_snack_bar(
                true,
                "A company sharing the same ISIN is already present in the repository section."
              )
            );
          }
        }

        dispatch(unset_loader);
      });
  };
};
export const deleteOrganizationEsgGpt = (token, id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      id,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/organization/delete_organization_esg_gpt",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson);
          // dispatch(set_snack_bar(responseJson.status.responseJson.message));
          // dispatch(setNewCompanyDetails(responseJson?.result));
          dispatch(viewOrganizationEsgGpt(token));
        }

        dispatch(unset_loader);
      });
  };
};

export const viewOrganizationEsgGpt = (
  token,
  limit,
  starting_after,
  search_keyword
) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      limit: limit || 10,
      starting_after: starting_after || 0,
      keyword: search_keyword || "",
    });

    return fetch(
      UNIVERSAL.BASEURL + "/organization/view_organization_esg_gpt",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganization(responseJson?.result));
          // responseJson?.result?.favorites.length > 0
          //   ? dispatch(setNewCompanyDetails(responseJson?.result?.favorites[0]))
          //   : dispatch(
          //       setNewCompanyDetails(responseJson?.result?.repositories[0])
          //     );
        }

        dispatch(unset_loader);

        dispatch(unset_loader);
      });
  };
};

// not a dispatch action
const getChatHistory = async (
  token,
  is_company_based,
  chat_group_id,
  company_id
) => {
  const historyData = encrypt({
    "user-token": token,
    is_company_based,
    chat_group_id: chat_group_id || "",
    company_id: company_id || "",
  });

  let chatHistory = [];

  await fetch(UNIVERSAL.BASEURL + "/python_apis/get_chat_history_for_python", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: historyData }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("api response", responseJson);
      chatHistory = responseJson?.result;
    });

  return chatHistory;
};

export const getGptAnswer = (
  token,
  company_id,
  question,
  chat_id,
  is_company_based,
  isin,
  companyName
) => {
  return async (dispatch, getState) => {
    // dispatch(set_loader);

    // const data = encrypt({
    //   "user-token": token,
    //   question,
    //   company_id,
    //   chat_id,
    //   is_company_based,
    // });

    const { chats } = getState().esgGptReducer;
    const { chat_tokens } = getState().login;

    const chatObj = {
      question,
      isLoading: true,
      thinking: "",
      answer: "",
    };

    // selecting socket to use based on if comapny name is present
    const socketToUse =
      companyName && is_company_based ? socketCompany : socket;

    // console.log("Using socket port: ", socketToUse._opts.port);

    dispatch(setChatConversations([...chats, chatObj]));

    const chatHistory = await getChatHistory(
      token,
      is_company_based,
      chat_id,
      company_id
    );

    function task1() {
      question = {
        question: question,
        isin: isin || undefined,
        chatHistory: chatHistory,
      };

      // console.log("Question is: ", question);

      // make use of socket.emit for direct chat and socketCompany.emit for company based chat

      new Promise(function (resolve, reject) {
        resolve(socketToUse.emit("start_chat_session", question));
      });
    }

    function taskThinking() {
      new Promise(function (resolve, reject) {
        resolve(
          socketToUse.on("thinking", async (responseJson) => {
            console.log("Thinking now: ", responseJson);
            const filterChats = chats.filter((c) => !c.isLoading);

            dispatch(
              setChatConversations([
                ...filterChats,
                {
                  ...chatObj,
                  isLoading: true,
                  thinking: responseJson,
                  answer: "",
                },
              ])
            );
          })
        );
      });
      // return new Promise(function (resolve, reject) {

      // });
    }

    function task2() {
      return new Promise(function (resolve, reject) {
        // socket.on("response", async (responseJson) => {
        socketToUse.on(
          "final_result" /*"final_result"*/,
          async (responseJson) => {
            // console.log("Response from Socket Server \n", responseJson);
            if (responseJson === undefined) {
              responseJson =
                "Unable to generate a response for your message. Please try again.";
            }

            const filterChats = chats.filter((c) => !c.isLoading);

            dispatch(
              setChatConversations([
                ...filterChats,
                {
                  ...chatObj,
                  isLoading: false,
                  answer: responseJson,
                },
              ])
            );
            // console.log("run");
            await responseJson;

            // reduce chat token
            const newChatTokens = chat_tokens <= 0 ? 0 : chat_tokens - 1;
            dispatch(setChatTokens(newChatTokens));
            localStorage.setItem("chat_tokens", newChatTokens);

            resolve(responseJson);
          }
        );
      });
    }

    function task3(socketResponse) {
      // const { socketResponse } = getState().esgGptReducer;
      // console.log("response: ", socketResponse);
      const chatData = encrypt({
        "user-token": token,
        question,
        answer: socketResponse,
        company_id,
        chat_id,
        is_company_based,
        isin: isin || "",
      });

      fetch(UNIVERSAL.BASEURL + "/python_apis/add_chat", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: chatData }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log("api response", responseJson);
          if (responseJson.status) {
            if (!is_company_based && !chat_id) {
              dispatch(viewChatGroups(token));
            }
          }

          // dispatch(unset_loader);

          // dispatch(unset_loader);
        });
    }

    Promise.all([task1(), taskThinking(), task2()]).then(function (values) {
      // console.log(values);
      task3(values[2]);
    });

    // try {
    //   if (companyName && is_company_based) {
    //     question = `${question}. Answer only about the company ${companyName}`;
    //   }
    //   // Emit the message using Socket.IO
    //   socket.emit("message", question);
    //   // console.log("Question is: ", question);

    //   // Handle the response from Socket.IO
    //   socket.on("response", (responseJson) => {
    //     // console.log("Response from Socket Server \n", responseJson, isin);
    //     if (responseJson === undefined) {
    //       responseJson =
    //         "Unable to generate a response for your message. Please try again.";
    //     }

    //     const chatData = encrypt({
    //       "user-token": token,
    //       question,
    //       answer: responseJson,
    //       company_id,
    //       chat_id,
    //       is_company_based,
    //       isin: isin || "",
    //     });

    //     fetch(UNIVERSAL.BASEURL + "/python_apis/add_chat", {
    //       method: "POST",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ data: chatData }),
    //     });

    //     const filterChats = chats.filter((c) => !c.isLoading);

    //     dispatch(
    //       setChatConversations([
    //         ...filterChats,
    //         {
    //           ...chatObj,
    //           isLoading: false,
    //           answer: responseJson,
    //         },
    //       ])
    //     );

    //     if (!is_company_based && !chat_id) {
    //       dispatch(viewChatGroups(token));
    //     }
    //   });
    // } catch (error) {
    //   console.error("Error in Socket.IO communication:", error);
    //   // Handle the error as needed
    // }

    // return fetch(UNIVERSAL.BASEURL + "/python_apis/get_answer", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ data: data }),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     // console.log(responseJson);
    //     if (responseJson.status) {
    //       const { chats } = getState().esgGptReducer;

    //       const filterChats = chats.filter((c) => !c.isLoading);

    //       dispatch(
    //         setChatConversations([
    //           ...filterChats,
    //           {
    //             ...chatObj,
    //             isLoading: false,
    //             answer: responseJson?.result,
    //           },
    //         ])
    //       );

    //       if (!is_company_based && !chat_id) {
    //         dispatch(viewChatGroups(token));
    //       }
    //     }

    //     // dispatch(unset_loader);

    //     // dispatch(unset_loader);
    //   });
  };
};

export const getGptChatHIstory = (
  token,
  company_id,
  is_company_based,
  chat_id
) => {
  return (dispatch, getState) => {
    // dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      is_company_based,
      chat_group_id: chat_id,
      company_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/get_chat_history ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setChatConversations(responseJson?.result));
        }

        // dispatch(unset_loader);

        // dispatch(unset_loader);
      });
  };
};

export const viewChatGroups = (token) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/get_chat_groups ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setChatGroups(responseJson?.result.reverse()));
        }
      });
  };
};

export const deleteChat = (token, chat_id) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      chat_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/delete_chat_group ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewChatGroups(token));
        }
      });
  };
};

export const updateChatName = (token, chat_id, group_name) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      chat_group_id: chat_id,
      group_name,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/update_chat_group_name  ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // console.log(responseJson);
          dispatch(viewChatGroups(token));
        }
      });
  };
};

export const updateGptTour = (token, is_company_tour = false) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      is_company_tour,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/set_tour_completed", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          localStorage.setItem(
            "chat_tour_completed",
            responseJson.result?.chat_tour_completed
          );
          localStorage.setItem(
            "company_tour_completed",
            responseJson.result?.company_tour_completed
          );
          // console.log(responseJson);
          // dispatch(viewChatGroups(token));
        }
      });
  };
};

export const updateChatFeedBack = (
  token,
  chat_id,
  response,
  is_liked,
  disliked_rating,
  is_company_based
) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      chat_id,
      response,
      is_liked,
      disliked_rating,
      is_company_based,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/chat_feedback", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
          // console.log(responseJson);
          // dispatch(viewChatGroups(token));
        } else {
          dispatch(
            set_snack_bar(responseJson.status, "Data Updated Successfully")
          );

          dispatch(unset_loader());
        }
      });
  };
};

export const sendPremiumPlanRequest = (
  token,
  name,
  email,
  organization_name,
  section
) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      email,
      name,
      organization_name,
      section,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/send_email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
          // console.log(responseJson);
          // dispatch(viewChatGroups(token));
        }
      });
  };
};

export const sendFeedBack = (token, feedback, rating) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      feedback,
      rating,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/send_feedback", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        }
      });
  };
};
