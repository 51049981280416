import { Box } from "@material-ui/core";

import { controversyChartData, defaultColors } from "./chartUtils/chartData";
import StackedChart from "./chartComponents/stackedChart";

const ControversyChart = () => {
  return (
    <Box sx={{ height: "300px", width: "100%" }}>
      <StackedChart
        chartData={controversyChartData}
        chartLabel={"Day"}
        defaultColors={defaultColors}
      />
    </Box>
  );
};
export default ControversyChart;
