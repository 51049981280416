import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import RouterCont from "./containers/router/routerCont";
import ReactGA from "react-ga4";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_ESG_GPT_GA);

const theme = createTheme({
  palette: {
    warning: { main: "#C45446" },
  },
  typography: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  overrides: {
    MuiTabs: {
      indicator: {
        color: "green",
        backgroundColor: "#32A05F",
      },
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterCont />
      </ThemeProvider>
    </>
  );
}

export default App;
