import React, { useEffect } from "react";
import { connect } from "react-redux";
// import { getAssessmentDetails } from "../../actions/basicinfoAction/basicinfoAction";
// import { viewAllUsers } from "../../actions/generalSettings/generalSettingsAction";
import {
  addCompanyBrm,
  getCountries,
  setCorporateAns,
} from "../../redux/actions/brm/BrmActions";
// import { getOrganizations } from "../../actions/surveyListing/surveyListingAction";
import RiskAssessment from "../../components/widgets/riskAssessment/riskAssessment";
import { getCalculatedSurvey } from "../../redux/actions/riskEvaluator/riskEvaluatorActions";
import { getSectorIndustries } from "../../redux/actions/brm/BrmActions";
import {
  createOrganization,
  setOrganizationDetails,
  updateOrganization,
  viewDetailsOrganization,
  viewOrganization,
} from "../../redux/actions/organization/organizationAction";
import { set_snack_bar } from "../../redux/actions/snackbar/snackbar_action";

function RiskAssessmentCont(props) {
  // useEffect(() => {
  //   props.getOrganizations(props.login.token, window.location.hostname);
  // }, [props.login.token]);
  return <RiskAssessment {...props} />;
}

export const mapStateToProps = (store) => ({
  login: store.login,
  brmData: store.brmData,
  corporateSurvey: store.corporateSurvey,
  riskEvaluator: store.riskEvaluator,
});

export const mapDispatchToProps = (dispatch) => ({
  // getOrganizations: (payload, domain_name) => {
  //   dispatch(getOrganizations(payload, domain_name));
  // },
  // getMaturityMatrix: (payload, domain_name) => {
  //   dispatch(getMaturityMatrix(payload, domain_name));
  // },
  setCorporateAns: (payload, domain_name) => {
    dispatch(setCorporateAns(payload, domain_name));
  },
  addCompanyBrm: (payload, domain_name) => {
    dispatch(addCompanyBrm(payload, domain_name));
  },
  getCalculatedSurvey: (token, organization_id) => {
    dispatch(getCalculatedSurvey(token, organization_id));
  },
  // getAssessmentDetails: (payload, domain_name) => {
  //   dispatch(getAssessmentDetails(payload, domain_name));
  // },
  getSectorIndustries: (token) => {
    dispatch(getSectorIndustries(token));
  },
  getCountries: (token) => {
    dispatch(getCountries(token));
  },
  viewOrganization: (token, bank_id, limit, starting_after, keyword) => {
    dispatch(viewOrganization(token, bank_id, limit, starting_after, keyword));
  },
  createOrganization: (
    token,
    bankId,
    name,
    isin,
    year,
    logo,
    sectors,
    operation,
    no_of_employees,
    address,
    masterSectors,
    revenue,
    rowsPerPage,
    turnoverData,
    country,
    aliases
  ) => {
    dispatch(
      createOrganization(
        token,
        bankId,
        name,
        isin,
        year,
        logo,
        sectors,
        operation,
        no_of_employees,
        address,
        masterSectors,
        rowsPerPage,
        revenue,
        turnoverData,
        country,
        aliases
      )
    );
  },
  updateOrganization: (
    token,
    organization_id,
    name,
    isin,
    year_of_assessment,
    logo,
    sectors,
    operation,
    no_of_employees,
    address,
    masterSectors,
    revenue,
    turnoverData,
    country_code,
    aliases
  ) => {
    dispatch(
      updateOrganization(
        token,
        organization_id,
        name,
        isin,
        year_of_assessment,
        logo,
        sectors,
        operation,
        no_of_employees,
        address,
        masterSectors,
        revenue,
        turnoverData,
        country_code,
        aliases
      )
    );
  },
  viewDetailsOrganization: (token, organization_id) => {
    dispatch(viewDetailsOrganization(token, organization_id));
  },
  set_snack_bar: (status, message) => {
    dispatch(set_snack_bar(status, message));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RiskAssessmentCont);
