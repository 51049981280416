import React  from 'react';
import { connect } from 'react-redux';
import LoginController from '../../components/router/controller';
import { setLogin, setSignupStepNo } from "../../redux/actions/login/loginAction";
// import { setLogin, getVersionNo } from '../../actions/login/loginActions';

export class Controller_con extends React.PureComponent {
  render() {
    return <LoginController {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (
      type,
      email,
      user_token,
      profile_img,
      name,
      roles,
      signupProcessNo
    ) => {
      dispatch(
        setLogin(
          type,
          email,
          user_token,
          profile_img,
          name,
          roles,
          signupProcessNo
        )
      );
    },
    setSignupStepNo: (no) => {
      dispatch(setSignupStepNo(no));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller_con);
