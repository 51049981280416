export const interestData = [
  {
    name: "India",
    interest: "73%",
  },
  {
    name: "Brazil",
    interest: "58%",
  },
  {
    name: "Canada",
    interest: "41%",
  },
  {
    name: "Sudan",
    interest: "32%",
  },
  {
    name: "Russia",
    interest: "100%",
  },
];
