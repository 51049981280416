// Function to calculate color based on interest percentage
const colorCalculator = (interest) => {
  const numericInterest = parseFloat(interest.replace("%", ""));
  const percentage = numericInterest > 100 ? 100 : numericInterest;

  // Calculate the color based on a gradient scale with mid color
  const minColor = [224, 224, 224]; // #E0E0E0 in RGB
  const midColor = [161, 191, 223]; // #A1BFDF in RGB
  const maxColor = [51, 116, 185]; // #3374B9 in RGB

  const midPoint = 100; // change to 50 to use 3 shades

  let interpolatedColor;
  if (percentage <= midPoint) {
    interpolatedColor = minColor.map((channel, index) =>
      Math.round(
        channel + (midColor[index] - channel) * (percentage / midPoint)
      )
    );
  } else {
    interpolatedColor = midColor.map((channel, index) =>
      Math.round(
        channel +
          (maxColor[index] - channel) *
            ((percentage - midPoint) / (100 - midPoint))
      )
    );
  }

  // Convert RGB values to hex
  const colorHex = `#${interpolatedColor
    .map((c) => c.toString(16).padStart(2, "0"))
    .join("")}`;

  return colorHex;
};

export default colorCalculator;
