import { Icon, Menu, MenuItem, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import comp from "../../../images/comp.png";
import comp1 from "../../../images/comp1.png";
import comp2 from "../../../images/comp2.png";
import comp3 from "../../../images/comp3.png";
import comp4 from "../../../images/comp4.png";
import companyPlaceholder from "../../../images/CompanyPlaceholder.png";
import Documents from "./documents";
import CustomButton from "../../UI/button/button";
import { getPeers } from "../../../redux/actions/riskEvaluator/riskEvaluatorActions";
import { useDispatch, useSelector } from "react-redux";
// import comp5 from "../../../images/comp5.png";

function PeerDocuments(props) {
  const { riskEvaluator } = props;
  const peers = [
    {
      name: "Globex Corporation",
      img: comp,
    },
    {
      name: "Ollivander's Wand Shop",
      img: comp1,
    },
    {
      name: "Soylent Corp",
      img: comp2,
    },
    {
      name: "Bubba Gump",
      img: comp3,
    },
    {
      name: "Stark Indutries",
      img: comp4,
    },
  ];
  const { login, brmData } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPeers(login.token, brmData?.organizationDetails[0]?._id));
  }, []);

  const [showPeerDocs, setShowpeerDocs] = useState(false);
  const [peerCompany, setPeerCompany] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {!showPeerDocs ? (
        <div style={{ height: "63vh" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Peers
              </Typography>
            </div>
            <div>
              <CustomButton
                style={{ padding: "3px 10px", textTransform: "none" }}
                color="primary"
                onClick={handleClick}
              >
                Filter
              </CustomButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
          <div className="scroll" style={{ height: "61vh" }}>
            {riskEvaluator?.peerOrganizations?.map((peer) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 5,
                  padding: 5,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={peer?.logo?.url || companyPlaceholder}
                    alt="sf"
                  />
                  <Typography style={{ marginLeft: "16px" }}>
                    {peer?.name}
                  </Typography>
                </div>
                <div>
                  <Typography
                    onClick={() => {
                      setShowpeerDocs(!showPeerDocs);
                      setPeerCompany(peer);
                    }}
                    style={{ fontSize: "24px", cursor: "pointer" }}
                  >{`>`}</Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Documents
          showPeerDocs={showPeerDocs}
          setShowpeerDocs={setShowpeerDocs}
          peerCompany={peerCompany}
          riskEvaluator={props?.riskEvaluator}
          {...props}
        />
      )}
    </>
  );
}

export default PeerDocuments;
