import React from "react";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const greenTheme = createTheme({
  palette: {
    primary: { main: "#3374B9" },
    secondary: { main: "#47BB76" },
    warning: { main: "#C45446" },

    action: {
      disabledBackground: "#C0D4E9",
      textTransform: "none",
    },
  },
  overrides: {
    MuiButton: {
      containedWarning: {
        backgroundColor: "#C45446", // Set the background color for the warning button
        "&:hover": {
          backgroundColor: "#B54335", // Adjust the hover background color if needed
        },
        color: "#ffffff", // Set the text color for the warning button
      },

      containedSecondary: {
        color: "#ffffff",
      },
      containedPrimary: {
        color: "#ffffff",
      },

      textPrimary: {
        "&:hover": {
          backgroundColor: "#EBF1F8",
        },
      },
      textSecondary: {
        "&:hover": {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});
export default class CustomButton extends React.PureComponent {
  render() {
    return (
      <>
        <MuiThemeProvider theme={greenTheme}>
          <Button {...this.props} />
        </MuiThemeProvider>
      </>
    );
  }
}
