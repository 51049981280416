import React, { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";

// redux imports
import { useDispatch, useSelector } from "react-redux";

// other imports
import {
  getSubmittedResponse,
  setResponseCompany,
} from "../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";
import ResponseSectionList from "./ResponseSectionList";
import ResponseSectionTopCard from "./ResponseSectionTopCard";
import ResponseSectionTopHeading from "./ResponseSectionTopHeading";

const DrawerRight = () => {
  // from redux

  const { token } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const {
    selectedResCompany,
    submittedQuestions,
    selectedResponse,
    total_no_of_questions,
    surveyStatus
  } = useSelector((state) => state.riskDashboard);

 

  useEffect(() => {
    let frequency =
      selectedResCompany?.frequency[selectedResCompany?.frequency.length - 1];
    frequency &&
      selectedResCompany &&
      dispatch(
        getSubmittedResponse(
          token,
          selectedResponse?.organization_id,
          frequency?._id,
          selectedResCompany?._id,
          frequency?.version
        )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResCompany]);

  useEffect(() => {
    return () => {
      dispatch(setResponseCompany(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // console.log(isRender);

  return (
    <Box sx={{ height: "90%", overflow: "hidden" }}>
      <Box sx={{ pl: 2, height: "100%", overflow: "hidden" }}>
        {/* top section */}

        <ResponseSectionTopHeading selectedResCompany={selectedResCompany} />

        {/* top cards */}

        <ResponseSectionTopCard
          selectedResCompany={selectedResCompany}
          total_no_of_questions={total_no_of_questions}
          surveyStatus={surveyStatus}
        />

        {/* question list */}

        <ResponseSectionList submittedQuestions={submittedQuestions} />


        {/* {isRender ? (
          <ResponseSectionList submittedQuestions={submittedQuestions} />
        ) : (
          <CircularProgress />
        )} */}
      </Box>
    </Box>
  );
};

export default DrawerRight;
