export const SET_LOGIN = "SET_LOGIN";

export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const SET_LOGOUT = "SET_LOGOUT";

export const SET_TOKEN = "SET_TOKEN";
export const SET_SIGNUP_STEP_NO = "SET_SIGNUP_STEP_NO";

export const SET_CHAT_TOKENS = "SET_CHAT_TOKENS";
