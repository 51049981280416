const QUESTION_TYPE_SCALE = "Scale";
const QUESTION_TYPE_MULTICHOICE = "Multichoice";
const QUESTION_TYPE_COMMENT_BOX = "Comment box";
const QUESTION_TYPE_LOCATION = "Location";
const QUESTION_TYPE_DOCUMENT_UPLOAD = "Document Upload";
const QUESTION_TYPE_DATE_PICKER = "Date Picker";

const SUB_QUESTION_0_10 = "0-10";
const SUB_QUESTION_LIKERT = "likert";
const SUB_QUESTION_SATISFACTION = "Satisfaction";
const SUB_QUESTION_QUALITY = "Quality";
const SUB_QUESTION_FREQUENCY = "Frequency";
const SUB_QUESTION_PERFORMANCE = "Performance";
const SUB_QUESTION_IMPORTANCE = "Importance";
const SUB_QUESTION_EMOTIONS = "Emotions";
const DATE_PICKER_SINGLE_DATE = "Single Date";
const DATE_PICKER_RANGE_DATE = "Range Date";

const questionTypes = [
  {
    primary: QUESTION_TYPE_SCALE,
    secondary: "Quantitative Ranking",
  },
  {
    primary: QUESTION_TYPE_MULTICHOICE,
    secondary: "Select Options",
  },
  {
    primary: QUESTION_TYPE_COMMENT_BOX,
    secondary: "Open-Ended Response",
  },
  {
    primary: QUESTION_TYPE_LOCATION,
    secondary: "Geographical Input",
  },
  {
    primary: QUESTION_TYPE_DOCUMENT_UPLOAD,
    secondary: "Attach Supporting Files",
  },
  {
    primary: QUESTION_TYPE_DATE_PICKER,
    secondary: "Select Date",
  },
];

const subQuestionTypes = {
  [QUESTION_TYPE_SCALE]: [
    { primary: SUB_QUESTION_0_10, secondary: "not at all - absolutely" },
    {
      primary: SUB_QUESTION_LIKERT,
      secondary: "Strongly disagree - strongly agree",
    },
    {
      primary: SUB_QUESTION_SATISFACTION,
      secondary: "Very dissatisfied - Very satisfied",
    },
    { primary: SUB_QUESTION_QUALITY, secondary: "Very poor - Very Good" },
    { primary: SUB_QUESTION_FREQUENCY, secondary: "Never - Very frequently" },
    { primary: SUB_QUESTION_PERFORMANCE, secondary: "Awfully - Superbly" },
    {
      primary: SUB_QUESTION_IMPORTANCE,
      secondary: "Not at all important - Extremely important",
    },
    { primary: SUB_QUESTION_EMOTIONS, secondary: "Sad face - Happy Face" },
  ],
  [QUESTION_TYPE_DATE_PICKER]: [
    { primary: DATE_PICKER_SINGLE_DATE, secondary: "DD/MM/YYYY" },
    { primary: DATE_PICKER_RANGE_DATE, secondary: "DD/MM/YYYY - DD/MM/YYYY" },
  ],
};

const radioOption = {
  [SUB_QUESTION_0_10]: [
    "Not at all",
    "Slightly",
    "Somewhat",
    "Moderately",
    "Considerably",
    "Significantly",
    "Substantially",
    "Greatly",
    "Extremely",
    "Absolutely",
  ],
  [SUB_QUESTION_QUALITY]: [
    "Very Poor",
    "Poor",
    "Below Average",
    "Average",
    "Above Average",
    "Good",
    "Very Good",
  ],
  [SUB_QUESTION_LIKERT]: [
    "Strongly Disagree",
    "Disagree",
    "Slightly Disagree",
    "Neutral",
    "Slightly Agree",
    "Agree",
    "Strongly Agree",
  ],
  [SUB_QUESTION_SATISFACTION]: [
    "Very dissatisfied",
    "Dissatisfied",
    "Somewhat dissatisfied",
    "Neither satisfied nor dissatisfied",
    "Somewhat satisfied",
    "Satisfied",
    "Very satisfied",
  ],
  [SUB_QUESTION_FREQUENCY]: [
    "Never",
    "Rarely",
    "Occasionally",
    "Sometimes",
    "Frequently",
    "Very Frequently",
  ],
  [SUB_QUESTION_PERFORMANCE]: [
    "Awfully",
    "Very Poor",
    "Poor",
    "Below Average",
    "Average",
    "Above Average",
    "Good",
    "Very Good",
    "Exceptional",
    "Superbly",
  ],
  [SUB_QUESTION_IMPORTANCE]: [
    "Not at all important",
    "Slightly important",
    "Somewhat important",
    "Moderately important",
    "Considerably important",
    "Significantly important",
    "Substantially important",
    "Greatly important",
    "Extremely important",
  ],
  [SUB_QUESTION_EMOTIONS]: [
    "😢 Sad face",
    "😟 A little sad",
    "😐 Neutral",
    "😊 A little happy",
    "😄 Happy face",
  ],
};

const sectionWiseData = {
  "Self Assessment Section": [
    {
      id: 1,
      question: "First question",
      questionType: QUESTION_TYPE_COMMENT_BOX,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question: "Second question",
      questionType: QUESTION_TYPE_DOCUMENT_UPLOAD,
      isEdit: false,
      weightage: 2,
      hasNewlyAdded: false,
    },
    // {
    //   id: 3,
    //   question: "Third question",
    //   questionType: SUB_QUESTION_FREQUENCY,
    //   isEdit: false,
    //   weightage: 3,
    // },
    // {
    //   id: 4,
    //   question: "Fourth question",
    //   questionType: SUB_QUESTION_EMOTIONS,
    //   isEdit: false,
    //   weightage: 2,
    // },
    // {
    //   id: 5,
    //   question: "Fifth question",
    //   questionType: QUESTION_TYPE_MULTICHOICE,
    //   isEdit: false,
    //   weightage: 1,
    // },
    // {
    //   id: 6,
    //   question: "Sixth question",
    //   questionType: QUESTION_TYPE_COMMENT_BOX,
    //   isEdit: false,
    //   weightage: 3,
    // },
    // {
    //   id: 7,
    //   question: "Seventh question",
    //   questionType: QUESTION_TYPE_MULTICHOICE,
    //   isEdit: false,
    //   weightage: 2,
    // },
    // {
    //   id: 8,
    //   question: "Eighth question",
    //   questionType: QUESTION_TYPE_MULTICHOICE,
    //   isEdit: false,
    //   weightage: 1,
    // },
    // {
    //   id: 9,
    //   question: "Ninth question",
    //   questionType: SUB_QUESTION_QUALITY,
    //   isEdit: false,
    //   weightage: 3,
    // },
    // {
    //   id: 10,
    //   question: "Tenth question",
    //   questionType: QUESTION_TYPE_MULTICHOICE,
    //   isEdit: false,
    //   weightage: 2,
    // },
  ],
  "Controversy Survey": [
    {
      id: 1,
      question: "First question",
      questionType: QUESTION_TYPE_MULTICHOICE,
      isEdit: false,
      weightage: 1,
      hasNewlyAdded: false,
    },
    {
      id: 2,
      question: "Second question",
      questionType: QUESTION_TYPE_DOCUMENT_UPLOAD,
      isEdit: false,
      weightage: 2,
      hasNewlyAdded: false,
    },
    // {
    //   id: 3,
    //   question: "Third question",
    //   questionType: SUB_QUESTION_FREQUENCY,
    //   isEdit: false,
    //   weightage: 3,
    // },
    // {
    //   id: 4,
    //   question: "Fourth question",
    //   questionType: SUB_QUESTION_EMOTIONS,
    //   isEdit: false,
    //   weightage: 2,
    // },
  ],
  // section: [
  //   {
  //     id: 1,
  //     question: "",
  //     questionType: "",
  //     isEdit: true,
  //     weightage: 0,
  //     hasNewlyAdded: true,
  //   },
  // ],
};

export {
  QUESTION_TYPE_SCALE,
  QUESTION_TYPE_MULTICHOICE,
  QUESTION_TYPE_COMMENT_BOX,
  QUESTION_TYPE_LOCATION,
  QUESTION_TYPE_DOCUMENT_UPLOAD,
  QUESTION_TYPE_DATE_PICKER,
  SUB_QUESTION_0_10,
  SUB_QUESTION_LIKERT,
  SUB_QUESTION_SATISFACTION,
  SUB_QUESTION_QUALITY,
  SUB_QUESTION_FREQUENCY,
  SUB_QUESTION_PERFORMANCE,
  SUB_QUESTION_IMPORTANCE,
  SUB_QUESTION_EMOTIONS,
  DATE_PICKER_SINGLE_DATE,
  DATE_PICKER_RANGE_DATE,
  questionTypes,
  subQuestionTypes,
  radioOption,
  sectionWiseData,
};
