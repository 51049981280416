import { useEffect } from "react";

import { Box, Typography, IconButton } from "@material-ui/core";

// redux imports
import { useDispatch, useSelector } from "react-redux";

// icons
import CloseIcon from "@material-ui/icons/Close";

// component imports
import DrawerLeft from "./DrawerSections/DrawerLeft";
import DrawerRight from "./DrawerSections/DrawerRight";
import {
  setSurveyRespondedUsers,
  setSubmittedQuestions,
} from "../../../../../../../redux/actions/riskdashboard/riskDashboardActions";
import NoData from "../../../../../NoData/NoData";

const ResponseDrawer = ({ handleDrawer }) => {
  const dispatch = useDispatch();
  const { selectedResponse, selectedResCompany, surveyRespondedUsers } =
    useSelector((state) => state.riskDashboard);

  useEffect(() => {
    return () => {
      dispatch(setSurveyRespondedUsers([]));
      dispatch(setSubmittedQuestions([], 0, false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        width: "85vw",
        display: "flex",
        flexDirection: "column",
        overflow:"hidden"
      }}
    >
      {/* Drawer Header */}
      <Box
        sx={{
          boxShadow: "0px 2px 4px #3374B926",
          px: 2,
          py: 0.5,
          backgroundColor: "#FEFEFE",
          position: "sticky",
          top: 0,
          zIndex: "9999",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: "600", color: "#15314E" }}>
            {selectedResponse?.organization_name}
          </Typography>
          <IconButton
            onClick={() => {
              handleDrawer();
            }}
          >
            <CloseIcon style={{ color: "#3374B9" }} />
          </IconButton>
        </div>
      </Box>

      {/* Drawer Contents */}
      <Box
        sx={{
          pt: 2,
          px: 2,
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          height:"100%"
        }}
      >
        {surveyRespondedUsers.length > 0 && (
          <DrawerLeft
            surveyRespondedUsers={surveyRespondedUsers}
            selectedResCompany={selectedResCompany}
          />
        )}
        {selectedResCompany ? (
          <DrawerRight />
        ) : (
          <Box sx={{ height: "100%", width: "100%", overflowY: "scroll" }}>
            <NoData message={"Please select a User"} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ResponseDrawer;
