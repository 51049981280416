export const SET_ACKNOWLEDGED = "SET_ACKNOWLEDGED";

export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_SEARCHED_ORGANIZATION = "SET_SEARCHED_ORGANIZATION";
export const SET_SEARCHED_TEXT = "SET_SEARCHED_TEXT";

export const SET_NEW_ORGANIZATION = "SET_NEW_ORGANIZATION";

export const SET_NEW_ORGANIZATION_LOGO = "SET_NEW_ORGANIZATION_LOGO";

export const SET_CHAT_QUESTION = "SET_CHAT_QUESTION";

export const SET_CHAT_ANSWER = "SET_CHAT_ANSWER";

export const SET_CHATS_CONVERSATION = "SET_CHATS_CONVERSATION";

export const SET_CHAT_GROUP = "SET_CHAT_GROUP";

export const SET_SELECTED_CHAT = "SET_SELECTED_CHAT";
export const SET_DISCLAIMER = "SET_DISCLAIMER";
export const SET_HELP_CENTER_DRAWER = "SET_HELP_CENTER_DRAWER"
