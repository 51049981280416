import { useState } from "react";
import Box from "@material-ui/core/Box";
import PlayIcon from "../../../../../images/play-icon.svg";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ArrowForwardIosRounded";
import YouTube from "react-youtube";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  accordonMain: {
    boxShadow: "none",
    borderBottom: "1px solid #A1BFDF",
  },
  expandIcon: {
    width: "18px",
    color: "#3374B9",
    transform: "rotate(0deg)",
    transition: "transform 0.3s ease",
  },
  rotated: {
    transform: "rotate(-90deg)",
  },
  accordionTitle: {
    width: "100%",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  },
  accordionContent: {
    width: "100%",
  },
}));

const DrawerMain = () => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // YouTube video options
  const opts = {
    height: "248",
    width: "440",
    playerVars: {
      autoplay: 0,
    },
  };

  // YouTube video ID
  const videoId = "skRSfDfMbSM";

  const accordionData = [
    {
      _id: "panel1",
      title: "Why do we collect company details",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      _id: "panel2",
      title: "What is Materiality Assessment",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      _id: "panel3",
      title: "Why would you provide materiality Assessment here ?",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      _id: "panel4",
      title: "What is sustainability reports ?",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      _id: "panel5",
      title: "Does ESG report require so all the infomation listed ?",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      {/* Video Box */}
      <Box
        sx={{
          width: "100%",
          height: "235px",
          backgroundColor: "#EBF1F8",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <YouTube videoId={videoId} opts={opts} />
        {/* <img
          src={PlayIcon}
          alt="PlayIcon"
          style={{
            width: "42px",
            height: "47.12px",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
        /> */}
      </Box>
      {/* Quick Onboarding */}
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "24px",
          marginTop: "1em",
        }}
      >
        <span style={{ cursor: "pointer", color: "#3374B9" }}>Click Here </span>
        for Quick Onboarding Experience.
      </Typography>

      {/* Questions Box */}
      <Box sx={{ marginTop: 20 }}>
        {accordionData.map((item) => (
          <Accordion
            key={item._id}
            expanded={expanded === item._id}
            onChange={handleChange(item._id)}
            className={classes.accordonMain}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  className={`${classes.expandIcon} ${
                    expanded === item._id ? classes.rotated : ""
                  }`}
                />
              }
              aria-controls={`${item._id}-content`}
              id={`${item._id}-header`}
            >
              <Typography className={classes.accordionTitle}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.accordionContent}>
                {item.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {/* More Queries */}
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "22px",
          margin: "2em 0px",
        }}
      >
        For more queries, please
        <span style={{ cursor: "pointer", color: "#3374B9" }}> click here</span>
      </Typography>
    </Box>
  );
};

export default DrawerMain;
