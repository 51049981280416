import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";

// redux imports
import { useSelector } from "react-redux";

import RiskSurveyInTop from "../../widgets/riskSurveyComponents/Inner/RiskSurveyInTop";
import RiskSurveyInMain from "../../widgets/riskSurveyComponents/Inner/RiskSurveyInMain";

const RiskSurveyInnerContainer = () => {
  // const history = useHistory();

  // // from redux
  // const { selectedSurveyData } = useSelector((state) => state.riskDashboard);

  // if (!selectedSurveyData) {
  //   history.push(`/risk_survey`);
  // }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        px: 5,
      }}
    >
      {/* Main areas */}
      {/* <RiskSurveyInTop surveyData={selectedSurveyData}> */}
      <RiskSurveyInMain />
        {/* <RiskSurveyInTop surveyData={selectedSurveyData} /> */}
      {/* </RiskSurveyInMain> */}
      {/* </RiskSurveyInTop> */}
    </Box>
  );
};
export default RiskSurveyInnerContainer;
