import {
  AppBar,
  Icon,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { appTheme } from "../riskAssessment/brmDetaails";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";
import TabPanel from "../../UI/tabPanel/tabPanel";
import EsgScore from "../esgCompanyProfile/esgScore";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import CompanyProfile from "../esgCompanyProfile/companyProfile";
import DimentionCharts from "../esgDeclaration/dimentionChart/dimentionCharts";

function VendorProfile(props) {
  const [vendorProfileTabNo, setVendorProfileTabNo] = useState(0);
  const app = appbarStyles();
  const data = {
    scoreObject: {
      environment: {
        score: 47.83425775613276,
        sum_of_weighted_score: 0.47834257756132753,
        final_weighted_score: 0.2152541599025974,
        average: {
          energy: 0.2975694444444445,
          water: 0.4738095238095238,
          waste: 0.49653679653679655,
          analytic: 0.4454545454545454,
          green: 0.5,
        },
        weighted_score: {
          energy: 0.07439236111111112,
          water: 0.11845238095238095,
          waste: 0.12413419913419914,
          analytic: 0.11136363636363635,
          green: 0.05,
        },
        data_points: {
          energy: 7.4392361111111125,
          water: 11.845238095238095,
          waste: 12.413419913419913,
          analytic: 11.136363636363635,
          green: 5,
        },
      },
      social: {
        score: 52.42647058823529,
        sum_of_weighted_score: 0.524264705882353,
        final_weighted_score: 0.23591911764705883,
        average: {
          career: 0.625,
          dataPrivacy: 0.5,
          diversity: 0.5,
          health: 0.5416666666666666,
          humanRights: 0.4754901960784313,
          product: 0.5,
        },
        weighted_score: {
          career: 0.125,
          dataPrivacy: 0.1,
          diversity: 0.1,
          health: 0.05416666666666667,
          humanRights: 0.09509803921568627,
          product: 0.05,
        },
        data_points: {
          career: 12.5,
          dataPrivacy: 10,
          diversity: 10,
          health: 5.416666666666667,
          humanRights: 9.509803921568627,
          product: 5,
        },
      },
      governance: {
        score: 8.692905242905244,
        sum_of_weighted_score: 0.08692905242905244,
        final_weighted_score: 0.008692905242905244,
        average: {
          compensation: 0.355978835978836,
          structure: 0.25665584415584414,
        },
        weighted_score: {
          compensation: 0.0355978835978836,
          structure: 0.05133116883116883,
        },
        data_points: {
          compensation: 3.55978835978836,
          structure: 5.133116883116883,
        },
      },
    },
    esgScore: 45.98661827925614,
    esgcScore: 5.555555555555555,
    finalEsg: 25.77108691740585,
    energy_kpis: {
      "Total electricity consumption in Gigajoules  from renewable sources": 0,
      "Total fuel consumption in Gigajoules  from renewable sources": 0,
      "Total Energy consumption  in Gigajoules  from renewable sources ": 0,
      "Total electricity consumption in Gigajoules  from non renewable sources": 57122.44,
      "Total fuel consumption in Gigajoules  fromnon renewable sources": 0,
      "Total Energy consumption  in Gigajoules  from non renewable sources ": 0,
    },
    water_kpis: {
      "wasteCount withdrawal by source (in kilolitres)  from surface water": 0,
      "Water withdrawal by source (in kilolitres)  from ground water": 0,
      "Water withdrawal by source (in kilolitres)  from third party water ": 0,
      "Amount of rainwater was utilized  (in kilolitres) ": 0,
      " Volume of water withdrawal  (in kilolitres)": 0,
      "Total volume of water consumption (in kilolitres)  ": 78400,
      " Water intensity per rupee of turnover (Water consumed / turnover) ": 0,
    },
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Vendor Profile
          </Typography>
        </div>
        <div>
          {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          /> */}
          <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
            more_vert
          </Icon>
        </div>
      </div>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={app.AppBar}>
          <Tabs
            value={vendorProfileTabNo}
            onChange={(e, newVal) => {
              setVendorProfileTabNo(newVal);
            }}
            // onChange={handleTabChange}
          >
            <Tab
              disableRipple
              className={app.tab}
              label={<span className={app.tabLabel}>Vendor Score</span>}
            />
            <Tab
              disableRipple
              className={app.tab}
              label={<span className={app.tabLabel}>Vendor Assessment </span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider>

      <div
        className="scroll"
        style={{ height: "51vh", marginTop: "10px", paddingBottom: 20 }}
      >
        <TabPanel value={vendorProfileTabNo} index={0}>
          { (
            <EsgScore scores={data} />
          )}
        </TabPanel>
        <TabPanel value={vendorProfileTabNo} index={1}>
          <div className="scroll" style={{ height: "55vh" }}>
            <div style={{ display: "flex", gap: 10 }}>
              <div style={{ width: "40%" }}>
                <BlueBackgroundCard heading={"Assessment Score"} />
                <CompanyProfile scores={data} />
              </div>
              <div style={{ width: "60%" }}>
                <BlueBackgroundCard heading={"Dimentions"} />
                <DimentionCharts isProfile {...props} scores={data} />
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default VendorProfile;
