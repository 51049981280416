import React from 'react';
import { connect } from 'react-redux';
import SignupPage from "../../components/screen/signup/signupPage";


class SignupContainer extends React.PureComponent {
  render() {
    return <SignupPage {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,

});

const mapDispatchToProps = (dispatch) => ({
 
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);
