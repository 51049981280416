import React, { useEffect, useRef, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Icon,
  IconButton,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomButton from "../../../UI/button/button";
import TabPanel from "../../../UI/tabPanel/tabPanel";
import CancelIcon from "@material-ui/icons/Cancel";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomTextfield from "../../../UI/textfield/textfield";
import countryList from "../../../../utils/countries/countryList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Add from "@material-ui/icons/Add";
import { appTheme } from "../brmDetaails";
import RiskAnalysisRightTab from "../../riskAnalysisRightTab/riskAnalysisRightTab";
import { corporateSurveyQuestions } from "../../../../utils/corporateSurveyQuestion/corporateSurveyQuestions";
import SentimentAnalysisRightTab from "../../sentimentAnalysisRightTab/sentimentAnalysisRightTab";
import RiskSummaryTab from "../../riskSummaryTab/riskSummaryTab";
import SurveyQuestions from "../../surveyQuestions/surveyQuestions";
import PeerAnalysis from "../../peerAnalysis/peerAnalysis";
import EsgCompantProfile from "../../esgCompanyProfile/esgCompantProfile";
import PeerDocuments from "../../publicInfo/peerDocuments";
import ApiConnections from "../../publicInfo/apiConnections";
import Documents from "../../publicInfo/documents";
import { TurnoverYearPickerDialog } from "../riskAssessment";
import StakeholderList from "../stakeholderList";
import DataBreakdown from "../DataBreakdown";
import SurveyResponses from "../../surveyResponses/surveyResponses";
import Contoversies from "../../controversiesTab/controversies";
import EsgRiskRating from "../../esgRiskRating/esgRiskRating";
import SectorIndustry from "./SectorIndustry";
import ShareDialog from "./ShareDialog";
import UserDialog from "./UserDialog";
import { brmRightStyles } from "./brmRightStyle";
import BrmDataRightEvaluator from "./BrmRightEvaluator";

function BrmRightManager(props) {
  const classes = brmRightStyles();
  const { brmData, riskTabNo, setRiskTabNo, setOrganizationDetails, login } =
    props;

  const [turnoverData, setTurnoverData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);

  const [organizaitonDetailsObject, setOrganizaitonDetailsObject] = useState({
    name: "",
    operation: "",
    no_of_employees: "",
    isin: "",
    year_of_assessment: "",
    country_name: "",
    country_code: "",
    address: [
      { landMark: "", state: "", pinCode: "", companyAddress: "", country: "" },
    ],
  });

  useEffect(() => {
    brmData?.organizationDetails[0] &&
      setOrganizaitonDetailsObject(brmData?.organizationDetails[0]);
    setCompanyLogoURI(brmData?.organizationDetails[0]?.logo?.url);
    setTurnoverData(brmData?.organizationDetails[0]?.turnover || []);
    setRevenueData(brmData?.organizationDetails[0]?.revenue || []);
  }, [brmData?.organizationDetails]);

  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);

  React.useEffect(() => {
    props.viewMember(
      login?.token ? login?.token : localStorage.getItem("user_token"),
      brmData?.organizationDetails
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id")
    );
    // const member = await viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails[0]?._id
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    props.getCalculatedSurvey(
      login?.token ? login?.token : localStorage.getItem("user_token"),
      brmData?.organizationDetails[0]?._id
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id")
    );
    // setMembers(brmData.members);
  }, []);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  useEffect(() => {
    if (props.isEvaluator) {
      brmData?.brmMainTabNo === 2 &&
        brmData?.brmSubTabNo === 0 &&
        props.getScores(
          login.token,
          brmData?.organizationDetails[0]?.isin,
          brmData?.organizationDetails[0]?._id
        );
      brmData?.brmMainTabNo === 2 &&
        brmData?.brmSubTabNo === 3 &&
        props.getControversyData(
          login.token,
          brmData?.organizationDetails[0]?.isin
        );
    }
  }, [brmData?.brmMainTabNo, brmData?.brmSubTabNo]);

  const [sectors, setSectors] = useState([]);
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);

  useEffect(() => {
    if (
      brmData?.organizationDetails &&
      brmData?.organizationDetails[0].sectors &&
      brmData.organizationDetails[0].sectors.length > 0
    ) {
      let masterSectors = [...brmData.sectorIndustries];
      masterSectors.map((masterSec, masterSecIdx) => {
        return (
          brmData.organizationDetails[0].sectors.some(
            (sec) => sec.sector === masterSec._id
          ) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            brmData.organizationDetails[0].sectors.map((basicSec) =>
              basicSec.industry.map((basicInd) =>
                masterInd._id === basicInd
                  ? (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = true)
                  : (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = false)
              )
            )
          )
        );
      });

      setSectors(masterSectors);
    } else {
      setSectors(brmData.sectorIndustries);
    }
  }, [brmData.sectorIndustries, brmData.organizationDetails]);

  useEffect(() => {
    return () => {
      setSectors([]);
      setOrganizationDetails("");
    };
  }, []);

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    let sectorL = JSON.parse(JSON.stringify(sectors));

    sectorL = sectorL.map((sect, i) => {
      sect.industry_data.map((ind, j) => {
        if (sectorIndex === i && industryIndex === j) {
          ind.isSelected = true;
        } else {
          ind.isSelected = false;
        }
        return ind;
      });

      return sect;
    });

    setSectors(sectorL);
  };

  const [compayLogo, setCompanyLogo] = useState("");
  const [companyLogoURI, setCompanyLogoURI] = useState("");
  const handleClearCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoURI("");
  };
  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  useEffect(() => {
    // console.log("new mount", organizaitonDetailsObject.year_of_assessment);
    organizaitonDetailsObject.year_of_assessment &&
      setStartYear(organizaitonDetailsObject.year_of_assessment?.split("-")[0]);
    organizaitonDetailsObject.year_of_assessment &&
      setEndYear(organizaitonDetailsObject.year_of_assessment?.split("-")[1]);
  }, [organizaitonDetailsObject.year_of_assessment]);

  useEffect(() => {
    const data = [];
    if (!organizaitonDetailsObject.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (organizaitonDetailsObject.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(
          organizaitonDetailsObject.year_of_assessment.split("-")[0] - 3 + i
        );
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const [turnoverPicker, setTurnoverPicker] = useState(false);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");
  const currentIndex = useRef({
    i: 0,
    revenue: true,
  });
  useEffect(() => {
    const data = [];
    if (true || "!companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (false && "companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        // data.push(companyDetails.yearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [turnoverPicker]);
  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      setTStartYear(year);
      setTEndYear("");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  const handleTurnover = (i, revenue) => {
    setTurnoverPicker(true);
    currentIndex.current = { i, revenue: revenue };
  };
  const handleSetYear = () => {
    !currentIndex.current.revenue &&
      setTurnoverData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });
    currentIndex.current.revenue &&
      setRevenueData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });
    // setCompanyDetails((prev) => ({
    //   ...prev,
    //   yearOfAssessment: `${startYear}-${endYear}`,
    // }));
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };

  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };

  return (
    <>
      <CardContent className={classes.cardContent}>
        {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 0 && (
          <div style={{ height: "63vh" }} className={classes.mainDiv}>
            {/* <Card
                    variant="elevation"
                    id="drawer-container"
                    elevation={1}
                    className={classes.cardContainer}
                    data-tut="reactour__basicRight"
                    >
                    <CardContent style={{ padding: 10 }}> */}
            <Typography className={classes.cardTypography}>
              Basic Company Details
            </Typography>

            <div className="scroll" style={{ height: "61vh" }}>
              <Grid container>
                <Grid item xs={12} md={11}>
                  <Box my={2}>
                    <CustomTextfield
                      name="compName"
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="Company Name"
                      value={organizaitonDetailsObject?.name}
                      onChange={(e) => {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1} md={1}>
                  {!organizaitonDetailsObject?.name && (
                    <span
                      style={{
                        color: "red",
                        padding: "0px 3px",
                        display: "inline",
                      }}
                    >
                      *
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box my={2}>
                    <Typography className={classes.headerText}>
                      Company Logo
                    </Typography>
                  </Box>
                  <Box my={2}>
                    {/* {console.log(companyLogoURI)} */}
                    {!companyLogoURI ? (
                      <>
                        <input
                          id="company-logo"
                          type="file"
                          onChange={(event) => handleUploadCompanyLogo(event)}
                          style={{ display: "none" }}
                          cursor="pointer"
                        />
                        <label htmlFor="company-logo">
                          <div className={classes.logoSelecter}>
                            <AddCircleOutlineIcon
                              name="addLogoIcon"
                              fontSize="large"
                              className={classes.addIcon}
                            />
                          </div>
                        </label>
                      </>
                    ) : (
                      <div className={classes.companyLogo}>
                        <img
                          alt="company-logo"
                          accept="image/png, image/gif, image/jpeg"
                          src={companyLogoURI}
                        />
                        <IconButton
                          onClick={handleClearCompanyLogo}
                          className={classes.ClearImage}
                        >
                          <CancelIcon
                            style={{ color: "gray" }}
                            fontSize="small"
                          />
                        </IconButton>
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Box my={2}>
                    <Typography className={classes.headerText}>
                      Sector & Industry
                      {sectors?.length === 0 && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  className={classes.addSectorContainer}
                >
                  <CustomButton
                    onClick={() => {
                      setIsSectorDialogOpen(true);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Add Sector
                  </CustomButton>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box my={2}>
                    {sectors.length !== 0 ? (
                      sectors.map((sector) => (
                        <div style={{ marginBottom: 8 }}>
                          {sector.industry_data.some(
                            (inds) => inds.isSelected
                          ) && (
                            <>
                              <Typography
                                className={classes.selectedSectorTitle}
                              >
                                {sector.Sector}
                              </Typography>
                              {sector.industry_data.map(
                                (industry) =>
                                  industry.isSelected && (
                                    <Chip
                                      className={classes.sectorChip}
                                      label={industry.Industry}
                                    />
                                  )
                              )}
                            </>
                          )}
                        </div>
                      ))
                    ) : (
                      <Chip
                        className={classes.sectorChip}
                        label="Non Selected"
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box
                    my={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography className={classes.headerText}>
                      Revenue Data
                      {revenueData.length === 0 && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Typography>
                    <CustomButton
                      name="addBranch"
                      size="medium"
                      onClick={() => {
                        const initialPeer = {
                          revenue: "",
                          year: "",
                          currency: "",
                          figures: "",
                        };
                        const copy = [...turnoverData];
                        copy.push(initialPeer);
                        setRevenueData(copy);
                        window.scrollTo({
                          top: 50,
                          behavior: "smooth",
                        });
                        // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Add Revenue e
                    </CustomButton>
                  </Box>
                  {revenueData.map((revenue, i) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        key={revenue.revenue + i}
                      >
                        <Grid item xs={12} md={5}>
                          <CustomTextfield
                            name="turnovers"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="text"
                            label="Add Revenue"
                            value={revenue.revenue}
                            onChange={(e) => {
                              const copy = [...revenueData];
                              copy[i].revenue = parseInt(e.target.value);
                              setRevenueData(copy);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          role="button"
                          onClick={() => handleTurnover(i, true)}
                        >
                          <CustomTextfield
                            name="NumOfEmployee"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="text"
                            label="Year"
                            onChange={(e) => {
                              const copy = [...revenueData];
                              copy[i].year = e.target.value;
                              setRevenueData(copy);
                            }}
                            value={revenue.year}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <Icon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const copy = [...revenueData];
                              copy.splice(i, 1);
                              setRevenueData(copy);
                            }}
                          >
                            remove
                          </Icon>
                        </Grid>
                        <Grid item xs={5} md={5}>
                          <Autocomplete
                            id="country-select-demo"
                            size="small"
                            value={revenue.currency}
                            options={["USD", "AED", "INR"]}
                            classes={{
                              endAdornment: classes.icon,
                              root: {},
                            }}
                            // inputProps={{
                            //   classes: {
                            //     icon: classes.icon,
                            //     root:{}
                            //   },
                            // }}
                            autoHighlight
                            getOptionLabel={(option) => `${option}`}
                            renderOption={(option) => (
                              <React.Fragment>{option}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                label="Currency"
                                variant="outlined"
                                value={revenue.currency}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                newValue &&
                                  setRevenueData((prev) => ({
                                    ...newValue,
                                    currency: newValue,
                                    // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                                  }));
                                // setBranchCountry(newValue);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={5} md={6}>
                          <Autocomplete
                            id="country-select-demo"
                            size="small"
                            value={revenue.figures}
                            options={["Million", "Billion", "Crore"]}
                            classes={{
                              endAdornment: classes.icon,
                              root: {},
                            }}
                            // inputProps={{
                            //   classes: {
                            //     icon: classes.icon,
                            //     root:{}
                            //   },
                            // }}
                            autoHighlight
                            getOptionLabel={(option) => `${option}`}
                            renderOption={(option) => (
                              <React.Fragment>{option}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                label="Figures"
                                variant="outlined"
                                value={revenueData.figures}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setRevenueData((prev) => ({
                                  ...prev,
                                  figures: newValue,
                                }));
                                // setBranchCountry(newValue);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item md={12} style={{ paddingBottom: 20 }}>
                  <Box
                    my={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography className={classes.headerText}>
                      Turnover Data
                    </Typography>
                    <CustomButton
                      name="addBranch"
                      size="medium"
                      onClick={() => {
                        const initialPeer = {
                          turnover: "",
                          year: "",
                          currency: "",
                          figures: "",
                        };
                        const copy = [...turnoverData];
                        copy.push(initialPeer);
                        setTurnoverData(copy);
                        window.scrollTo({
                          top: 50,
                          behavior: "smooth",
                        });
                        // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Add Turnover
                    </CustomButton>
                  </Box>
                  {turnoverData.map((turnover, i) => {
                    return (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={5}>
                          <CustomTextfield
                            name="turnovers"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="text"
                            label="Add Turnover"
                            value={turnover.turnover}
                            onChange={(e) => {
                              const copy = [...turnoverData];
                              copy[i].turnover = parseInt(e.target.value);
                              setTurnoverData(copy);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          role="button"
                          onClick={() => handleTurnover(i, false)}
                        >
                          <CustomTextfield
                            name="NumOfEmployee"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="text"
                            label="Year"
                            onChange={(e) => {
                              const copy = [...turnoverData];
                              copy[i].domain = e.target.value;
                              setTurnoverData(copy);
                            }}
                            value={turnover.year}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <Icon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const copy = [...turnoverData];
                              copy.splice(i, 1);
                              setTurnoverData(copy);
                            }}
                          >
                            remove
                          </Icon>
                        </Grid>
                        <Grid item xs={5} md={5}>
                          <Autocomplete
                            id="country-select-demo"
                            size="small"
                            value={turnover.currency}
                            options={["USD", "AED", "INR"]}
                            classes={{
                              endAdornment: classes.icon,
                              root: {},
                            }}
                            // inputProps={{
                            //   classes: {
                            //     icon: classes.icon,
                            //     root:{}
                            //   },
                            // }}
                            autoHighlight
                            getOptionLabel={(option) => `${option}`}
                            renderOption={(option) => (
                              <React.Fragment>{option}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                label="Currency"
                                variant="outlined"
                                value={turnoverData.currency}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setTurnoverData((prev) => ({
                                  ...prev,
                                  currency: newValue,
                                  // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                                }));
                                // setBranchCountry(newValue);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={5} md={6}>
                          <Autocomplete
                            id="country-select-demo"
                            size="small"
                            value={turnover.figures}
                            options={["Million", "Billion", "Crore"]}
                            classes={{
                              endAdornment: classes.icon,
                              root: {},
                            }}
                            // inputProps={{
                            //   classes: {
                            //     icon: classes.icon,
                            //     root:{}
                            //   },
                            // }}
                            autoHighlight
                            getOptionLabel={(option) => `${option}`}
                            renderOption={(option) => (
                              <React.Fragment>{option}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                label="Figures"
                                variant="outlined"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setTurnoverData((prev) => ({
                                  ...prev,
                                  figures: newValue,
                                }));
                                // setBranchCountry(newValue);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid item xs={12} md={11}>
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    // style={{ width: 300 }}
                    value={{
                      country_name: organizaitonDetailsObject?.country_name,
                    }}
                    options={brmData.countries}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.country_name}
                    renderOption={(option) => (
                      <React.Fragment>{option.country_name}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Choose a country"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          country_name: newValue?.country_name,
                          country: newValue?.country_code,
                          // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                        }));
                        // setBranchCountry(newValue);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  {!organizaitonDetailsObject?.country_name && (
                    <span
                      style={{
                        color: "red",
                        padding: "0px 3px",
                        display: "inline",
                      }}
                    >
                      *
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={11}>
                  <Box my={2}>
                    <CustomTextfield
                      name="NumOfEmployee"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      label="ISIN Number"
                      onChange={(e) => {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          isin: e.target.value,
                        }));
                      }}
                      value={organizaitonDetailsObject?.isin}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1} md={1}>
                  {!organizaitonDetailsObject?.isin && (
                    <span
                      style={{
                        color: "red",
                        padding: "0px 3px",
                        display: "inline",
                      }}
                    >
                      *
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={11}>
                  <Box
                    my={2}
                    onClick={() => setIsDatePickerDialogOpen(true)}
                    style={{
                      border: "1px solid #00000050",
                      padding: 12,
                      borderRadius: 5,
                      cursor: "pointer",
                      minHeight: 45,
                    }}
                  >
                    {organizaitonDetailsObject.year_of_assessment ? (
                      <Typography variant="body1" name="yearOfAssesment">
                        {`${
                          organizaitonDetailsObject.year_of_assessment.split(
                            "-"
                          )[0]
                        } - ${
                          organizaitonDetailsObject.year_of_assessment.split(
                            "-"
                          )[1]
                        }`}
                      </Typography>
                    ) : (
                      <Typography
                        style={{ color: "#878282e6", fontFamily: "Roboto" }}
                      >
                        Year Of Assessment
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={1} md={1}>
                  {!organizaitonDetailsObject?.year_of_assessment && (
                    <span
                      style={{
                        color: "red",
                        padding: "0px 3px",
                        display: "inline",
                      }}
                    >
                      *
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={11}>
                  <Box my={2}>
                    <CustomTextfield
                      name="compOperation"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      size="small"
                      label="Company Operation"
                      onChange={(e) => {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          operation: e.target.value,
                        }));
                      }}
                      value={organizaitonDetailsObject?.operation}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Box my={2}>
                    <CustomTextfield
                      name="NumOfEmployee"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      label="Number of Employees"
                      onChange={(e) => {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          no_of_employees: e.target.value,
                        }));
                      }}
                      value={organizaitonDetailsObject?.no_of_employees}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={11}>
                  <Box my={2}>
                    <Typography className={classes.headerText}>
                      Company Address
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <CustomTextfield
                      name="compAddress"
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="Street/Location"
                      value={
                        organizaitonDetailsObject?.address &&
                        organizaitonDetailsObject?.address[0]?.companyAddress
                      }
                      onChange={(e) => {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          address: [
                            {
                              ...prev?.address[0],
                              companyAddress: e.target.value,
                            },
                          ],
                        }));
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <RoomOutlinedIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box my={2}>
                    <CustomTextfield
                      variant="outlined"
                      name="compLandmark"
                      fullWidth
                      size="small"
                      label="Landmark"
                      value={
                        organizaitonDetailsObject?.address &&
                        organizaitonDetailsObject?.address[0]?.landMark
                      }
                      onChange={(e) => {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          address: [
                            {
                              ...prev?.address[0],
                              landMark: e.target.value,
                            },
                          ],
                        }));
                      }}
                    />
                  </Box>
                  <Box my={2}>
                    <CustomTextfield
                      name="compPincode"
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="Pincode"
                      type="number"
                      value={
                        organizaitonDetailsObject?.address &&
                        organizaitonDetailsObject?.address[0]?.pinCode
                      }
                      onChange={(e) => {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          address: [
                            {
                              ...prev?.address[0],
                              pinCode: e.target.value,
                            },
                          ],
                        }));
                      }}
                    />
                  </Box>
                  <Box my={2}>
                    <CustomTextfield
                      name="compState"
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="State"
                      value={
                        organizaitonDetailsObject?.address &&
                        organizaitonDetailsObject?.address[0]?.state
                      }
                      onChange={(e) => {
                        setOrganizaitonDetailsObject((prev) => ({
                          ...prev,
                          address: [
                            {
                              ...prev?.address[0],
                              state: e.target.value,
                            },
                          ],
                        }));
                      }}
                    />
                  </Box>
                  <Box my={2}>
                    <Autocomplete
                      name="compCountry"
                      id="countryCode"
                      options={countryList}
                      getOptionLabel={(option) => option}
                      renderOption={(option) => option}
                      size="small"
                      renderInput={(params) => (
                        <CustomTextfield
                          {...params}
                          label="Country"
                          variant="outlined"
                        />
                      )}
                      value={
                        organizaitonDetailsObject?.address &&
                        organizaitonDetailsObject?.address[0]?.country
                          ? organizaitonDetailsObject?.address[0]?.country
                          : ""
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setOrganizaitonDetailsObject((prev) => ({
                            ...prev,
                            address: [
                              {
                                ...prev?.address[0],
                                country: newValue,
                              },
                            ],
                          }));
                        }
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <CustomButton
                    style={{
                      float: "right",
                      textTransform: "none",
                      fontSize: "13px",
                      color: "#3374B9",
                    }}
                    disabled={
                      !organizaitonDetailsObject.isin ||
                      !organizaitonDetailsObject?.country_code ||
                      !organizaitonDetailsObject.yearOfAssessment ||
                      !revenueData.filter(
                        (d) =>
                          d.revenue !== "" &&
                          d.year !== "" &&
                          d.figures !== "" &&
                          d.currency !== ""
                      ).length
                    }
                    onClick={() => {
                      props.updateOrganization(
                        login.token,
                        brmData?.organizationDetails[0]?._id
                          ? brmData?.organizationDetails[0]?._id
                          : localStorage.getItem("organization_id"),
                        organizaitonDetailsObject?.name,
                        organizaitonDetailsObject?.isin,
                        organizaitonDetailsObject?.year_of_assessment,
                        compayLogo,
                        sectors,
                        organizaitonDetailsObject?.operation,
                        organizaitonDetailsObject?.no_of_employees,
                        organizaitonDetailsObject?.address,
                        brmData.sectorIndustries,
                        revenueData.filter(
                          (d) =>
                            d.revenue !== "" &&
                            d.year !== "" &&
                            d.currency !== "" &&
                            d.figures !== ""
                        ),
                        turnoverData.filter(
                          (d) =>
                            d.turnover !== "" &&
                            d.year !== "" &&
                            d.currency !== "" &&
                            d.figures !== ""
                        ),
                        organizaitonDetailsObject?.country
                      );
                    }}
                  >
                    Update Portfolio
                  </CustomButton>
                  <CustomButton
                    onClick={() =>
                      setOrganizaitonDetailsObject(
                        brmData?.organizationDetails[0]
                      )
                    }
                    style={{
                      float: "right",
                      textTransform: "none",
                      fontSize: "13px",
                      color: "#3374B9",
                    }}
                  >
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid xs={7}></Grid>
                <Grid item xs={9}>
                  {/* {LocationStore} */}
                </Grid>
              </Grid>
            </div>
            {/* </CardContent>
</Card> */}
          </div>
        )}
        {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 1 && (
          <StakeholderList
            setAddNewUserDialogOpen={setAddNewUserDialogOpen}
            // handleEditUser={handleEditUser}
            brmData={brmData}
          />
        )}
        {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 2 && (
          <SurveyQuestions
            login={login}
            brmData={brmData}
            setShareDialogOpen={setShareDialogOpen}
            getSurveyeQuestions={props.getSurveyeQuestions}
            addSurveyeQuestions={props.addSurveyeQuestions}
            corporateSurveyQuestions={corporateSurveyQuestions}
          />
        )}
        {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 0 && (
          <div style={{ height: "63vh" }} className={classes.mainDiv}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                {" "}
                <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
                  Stakeholders Status
                </Typography>
              </div>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Add />}
                  style={{ cursor: "pointer" }}
                  color="primary"
                  disabled={
                    brmData.members &&
                    brmData.members.length > 0 &&
                    brmData.members.filter((member) => member.status === "Sent")
                      .length > 0
                  }
                  onClick={() => setShareDialogOpen(true)}
                >
                  Share Survey
                </Button>
              </div>
            </div>
            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              style={{
                marginTop: "10px",
                backgroundColor: "#EBF1F8",
                padding: "3px",
                borderRadius: "5px",
              }}
            >
              <Grid item md={5} xs={4}>
                <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                  Name E-mail
                </Typography>
              </Grid>
              <Grid item md={3} xs={3}>
                <Typography style={{ fontSize: "13px" }}>Category</Typography>
              </Grid>
              <Grid item md={2} xs={3}>
                <Typography style={{ fontSize: "13px" }}>Status</Typography>
              </Grid>
              <Grid item md={2} xs={3} align="center">
                <Typography style={{ fontSize: "13px" }}>Role</Typography>
              </Grid>
              {/* <Grid item md={1} xs={1}>
                    <Typography
                      style={{ fontSize: "13px", textAlign: "center" }}
                    >
                      Edit
                    </Typography>
                  </Grid> */}
            </Grid>
            {brmData.members &&
              brmData.members.length > 0 &&
              brmData.members.map((member) => (
                <Grid
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "10px",
                    padding: "5px",
                    borderBottom: "1px solid grey",
                    borderRadius: "2px",
                    alignItems: "center",
                  }}
                >
                  <Grid item md={5} xs={4}>
                    <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                      {member.name}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        marginLeft: "5px",
                        color: "grey",
                      }}
                    >
                      {member.email}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <Typography style={{ fontSize: "13px" }}>
                      {member.category}
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <Typography
                      style={{
                        diaplay: "inline-flex",
                        alighItems: "center",
                      }}
                    >
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        // bgcolor={true ? '#EBF6EF' : '#E9E9E9'}
                        p={1}
                        style={{
                          padding: "8px",
                        }}
                        borderRadius="8px"
                      >
                        <Box
                          borderRadius="50%"
                          height={10}
                          width={10}
                          bgcolor={
                            // "#5BB37F"
                            member.status === "Sent" && "#5BB37F"
                              ? member.status === "Not Sent"
                                ? "#5BB37F"
                                : "#5BB37F"
                              : "#9FA1AF"
                          }
                        />
                        <Box
                          style={{
                            paddingLeft: "5px",
                            fontSize: "14px",
                            color:
                              member.status === "Sent" && "#5BB37F"
                                ? member.status === "Not Sent"
                                  ? "#5BB37F"
                                  : "#5BB37F"
                                : "#9FA1AF",
                          }}
                        >
                          {member.status}
                        </Box>
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <Typography style={{ fontSize: "13px" }} align="center">
                      {member.role}
                    </Typography>
                  </Grid>
                  {/* <Grid item md={1} xs={1} align="center">
                        <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
                      </Grid> */}
                </Grid>
              ))}
          </div>
        )}
      </CardContent>
    </>
  );
}

export default BrmRightManager;
