import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import "../../../styles/drawer.css";
import data from '../../../utils/policies/tncData'
import tncUseStyles from "./tncStyles";



export default function TnC() {
  const classes = tncUseStyles();
 
  const [expanded, setExpanded] = React.useState("");

  const onExpandChange = (title) => {
    if (expanded === title) {
      setExpanded("");
    } else {
      setExpanded(title);
    }
  };

  function changeBackground(e) {
    e.target.style.background = "#F5F6F8";
  }
  function normalBackground(e) {
    e.target.style.background = "white";
  }
  return (
    <>
      <Grid container className={classes.paddings}>
    
        <Grid item xs={12} className={classes.mainGrid}>
          <Grid container>
        
            <Grid item xs={3} sm={3} md={3} className={classes.leftGrid}>
           
              <Typography
                variant="h6"
                color="primary"
                className={classes.texts}
                style={{ margin: "25px 0px" }}
              >
                <span
                  className={classes.name}
                  onMouseOver={changeBackground}
                  onMouseOut={normalBackground}
                >
                  General Terms
                </span>
              </Typography>
            </Grid>
            <Grid item xs={9} sm={9} md={6} axis="x">
              <Grid
                item
                xs={12}
                style={{ marginTop: "50px", marginLeft: "-20px" }}
                className="desc"
              >
                <Typography variant="body2" className="desc">
                  This document is an electronic record in terms of Information
                  Technology Act, 2000 and rules there under as applicable and
                  the amended provisions pertaining to electronic records in
                  various statutes as amended by the Information Technology Act,
                  2000. This electronic record is generated by a computer system
                  and does not require any physical or digital signatures.
                </Typography>
                <br />
                <Typography variant="body2" className="desc">
                  This document is published in accordance with the provisions
                  of Rule 3 (1)(a) of the Information Technology (Intermediary
                  Guidelines and Digital Media Ethics Code) Rules, 2021 that
                  require publishing the rules and regulations, privacy policy
                  and Terms of Use for access or usage of domain name/ website
                  [www.billionlives.in]
                  <strong>(“Website”, “Platform”)</strong>.
                </Typography>
                <br />
                <Typography variant="body2" className="desc">
                  This document is a legally binding agreement between a user
                  (collectively referred to as{" "}
                  <strong>“you”, “your”, “User”</strong> hereinafter) who access
                  or use or transact on the Platform and avail Service for a
                  commercial purpose only and the BillionLives Business
                  Initiatives Pvt Ltd (referred to as <strong>"we"</strong>,{" "}
                  <strong>"our"</strong> or <strong>"BillionLives"</strong>{" "}
                  hereinafter).
                </Typography>
                <br />
                <Typography variant="body2" className="desc">
                  This document and such other rules and policies of the
                  Platform (including but not limited to Privacy Policy as may
                  be amended from time to time are collectively referred to
                  below as the <strong>“Terms”.</strong>
                  We reserve the right, at our sole discretion, to change,
                  modify, add or remove portions of these Terms, at any time
                  without any prior written notice to You. By accessing,
                  browsing, or otherwise using the Platform or using the
                  Services, including following the posting of changes, User
                  agrees to accept and be bound by the Terms (as may be amended
                  from time to time). It is your responsibility to review these
                  Terms periodically for any updates / changes. Please do not
                  use the Services or access the Platform if you do not accept
                  the Terms or are unable to be bound by the Terms.
                </Typography>
                <br />
                <Typography variant="body2" className="desc">
                  PLEASE READ THE TERMS CAREFULLY BEFORE PROCEEDING IF YOU DO
                  NOT AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE THE
                  PLATFORM OR THE SERVICES PROVIDED BY THE PLATFORM OR
                  BILLIONLIVES. By impliedly or expressly accepting these Terms,
                  You also accept and agree to be bound by any amendments,
                  updates and modifications to the Terms and the other policies
                  (including but not limited to, Privacy Policy), as maybe
                  amended, updated and modified from time to time.
                </Typography>
                <br />
                <Typography variant="body2" className="desc">
                  Additional terms and conditions may apply to You in respect of
                  availing specific services and/or to specific portions or
                  features of the Platform, including but not limited to,
                  Services, any other additional services as may be offered by
                  us from time to time, contests, offers, schemes, promotions or
                  other similar features, all of which terms are to be read as
                  part of these Terms. You agree to abide by such other terms
                  and conditions, including, where applicable, representing that
                  You have the legal capacity to use or participate in such
                  service or feature. If there is a conflict between these Terms
                  and the terms posted for or applicable to a specific portion
                  of the Platform or for any specific service offered on or
                  through the Platform, the latter terms shall control with
                  respect to your use of that portion of the Platform or the
                  specific service.
                </Typography>
                <br />
              </Grid>
              <Grid item xs={9} md={6}>
                <div className="accordion" style={{ marginLeft: "-20px" }}>
                  {data.map((s) => {
                    return (
                      <Accordion
                        style={{
                          boxShadow: "0px 0px 0px 0px",
                          borderBottom: "0.5px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "0px",
                        }}
                      >
                        <AccordionSummary
                          style={{ padding: "12px" }}
                         
                          expandIcon={
                            expanded !== s.title ? (
                              <Add />
                            ) : (
                              <Remove style={{ color: "#3374b9" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          onClick={() => onExpandChange(s.title)}
                        >
                          <Typography
                            id={expanded !== s.title ? "" : "question"}
                            className="que-div"
                          >
                            {s.title}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails className="content">
                          <Typography className="ans-div">
                            {s.desc.map((a) => {
                              return (
                                <ol key={a}>
                                  <div>
                                    {a}
                                    <br />
                                    <br />
                                  </div>
                                </ol>
                              );
                            })}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
