import React from "react";

import { Grid, Typography } from "@material-ui/core";

import EsgGptMainRight from "./EsgMainRight";
import EsgGptMainLeft from "./EsgMainLeft";
import EsgMOreBUttons from "../../widgets/esgGpt/EsgGptMoreBUtton";

function EsgGptMain(props) {
  return (
    <Grid container style={{ height: "85vh" }}>
      {/* <Grid item style={{border:"solid"}}> */}
      <EsgGptMainLeft width={65}>
        <EsgMOreBUttons login={props.login} logOut={props.logOut} />
      </EsgGptMainLeft>
      {/* </Grid> */}
      <Grid item md={12} sm={12} style={{ height: "100%" }}>
        <EsgGptMainRight />
      </Grid>
    </Grid>
  );
}

export default EsgGptMain;
