import React from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Typography,
  IconButton,
  MenuItem,
  TextField,
  Divider,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CustomButton from "../../../../../../UI/button/button";
import copy from "../../../../../../../images/copy.png";
import trash from "../../../../../../../images/trashRed.png";
// import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { CustomList } from "../QuestionTabComponents/SectionList";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import QuestionBody from "../QuestionTabComponents/QuestionBody";

import {
  addQuestion,
  setQuestions,
  updateQuestion,
  setSelectedSection,
  setSection,
} from "../../../../../../../redux/actions/riskdashboard/riskDashboardActions";
import QuestionTabDrawer from "../QuestionTabComponents/QuestionTabDrawer";
import SaveDetailsDialog from "../../SaveDetailsDialog";

const QuestionLayout = ({
  questionType,
  handleCloseSelect,
  handleOpenSelect,
  menuOpen,
  questionOption,
  handleMoreListOption,
  questionText,
  weightage,
  id,
  hasNewlyAdded,
  questionData,
  selectedSection,
  sectionId,
  defaultSection,
  questionLength,
  latestVersion,
  sectionWiseQuestion,
  sectionWiseData,
}) => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);

  const [selectedQuestionId, setSelectedQuestionId] = React.useState(null);

  const [OpenDrawer, setOpenDrawer] = React.useState(false);

  const [checkBoxOption, setCheckBoxOption] = React.useState([]);

  const [openAlert, setOpenAlert] = React.useState(false);

  const [openDefaultEditAlert, setOpenDefaultEditAlert] = React.useState(false);

  const disableOptions =
    selectedSection !== "Self Assessment Section" &&
    selectedSection !== "Controversy Survey";

  const handleDrawer = (value) => {
    setOpenDrawer(value);
  };

  const handleQuestionSave = (i, data, hasSave) => {
    if (hasSave) {
      const handleAnswer =
        data.question_type === "Multichoice" ? checkBoxOption : null;
      if (data?._id === "temporary") {
        dispatch(
          addQuestion(
            login?.token,
            data?.question,
            data?.question_type,
            sectionId || "",
            handleAnswer,
            latestVersion,
            questionLength
          )
        );
      } else {
        dispatch(
          updateQuestion(
            login?.token,
            data?._id,
            data?.weightage,
            data?.question,
            data?.active,
            handleAnswer,
            data?.question_type,
            sectionId,
            data?.comment,
            data?.mandatory,
            latestVersion
          )
        );
      }

      setSelectedQuestion(null);
      setSelectedQuestionId(null);
    } else {
      setSelectedQuestion(data);
      setSelectedQuestionId(i);
    }
  };

  const handleNewQuestion = (data) => {
    setSelectedQuestion(data);
    setSelectedQuestionId(data?._id);
  };

  hasNewlyAdded && selectedQuestion === null && handleNewQuestion(questionData);

  const hasLocalState = hasNewlyAdded || selectedQuestionId === id;

  const handleQuestionUpdate = (e, i, propertyName) => {
    const updatedQuestion = {
      ...selectedQuestion,
      [propertyName]:
        propertyName === "question_type" ? e?.primary : e.target.value,
    };
    setSelectedQuestion(updatedQuestion);
    propertyName === "question_type" && handleCloseSelect();
  };
  const boxStyle =
    selectedQuestionId === id
      ? {
          borderLeft: "4px solid #3374B9",
          boxShadow: "0px 11px 20px 0px rgba(5, 16, 55, 0.10)",
          marginBottom: "15px",
        }
      : {
          borderLeft: "1px solid #76A2D0",
          // marginTop: "10px",
        };

  const handleQuestionDelete = (id, selectedQuestion, flag) => {
    if (questionLength === 1) {
      setOpenAlert(true);
    } else {
      handleQuestionSave(id, { ...selectedQuestion, active: false }, flag);
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setOpenDefaultEditAlert(false);
  };

  const handleTemporaryQuestionCancel = () => {
    if (sectionWiseQuestion.length === 1) {
      // dispatch(
      //   setQuestions(sectionWiseQuestion?.filter((q) => q?._id !== "temporary"))
      // );
      dispatch(
        setSelectedSection(sectionWiseData[sectionWiseData.length - 2]?.title)
      );
      dispatch(
        setSection(sectionWiseData?.filter((s) => s?.title !== "section"))
      );
    } else {
      dispatch(
        setQuestions(sectionWiseQuestion?.filter((q) => q?._id !== "temporary"))
      );
    }
  };

  const handleQuestionType = hasLocalState
    ? selectedQuestion?.question_type
    : questionType;

  return (
    <Box style={boxStyle}>
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px 25px",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Question"
          variant="outlined"
          fullWidth
          style={{
            width:
              selectedQuestionId === id
                ? "65%"
                : selectedQuestionId !== id
                ? "80%"
                : "75%",
            pointerEvents: selectedQuestionId === id ? "auto" : "none",
          }}
          size="small"
          value={hasLocalState ? selectedQuestion?.question : questionText}
          onChange={(e) => handleQuestionUpdate(e, id, "question")}
          disabled={selectedQuestionId && !disableOptions}
        />

        {/*  question type */}

        {selectedQuestionId === id && (
          <FormControl variant="outlined" style={{ width: "20%" }} size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Type of Question
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                hasLocalState ? selectedQuestion?.question_type : questionType
              }
              onClose={() => handleCloseSelect()}
              onOpen={() => handleOpenSelect()}
              label="Type of Question"
              open={menuOpen}
              disabled={selectedQuestionId && !disableOptions}
            >
              <CustomList
                value={
                  hasLocalState ? selectedQuestion?.question_type : questionType
                }
                list={questionOption}
                handleListIcon={handleMoreListOption}
                handleListClick={(e) =>
                  handleQuestionUpdate(e, id, "question_type")
                }
                isMenuItem={true}
              >
                {hasLocalState ? selectedQuestion?.question_type : questionType}
              </CustomList>
            </Select>
          </FormControl>
        )}
        {/* question weightage */}

        {weightage ? (
          <FormControl variant="outlined" style={{ width: "10%" }} size="small">
            {/* <InputLabel id="demo-simple-select-outlined-label">
              Type of Question
            </InputLabel> */}
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={hasLocalState ? selectedQuestion?.weightage : weightage}
              onChange={(e) => handleQuestionUpdate(e, id, "weightage")}
              disabled={!selectedQuestionId && !disableOptions}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((weight) => (
                <MenuItem key={weight} value={weight}>
                  {weight}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {selectedQuestionId !== id && (
          <IconButton
            onClick={() =>
              defaultSection
                ? setOpenDefaultEditAlert(true)
                : handleQuestionSave(id, questionData, false)
            }
          >
            <EditOutlinedIcon style={{ color: "#3374B9" }} />
          </IconButton>
        )}
      </Box>
      {/* main question body */}
      <Box
        style={{
          width: "100%",
          padding: "25px 25px",
          pointerEvents: selectedQuestionId === id ? "auto" : "none",
        }}
      >
        <QuestionBody
          questionType={handleQuestionType}
          answers={
            handleQuestionType === "Multichoice" ? questionData?.answers : null
          }
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
        />
      </Box>
      {/* edit question buttons */}
      {selectedQuestionId === id && (
        <Box>
          <Divider
            variant="middle"
            style={{ marginTop: "10px", border: "1px solid #C0D4E9" }}
          />
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <CustomButton
              style={{ textTransform: "none", marginRight: 10 }}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => handleQuestionSave(id, selectedQuestion, true)}
            >
              <Typography>Save</Typography>
            </CustomButton>

            {!defaultSection && (
              <CustomButton
                style={{ textTransform: "none", marginRight: 10 }}
                size="small"
                color="primary"
                variant="text"
                onClick={() => handleDrawer(true)}
              >
                <Typography>Add Rule</Typography>
              </CustomButton>
            )}
            {!defaultSection && (
              <IconButton>
                <img src={copy} style={{ height: "20px" }} alt="upload" />
              </IconButton>
            )}
            {!defaultSection && (
              <IconButton
                onClick={() => handleQuestionDelete(id, selectedQuestion, true)}
                disabled={id === "temporary" ? true : false}
              >
                <img src={trash} style={{ height: "20px" }} alt="upload" />
              </IconButton>
            )}
            <CustomButton
              style={{ textTransform: "none", marginRight: 10 }}
              size="small"
              color="primary"
              variant="text"
              onClick={() =>
                id === "temporary"
                  ? handleTemporaryQuestionCancel()
                  : handleQuestionSave(null, null, false)
              }
            >
              <Typography>Cancel</Typography>
            </CustomButton>
          </Box>
        </Box>
      )}
      {OpenDrawer && (
        <QuestionTabDrawer
          headerName={"Question Settings"}
          buttonText={"Add Rules"}
          open={OpenDrawer}
          handleClose={handleDrawer}
        >
          <FormControl
            style={{ width: "100%", padding: "20px" }}
            component="fieldset"
          >
            <FormGroup style={{ gap: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedQuestion?.mandatory}
                    onChange={(e) =>
                      setSelectedQuestion((preValue) => ({
                        ...preValue,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                    name="mandatory"
                    color="primary"
                  />
                }
                label="Make Question Mandatory"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedQuestion?.comment}
                    onChange={(e) =>
                      setSelectedQuestion((preValue) => ({
                        ...preValue,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                    name="comment"
                    color="primary"
                  />
                }
                label="Add comment box for the question"
              />
              <FormControlLabel
                control={
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Question hints"
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                  />
                }
                // label="Make Section Mandatory"
              />
            </FormGroup>
          </FormControl>
        </QuestionTabDrawer>
      )}
      {openAlert && (
        <SaveDetailsDialog
          open={openAlert}
          handleClose={handleCloseAlert}
          title={"Section Delete"}
          contentText={
            "There are no questions inside this section. would you like to delete the entire section with it ?"
          }
          leftButtonText={"Keep the question"}
          rightButtonText={"Delete section"}
          sectionId={sectionId}
          login={login}
        />
      )}
      {openDefaultEditAlert && (
        <SaveDetailsDialog
          open={openDefaultEditAlert}
          handleClose={handleCloseAlert}
          title={"Default Section Edit"}
          contentText={
            "In the default survey section, you can only edit the question's weightage. The question, type, and options are fixed for this section. Customize the weightage as needed"
          }
          leftButtonText={"Cancel"}
          rightButtonText={"Continue to edit"}
          sectionId={sectionId}
          login={login}
          handleSave={() => {
            handleQuestionSave(id, questionData, false);
            setOpenDefaultEditAlert(false);
          }}
        />
      )}
      {(questionData?.comment || selectedQuestion?.comment) && (
        <Box
          style={{
            width: "100%",
            padding: "25px 25px",
            pointerEvents: selectedQuestionId === id ? "auto" : "none",
          }}
        >
          <QuestionBody
            questionType={"Comment box"}
            // answers={
            //   handleQuestionType === "Multichoice" ? questionData?.answers : null
            // }
            // checkBoxOption={checkBoxOption}
            // setCheckBoxOption={setCheckBoxOption}
          />
        </Box>
      )}
    </Box>
  );
};

export default QuestionLayout;
