import {
  SET_VENODR_SUB_TAB_NO,
  SET_VENODR_TAB_NO,
} from "../../../constants/vendorConstants";

export const setVendorTabNo = (payload) => ({
  type: SET_VENODR_TAB_NO,
  payload,
});
export const setVendorSubTabNo = (payload) => ({
  type: SET_VENODR_SUB_TAB_NO,
  payload,
});
