import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import CachedIcon from "@material-ui/icons/Cached";

import ListItemText from "@material-ui/core/ListItemText";
import {
  setAcknowledged,
  setNewCompanyDetails,
  setSearcheText,
  setSearchedOrg,
  viewOrganizationEsgGpt,
} from "../../../redux/actions/gpt/esgGptAction";
import { Typography } from "@material-ui/core";
import CustomCompaniesRange from "../../UI/CustomRange/CustomCompaniesRange";
import { useHistory } from "react-router-dom";
import CustomButton from "../../UI/button/button";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    // flex:1

    // overflow: "scroll",
  },
}));

export const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },

    color: "#6C6C6C",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#EBF1F8",
    color: "#15314E",
    fontSize: "14px",
    fontWeight: 600,
  },
})(MuiListItem);

export default function EsgGptCompanyList() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const login = useSelector((state) => state.login);

  const {
    organizations,
    totalCompanies,
    searchedOrganizations,
    newOrganization,
    acknowledged,
  } = useSelector((state) => state.esgGptReducer);

  // console.log("searchedOrganizations", searchedOrganizations);

  const [selectedID, setSelectedId] = useState(0);

  // for pagination
  const [companiesData, setCompaniesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // page
  const [limit, setLimit] = useState(10); // max data to fetch

  const handleListItemClick = (event, _id, organization) => {
    window.location.pathname !== "/esg_gpt/company_profiles" &&
      history.push("/esg_gpt/company_profiles");
    acknowledged && dispatch(setAcknowledged(false));
    setSelectedId(_id);
    dispatch(setNewCompanyDetails(organization));
  };

  const loadMoreCompanies = () => {
    const nextPage = currentPage + 10;
    dispatch(viewOrganizationEsgGpt(login.token, limit, nextPage));
    setCurrentPage(nextPage);
  };

  useEffect(() => {
    dispatch(viewOrganizationEsgGpt(login.token, limit, currentPage));

    return () => {
      dispatch(setSearchedOrg([]));
      dispatch(setSearcheText(""));
    };
  }, [login.token]);

  useEffect(() => {
    organizations?.repositories &&
      setSelectedId(organizations?.repositories[0]?._id);
    !newOrganization?._id &&
      organizations?.repositories &&
      dispatch(setNewCompanyDetails(organizations?.repositories[0]));

    if (!searchedOrganizations?.text || searchedOrganizations.text === "") {
      setCompaniesData((prevCompaniesData) => {
        // Merge the existing data with the new data
        return [...prevCompaniesData, ...(organizations?.repositories || [])];
      });
    } else {
      setCompaniesData(organizations?.repositories);
    }
  }, [organizations.repositories]);

  useEffect(() => {
    if (searchedOrganizations?.text === "") setCompaniesData([]);

    setCurrentPage(0);
  }, [searchedOrganizations?.text]);

  const handleRedirect = () => {
    history.push("/esg_gpt/company_profiles");
  };
  // const companies = () => {
  //   if (searchedOrganizations?.filtered?.length > 0)
  //     return searchedOrganizations?.filtered;
  //   else if (
  //     !searchedOrganizations.text &&
  //     searchedOrganizations?.filtered?.length === 0
  //   )
  //     return organizations?.repositories;
  //   else return null;
  // };

  // console.log("Comapnies Array: ", companiesData);
  // console.log("Actual Data: ", organizations?.repositories);

  return (
    <div className={classes.root}>
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{ height: "100%" }}
      >
        {organizations ? (
          searchedOrganizations?.text && searchedOrganizations?.text !== "" ? (
            companiesData?.map((organizations, i) => {
              return (
                <ListItem
                  key={`${organizations?._id}-searched`}
                  button
                  selected={newOrganization?._id === organizations?._id}
                  onClick={(event) =>
                    handleListItemClick(
                      event,
                      organizations?._id,
                      organizations
                    )
                  }
                  style={{ borderRadius: "8px", margin: "5px" }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          fontSize: 13,
                          fontWeight:
                            newOrganization?._id === organizations?._id
                              ? 500
                              : 400,
                        }}
                      >
                        {organizations?.name}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  height: "90%",
                  padding: "8px 12px 8px 0px",
                  overflow: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                {(!searchedOrganizations.text ||
                  searchedOrganizations.text === "") &&
                  companiesData?.map((organizations, i) => {
                    return (
                      <ListItem
                        key={`${organizations?._id}`}
                        button
                        selected={newOrganization?._id === organizations?._id}
                        onClick={(event) =>
                          handleListItemClick(
                            event,
                            organizations?._id,
                            organizations
                          )
                        }
                        style={{ borderRadius: "8px", margin: "5px" }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              style={{
                                fontSize: 13,
                                fontWeight:
                                  newOrganization?._id === organizations?._id
                                    ? 500
                                    : 400,
                              }}
                            >
                              {organizations?.name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
              </div>
              <div style={{ height: "10%", width:"100%", textAlign:"center" }}>
                {!searchedOrganizations.text &&
                  currentPage <= organizations?.total && (
                    <CustomButton
                      button
                      onClick={loadMoreCompanies}
                      style={{
                        backgroundColor: "#EBF1F8",
                        color: "#15314E",
                        fontSize: "14px",
                        fontWeight: 500,
                        textAlign: "center",
                        // height: "40px",
                        width: "90%",
                        textTransform: "capitalize",
                      }}
                      size='small'
                    >
                      <CachedIcon style={{ fontSize: "20px", color: "#3374B9",marginRight:"10px" }} />
                      Load More
                      {/* <ListItemText primary="Load More" /> */}
                    </CustomButton>
                  )}
              </div>
            </div>
          )
        ) : (
          <Typography style={{ textAlign: "center", fontSize: 14 }}>
            No Company found.
          </Typography>
        )}
      </List>
    </div>
  );
}
