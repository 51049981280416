import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Rating from "@material-ui/lab/Rating";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextfield from "../../UI/textfield/textfield";
import brief from "../../../images/briefcaseBlue.png";
import mail from "../../../images/mail.png";
import tick from "../../../images/tick.png";
import signupCardUseStyles from "../../screen/signup/signupCardStyles";
import { useDispatch, useSelector } from "react-redux";
import { updateChatFeedBack } from "../../../redux/actions/gpt/esgGptAction";
function Feedback({
  isOpen,
  handleClose,
  onSubmit,
  additional,
  chatId,
  title,
  contentText,
  placeHolderText,
  extraText,
  hasRatingStar = false,
  handleFeedBackSubmission,
  children,
}) {
  const dispatch = useDispatch();
  const login = useSelector((state) => state?.login);
  const [details, setDetails] = useState({
    chip: "",
    description: "",
  });
  const chipsData = [
    `This isn't True`,
    `This isn't helpful`,
    // `This isn't True`,
    "Other",
  ];
  const classes = signupCardUseStyles();
  const subCard = (details) => {
    return (
      <div style={{ display: "flex", padding: "10px 10px" }}>
        <img alt="" src={tick} width="20px" height="20px" />
        <Typography className={classes.sideBarSubTitle}>
          {details || ""}
        </Typography>
      </div>
    );
  };

  const handleOnSubmit = () => {
    // console.log(login?.token, chatId, details?.description, !additional, details?.chip);
    if (hasRatingStar) {
      handleFeedBackSubmission(login?.token, details?.description);
    } else {
      const is_company_based = window.location.pathname !== "/esg_gpt/chat";
      console.log(
        login?.token,
        chatId,
        details?.description,
        !additional,
        details?.chip,is_company_based
      );
      dispatch(
        updateChatFeedBack(
          login?.token,
          chatId,
          details?.description,
          !additional,
          details?.chip,
          is_company_based
        )
      );
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <div style={{ width: "760px" }}>
        <DialogTitle style={{ backgroundColor: "#EBF1F8", padding: 10 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: additional && "5px 5px 25px 5px",
            }}
          >
            <Typography style={{ padding: 5, fontSize: 18, fontWeight: 600 }}>
              {title}
            </Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          {additional && (
            <Typography
              style={{
                padding: 5,
                fontSize: 12,
                fontWeight: 400,
                color: "#5C90C7",
              }}
            >
              {contentText}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between">
            <div item style={{ width: "100%" }}>
              {additional && (
                <>
                  <div>
                    <Typography
                      style={{ padding: 5, fontSize: 16, fontWeight: 400 }}
                    >
                      {extraText}
                    </Typography>
                  </div>
                  <div
                    style={{
                      margin: "10px 0px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {!hasRatingStar &&
                      chipsData.map((data) => (
                        <Chip
                          style={{
                            // padding: "5px 8px",
                            // fontSize: 13,
                            // fontWeight: 400,
                            border: "none",
                            // color: "#676767",
                            // backgroundColor: "#E9E9E9",
                            // borderRadius: "12px",
                            // width: "fit-content",
                            margin: "0px 5px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            setDetails((prev) => ({
                              ...prev,
                              chip: data,
                            }));
                          }}
                          color={details?.chip === data ? "primary" : "default"}
                          label={data}
                        />
                      ))}
                    {hasRatingStar && children && <>{children}</>}
                  </div>
                </>
              )}
              <div>
                {/* <Typography className={classes.labelText}>
                  Organization Name
                </Typography> */}
                <CustomTextfield
                  multiline={true}
                  type="text"
                  name="orgName"
                  variant="outlined"
                  size="small"
                  placeholder={placeHolderText}
                  fullWidth
                  rows={4}
                  value={details.description}
                  onChange={(e) =>
                    setDetails((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className={classes.costumTextField}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment
                  //       name="passSvg"
                  //       position="end"
                  //       className={classes.inputStyle}
                  //     >
                  //       <img alt="img" src={brief} />
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </div>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
            }}
            style={{
              textTransform: "none",
              backgroundColor: "#FFFF",
              color: "#5C90C7",
              padding: "2px 18px",
            }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={() => {
              handleOnSubmit();
              handleClose();
            }}
            style={{
              textTransform: "none",
              backgroundColor: "#5C90C7",
              color: "#FFFF",
              padding: "2px 20px",
            }}
          >
            Submit Feedback
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default Feedback;
