import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import EsgGptDrawerTabs from "./EsgGptDrawerTabs";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";

import company from "../../../images/ImpactGrows.png";
import helpCenterIcon from "../../../images/help-circle.svg";
import settingSvg from "../../../images/settingButton.svg";
import Group from "../../../images/Group.svg";
import avatar from "../../../images/Avatar.svg";
import HelpCenterDrawer from "./esgHelpCenterDrawer/HelpCenterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { setHelpCenterDrawer } from "../../../redux/actions/gpt/esgGptAction";
import {
  logOut,
  setChatTokens,
} from "../../../redux/actions/login/loginAction";
import UserProfileCard from "../userProfileCard/userProfileCard";

const useStylesLayput = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "88vh",
    overflow: "hidden",
    // border:"solid",

    // flexDirection:"column"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#EBF1F8",
    borderRadius: "0px 0px 8px 8px",
    boxShadow: "0px 1px 3px 1px rgba(51, 116, 185, 0.15)",
    height: "65px",
  },

  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    // overflow:"hidden",
    paddingRight: theme.spacing(1),
    // border:"solid",
    // marginRight: "10px",
  },

  chip: {
    backgroundColor: "#bd1010",
    width: "80px",
    height: "40px",
    display: "flex",
    justifyContent: "space-evenly",
    borderRadius: "900px",
    "&.MuiChip-colorPrimary": {
      backgroundColor: "#FEFEFE",
      color: "#15314E",
      fontSize: "18px",
      fontWeight: 500,
    },
    "&.MuiChip-root .MuiChip-avatarColorPrimary": {
      backgroundColor: "#FEFEFE",
      color: "#FEFEFE",
    },
  },
  detailChip: {
    backgroundColor: "#bd1010",
    width: "110px",
    height: "40px",
    display: "flex",
    justifyContent: "space-evenly",
    "&.MuiChip-colorPrimary": {
      backgroundColor: "#FEFEFE",
      color: "#15314E",
      fontSize: "18px",
      fontWeight: 500,
    },
    "&.MuiChip-root .MuiChip-avatarColorPrimary": {
      backgroundColor: "#FEFEFE",
      color: "#FEFEFE",
    },
  },
}));

function EsgGptAppBar() {
  const classes = useStylesLayput();
  const { helpCenter } = useSelector((state) => state?.esgGptReducer);
  const { chat_tokens } = useSelector((state) => state?.login);
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [tokenDetail, setTokenDetail] = useState(false);
  useEffect(() => {
    // dispatch(setChatTokens(localStorage.getItem("chat_tokens")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // drawer states
  // const [helpCenter, setHelpCenter] = useState(false);

  const toggleHelpCenter = () => {
    dispatch(setHelpCenterDrawer());
  };

  const handleMenuUser = (event) => {
    if (event?.currentTarget !== anchorElUser) {
      setAnchorElUser(event?.currentTarget);
    } else {
      setAnchorElUser(null);
    }
  };

  const handleUserLogOut = (token, value) => {
    dispatch(logOut(token, value));
  };

  const TokenDetails = ({ onClose, open, heading, content }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
              Tokens
            </Typography>
            <CloseIcon onClick={onClose} cursor="pointer" />
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Group} alt="" />
            <Typography
              style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
            >
              50
            </Typography>
            <Typography
              style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
            >
              {`=`}
            </Typography>
            <Typography
              style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
            >
              50 Questions
            </Typography>
          </div>
          <Typography
            style={{ fontSize: 13, marginTop: 25, paddingBottom: 25 }}
          >
            Each token serves as a question, allowing you to inquire about
            companies' ESG practices or any other information with our chatbot.
          </Typography>
        </DialogContent>
      </Dialog>
    );
  };
  const is_premium = JSON.parse(localStorage.getItem("is_premium"));
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <TokenDetails
        open={tokenDetail}
        onClose={() => setTokenDetail(!tokenDetail)}
      />
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box style={{ width: "200px", alignSelf: "flex-end" }}>
          <EsgGptDrawerTabs />
        </Box>
        <Box
          style={{
            height: "80%",
            alignSelf: "center",
            // marginRight: "auto",
            // marginLeft: "auto",
          }}
        >
          <img src={company} alt="companyLogo" height={"100%"} />
        </Box>
        <Box
          style={{
            // height: "30%",
            alignSelf: "right",
            // marginRight: "auto",
            // marginLeft: "auto",
            display: "flex",
            justifyContent: !is_premium ? "space-evenly" : "right",
            alignItems: "center",
            width: "250px",
          }}
        >
          {/* <IconButton
            data-tut="help"
            aria-label="help_center"
            onClick={toggleHelpCenter}
          >
            <img
              src={helpCenterIcon}
              alt="companyLogo"
              height={"100%"}
              style={{ cursor: "pointer" }}
            />
          </IconButton> */}
          {/* <img
            src={settingSvg}
            alt="companyLogo"
            height={"100%"}
            style={{ cursor: "pointer" }}
            data-tut="setting"
          /> */}
          {!is_premium && (
            <Chip
              className={classes.chip}
              data-tut="token"
              color="primary"
              label={chat_tokens || 0}
              avatar={<Avatar src={Group} />}
              onClick={() => setTokenDetail(!tokenDetail)}
            />
          )}
          <Avatar
            style={{ cursor: "pointer" }}
            src={avatar}
            data-tut="user-profile"
            onClick={(e) => handleMenuUser(e)}
          />
        </Box>
      </Toolbar>

      {/* Help Center Drawer */}
      {helpCenter && (
        <HelpCenterDrawer open={helpCenter} onClose={toggleHelpCenter} />
      )}
      {/* logOut / user profile */}
      {anchorElUser && (
        <UserProfileCard
          anchorEl={anchorElUser}
          onClose={handleMenuUser}
          logOut={handleUserLogOut}
        />
      )}
    </AppBar>
  );
}

export default EsgGptAppBar;
