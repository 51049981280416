import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  makeStyles,
  AppBar,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

// components imports
import QuestionsTab from "./Elements/MainTabs/QuestionsTab";
import ResponsesTab from "./Elements/MainTabs/ResponsesTab";
import { getSurveySectionQuestion, setQuestions } from "../../../../redux/actions/riskdashboard/riskDashboardActions";

// styling
const useStyles = makeStyles(() => ({
  AppBar: {
    boxShadow: "none",
    // marginBottom: "20px",
    // marginTop: "auto",
    // border: "solid",
    width: "85%",
    height:"100%"
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  activeTabLabel: {
    color: "#154328",
  },

  // Save Button
  saveButton: {
    position: "absolute",
    bottom: "20px",
    right: "50px",
    textTransform: "none",
    width: "200px",
    height: "36px",
    color: "#fff",
    borderRadius: "8px",
    padding: "8px 16px 8px 16px",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374B9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
}));

// ----------------- Main Render -----------------
const RiskSurveyInMain = () => {
  const dispatch = useDispatch();
  const login = useSelector(state => state.login)
  // using styles
  const classes = useStyles();

  // State to track the active tab
  const [activeTab, setActiveTab] = useState(0);

  // Component mapping based on the selected tab
  const tabComponents = [
    // <BasicDetailsTab />,
    <QuestionsTab />,
    <ResponsesTab />,
    // <SettingsTab />,
  ];

  

  useEffect(() => {
    
    dispatch(getSurveySectionQuestion(login?.token)) 
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setQuestions([]));
      dispatch({ type: "HAS_MORE_DATA", payload: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return (
    // Main Container
    <Box>
      {/* Tabs Section */}
      <Box
        style={{
          // border: "solid red",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom:"50px"
          // height:"60px"
        }}
      >
        <Typography
          // onClick={toggleDialog}
          style={{
            fontWeight: 600,
            fontSize: "22px",
            color: "#15314E",
            cursor: "pointer",
            width: "15%",
          }}
        >
          Survey Section
        </Typography>
          <MuiThemeProvider>
            <AppBar
              position="static"
              color="inherit"
              className={classes.AppBar}
            >
              <Tabs
                value={activeTab}
                onChange={(e, newVal) => setActiveTab(newVal)}
                style={{
                  // marginLeft: "auto",
                  // marginRight: "auto",
                  // width: "100%",
                  // border: "solid yellow",
                  alignSelf:"center"
                }}
              >
                {/* <Tab
                  disableRipple
                  label={
                    <span
                      className={`${classes.tabLabel} ${
                        activeTab === 0 ? classes.activeTabLabel : ""
                      }`}
                    >
                      Basic Details
                    </span>
                  }
                /> */}
                <Tab
                  disableRipple
                  label={
                    <span
                      className={`${classes.tabLabel} ${
                        activeTab === 0 ? classes.activeTabLabel : ""
                      }`}
                    >
                      Questions
                    </span>
                  }
                />
                <Tab
                  disableRipple
                  label={
                    <span
                      className={`${classes.tabLabel} ${
                        activeTab === 1 ? classes.activeTabLabel : ""
                      }`}
                    >
                      Responses
                    </span>
                  }
                />
                {/* <Tab
                  disableRipple
                  label={
                    <span
                      className={`${classes.tabLabel} ${
                        activeTab === 3 ? classes.activeTabLabel : ""
                      }`}
                    >
                      Settings
                    </span>
                  }
                /> */}
              </Tabs>
            </AppBar>
          </MuiThemeProvider>
      </Box>

      {/* Active Component */}
      {tabComponents[activeTab]}

      {/* Save Changes Button */}
      {/* <Button
        variant="contained"
        color="primary"
        className={classes.saveButton}
        onClick={handleSaveChanges}
      >
        Save Changes
      </Button> */}
    </Box>
  );
};

export default RiskSurveyInMain;
