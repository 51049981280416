import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { Box, Typography, Grid, CircularProgress } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PublishIcon from "@material-ui/icons/Publish";
import InputAdornment from "@material-ui/core/InputAdornment";

import CustomButton from "../../../../../UI/button/button";
import CustomTextfield from "../../../../../UI/textfield/textfield";
import upload from "../../../../../../images/uploadGreen.png";
import download from "../../../../../../images/downloadGreenNew.png";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
// import AccessTimeIcon from '@material-ui/icons/AccessTime';

import SectionList, { useStyles } from "./QuestionTabComponents/SectionList";

import {
  questionTypes,
  subQuestionTypes,
} from "./QuestionTabComponents/QuestionData";
import {
  setQuestions,
  setSelectedSection,
} from "../../../../../../redux/actions/riskdashboard/riskDashboardActions";
import QuestionTabDrawer from "./QuestionTabComponents/QuestionTabDrawer";
import {
  getVersionHistory,
  publishSurvey,
} from "../../../../../../redux/actions/corporateSurvey/corporateSurveyActions";
import QuestionLayout from "./QuestionTabComponents/QuestionLayout";
// import OptimizeInitialRender from "../../../../riskAssessment/brmDataRight.js/OptimizeInitialRender";
import useInfiniteScroll from "../../../../../../customHooks/useInfiniteScroll";

const QuestionListHeader = ({ selectedSection }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <Typography
        style={{
          color: "#15314E",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        {selectedSection}
      </Typography>
      {selectedSection !== "Self Assessment Section" &&
        selectedSection !== "Controversy Survey" && (
          <Box>
            <CustomButton
              style={{
                textTransform: "none",
                marginRight: 10,
                borderRadius: "8px",
              }}
              size="small"
              color="secondary"
              variant="outlined"
            >
              <img
                src={upload}
                style={{ height: "20px", marginRight: "5px" }}
                alt="upload"
              />
              <Typography>Upload Sheet</Typography>
            </CustomButton>
            <CustomButton
              style={{
                textTransform: "none",
                marginRight: 10,
                borderRadius: "8px",
              }}
              size="small"
              color="secondary"
              variant="outlined"
            >
              <img
                src={download}
                style={{ height: "20px", marginRight: "5px" }}
                alt="upload"
              />

              <Typography>Download Sheet</Typography>
            </CustomButton>
          </Box>
        )}
    </Box>
  );
};

const QuestionsTab = () => {
  const classes = useStyles();
  const {
    sectionWiseData,
    sectionWiseQuestion,
    selectedSection,
    versionHistory,
  } = useSelector((state) => state.riskDashboard);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const scrollDivRef = useRef();
  const { loading, resetHookState } = useInfiniteScroll(scrollDivRef);
  // console.log(loading);
  // const [selectedSection, setSelectedSection] = React.useState(
  //   "Self Assessment Section"
  // );

  const [questionOption, setQuestionOption] = React.useState(questionTypes);

  const [menuOpen, setMenuOpen] = React.useState(false);

  const [OpenDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawer = (value) => {
    setOpenDrawer(value);
  };

  const handleCloseSelect = () => {
    setMenuOpen(false);
    setTimeout(() => {
      setQuestionOption(questionTypes);
    }, 500);
  };

  const handleOpenSelect = () => {
    setMenuOpen(true);
  };

  const handleSelectQuestionType = React.useCallback((value) => {
    // setQuestionType(value?.primary);
    handleCloseSelect();
  }, []);

  const handleSectionSelection = React.useCallback((value) => {
    // scrollDivRef.current.resetState()
    resetHookState();
    dispatch(setSelectedSection(value));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMoreListOption = React.useCallback((value) => {
    setQuestionOption(subQuestionTypes[value]);
  }, []);

  useEffect(() => {
    // selectedSection !== "section" &&
    //   sectionWiseData.length > 0 &&
    //   dispatch(
    //     getQuestion(
    //       login?.token,
    //       sectionWiseData.find((s) => s.title === selectedSection)?._id,
    //       sectionWiseData.find((s) => s.title === selectedSection)?.version
    //     )
    //   );

    selectedSection === "section" &&
      dispatch(
        setQuestions([
          {
            _id: "temporary",
            hasNewlyAdded: true,
            question_type: "Multichoice",
          },
        ])
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection, sectionWiseData.length]);

  const handleChangeSectionQuestion = (id, data) => {
    dispatch(
      setQuestions([
        ...sectionWiseQuestion,
        { _id: "temporary", hasNewlyAdded: true, question_type: "Multichoice" },
      ])
    );
  };

  useEffect(() => {
    return () => {
      resetHookState();
      dispatch(setSelectedSection("Self Assessment Section"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {/* Question List Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#15314E",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Questions list
        </Typography>
        <CustomButton
          style={{
            textTransform: "none",

            // width: "200px",
            marginLeft: "auto",
            borderRadius: "8px",
            marginRight: "10px",
          }}
          size="small"
          color="primary"
          variant="text"
          onClick={() => {
            dispatch(getVersionHistory(login?.token));
            handleDrawer(true);
          }}
        >
          <AccessTimeIcon
            style={{ color: "#5C90C7", fontSize: "18px", marginRight: "5px" }}
          />

          <Typography>Version History</Typography>
        </CustomButton>
        <CustomButton
          style={{
            textTransform: "none",

            // width: "200px",
            borderRadius: "8px",
          }}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => {
            dispatch(
              publishSurvey(
                login?.token,
                sectionWiseData.find((s) => s.title === selectedSection)
                  ?.version
              )
            );
            // handleDrawer(true);
          }}
        >
          <PublishIcon style={{ fontSize: "18px", marginRight: "5px" }} />

          <Typography>Publish</Typography>
        </CustomButton>
      </Box>
      {/* question list content */}
      <Grid
        container
        style={{ height: "70vh", marginTop: "10px" }}
        justifyContent="space-between"
      >
        <Grid item md={2}>
          <SectionList handleSectionSelection={handleSectionSelection} />
        </Grid>
        <Grid
          item
          md={10}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "scroll",
          }}
          ref={scrollDivRef}
        >
          <QuestionListHeader selectedSection={selectedSection} />

          {
            sectionWiseQuestion.length > 0 && (
              // <OptimizeInitialRender chunkSize={1}>
              <>
                {sectionWiseQuestion.map(
                  (
                    {
                      _id,
                      question,
                      question_type,
                      weightage,
                      hasNewlyAdded,
                      answers,
                    },
                    i,
                    arr
                  ) => (
                    <QuestionLayout
                      key={_id}
                      sectionWiseQuestion={sectionWiseQuestion}
                      sectionWiseData={sectionWiseData}
                      questionText={question}
                      questionType={question_type}
                      handleCloseSelect={handleCloseSelect}
                      handleOpenSelect={handleOpenSelect}
                      menuOpen={menuOpen}
                      questionOption={questionOption}
                      handleMoreListOption={handleMoreListOption}
                      handleSelectQuestionType={handleSelectQuestionType}
                      weightage={weightage}
                      id={_id}
                      hasNewlyAdded={hasNewlyAdded}
                      questionData={arr[i]}
                      handleChangeSectionQuestion={handleChangeSectionQuestion}
                      selectedSection={selectedSection}
                      sectionId={
                        sectionWiseData.find((s) => s.title === selectedSection)
                          ?._id
                      }
                      defaultSection={
                        sectionWiseData.find((s) => s.title === selectedSection)
                          ?.default
                      }
                      questionLength={arr.length}
                      latestVersion={
                        sectionWiseData.find((s) => s.title === selectedSection)
                          ?.version
                      }
                    />
                  )
                )}
                {loading && <CircularProgress style={{ margin: "auto" }} />}
              </>
            )

            // </OptimizeInitialRender>
          }

          {sectionWiseQuestion.length === 0 && (
            <CircularProgress style={{ margin: "auto" }} />
          )}

          {sectionWiseData.find((s) => s.title === selectedSection)?.default ===
            false && (
            <CustomButton
              style={{
                textTransform: "none",
                marginRight: 10,
                // width: "20%",
                alignSelf: "center",
                borderRadius: "8px",
              }}
              size="small"
              color="secondary"
              variant="outlined"
              // disabled={selectedSection === "section" ? true : false}
              onClick={() => handleChangeSectionQuestion()}
            >
              <AddIcon />

              <Typography>add Question</Typography>
            </CustomButton>
          )}
        </Grid>
      </Grid>
      {OpenDrawer && (
        <QuestionTabDrawer
          headerName={"Versions"}
          buttonText={"Select Version"}
          open={OpenDrawer}
          handleClose={handleDrawer}
        >
          <Box style={{ width: "100%" }}>
            <CustomTextfield
              name="Search"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="All Versions"
              onChange={(e) => {}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: "#3374B9" }} />
                  </InputAdornment>
                ),
              }}
              //   value={}
            />
            <Box
              classes={{
                root: classes.root, // class name, e.g. `classes-nesting-root-x`
              }}
              style={{ width: "100%", marginTop: "10px" }}
            >
              {versionHistory.map((v) => (
                <Box
                  key={v?.added_on}
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  style={{
                    border: "1px solid #E0E0E0",
                    width: "100%",
                    // height: "100px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <AccessTimeIcon style={{ color: "#5C90C7" }} />
                    <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                      {moment(v?.added_on).format("DD MMMM YY")}
                    </Typography>
                    <CustomButton
                      style={{
                        textTransform: "none",
                        width: "50px",
                        borderRadius: "8px",
                        marginLeft: "auto",
                      }}
                      size="small"
                      color="primary"
                      variant="text"
                    >
                      <Typography>View</Typography>
                    </CustomButton>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FiberManualRecordIcon
                      style={{ color: "#32A05F", fontSize: "12px" }}
                    />
                    <Typography>{v?.user_name}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </QuestionTabDrawer>
      )}
    </Box>
  );
};
export default QuestionsTab;
