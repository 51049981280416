import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import UNIVERSAL from "../../../config/config";

function ReCaptcha({onChange}) {
  console.log(UNIVERSAL)
  return (
    <div>
      <ReCAPTCHA sitekey={UNIVERSAL.CAPTCHA_SITE_KEY} onChange={onChange} />
    </div>
  );
}

export default ReCaptcha;