import React from "react";
import {
  Box,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";

// icons
import SearchIcon from "@material-ui/icons/Search";

// redux imports
import { useDispatch } from "react-redux";
import {
  setResponseCompany,
  setSubmittedQuestions,
} from "../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";

// custom imports
import CustomTextfield from "../../../../../../../UI/textfield/textfield";

const DrawerLeft = ({ selectedResCompany, surveyRespondedUsers }) => {
  // from redux
  const dispatch = useDispatch();

  const handleCompanyClick = (company) => {
    dispatch(setSubmittedQuestions([], 0, false));

    dispatch(setResponseCompany(company));
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "277px",
      }}
    >
      {/* Search Component */}
      <CustomTextfield
        name="Search"
        variant="outlined"
        fullWidth
        size="small"
        type="text"
        label="Search"
        onChange={(e) => {}}
        style={{ color: "#EBF1F8" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{ color: "#3374B9" }} />
            </InputAdornment>
          ),
        }}
      />

      {/* Sub Companies Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "16px",
          maxHeight: "40em",
          overflowY: "scroll",
        }}
      >
        {surveyRespondedUsers?.map((company) => (
          <Typography
            key={company._id}
            onClick={() => handleCompanyClick(company, company._id)}
            style={{
              width: "261px",
              height: "52px",
              border: "0px 0px 1px 0px",
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "8px",
              cursor: "pointer",
              backgroundColor:
                company._id === selectedResCompany?._id
                  ? "#EBF1F8"
                  : "transparent",
              fontWeight:
                company._id === selectedResCompany?._id ? "600" : "500",
              fontSize: "14px",
              lineHeight: "20px",
              color:
                company._id === selectedResCompany?._id ? "#15314E" : "#6C6C6C",
            }}
          >
            {company.name}
          </Typography>
        ))}
        {surveyRespondedUsers.length === 0 && <CircularProgress />}
      </Box>
    </Box>
  );
};
export default DrawerLeft;
