import UNIVERSAL from "../../../config";
import {
  SET_CALCULATED_SENTIMENT_ARTICLE,
  SET_CALCULATED_SENTIMENT_BAR_GRAPH,
  SET_CALCULATED_SURVEY,
  SET_CALCULATED_SENTIMENT_TOPIC_WISE,
  SET_SENTIMENT_SOCRE_DATA,
  SET_COMPANY_INSIGHTS,
  SET_SCORES,
  SET_CONTROVERSY_DATA,
  SET_PEER_SCORES,
  SET_PUBLIC_REPORTS,
  SET_PEERS,
  SET_SELF_ASSESSMENT_SOCRE,
  SET_RISK_RATING_DATA,
} from "../../../constants/riskEvaluatorConstants";

import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";
export const setCalculatedSurvey = (payload) => {
  return {
    type: SET_CALCULATED_SURVEY,
    payload,
  };
};
export const setNewArticles = (payload, total) => {
  return {
    type: SET_CALCULATED_SENTIMENT_ARTICLE,
    payload,
    total,
  };
};

export const setSelfAssessmentScore = (payload, total) => {
  return {
    type: SET_SELF_ASSESSMENT_SOCRE,
    payload,
    total,
  };
};

export const setBarChartData = (payload) => {
  return {
    type: SET_CALCULATED_SENTIMENT_BAR_GRAPH,
    payload,
  };
};
export const setRiskRatingData = (payload) => {
  return {
    type: SET_RISK_RATING_DATA,
    payload,
  };
};
export const setTopicWiseData = (payload) => {
  return {
    type: SET_CALCULATED_SENTIMENT_TOPIC_WISE,
    payload,
  };
};
export const setPublicReports = (payload) => {
  return {
    type: SET_PUBLIC_REPORTS,
    payload,
  };
};
export const getCalculatedSurvey = (token, organization_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      organization_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/questions/get_calculated_survey", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCalculatedSurvey(responseJson.result));
        }
        dispatch(unset_loader());
        // dispatch(unset_loader);
      });
  };
};
export const getSelfAssessmentScors = (token, organization_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      organization_id,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/organization/get_self_assessment_score ",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSelfAssessmentScore(responseJson.result));
        }
        dispatch(unset_loader());
        // dispatch(unset_loader);
      });
  };
};
export const getNewArticles = (token, isin, limit, skip, filter) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      isin,
      limit,
      skip,
      filter: filter || "None",
    });
    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/get_articles", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setNewArticles(responseJson.result, responseJson.total));
          // dispatch(setTotalNewArticles(responseJson.total));
        } else {
          dispatch(setNewArticles([]));
        }
        dispatch(unset_loader());
        // dispatch(
        //   setNewArticles([
        //     {
        //       organisation: "bajaj finance ltd",
        //       source: "boursakuwait.com",
        //       url: "https://www.boursakuwait.com.kw/ar/news/view#65266",
        //       title:
        //         "Good news for FD investors Bajaj Finance offers profitable FD rates up to 7.45 per cent p.a. to grow your money",
        //       state: "neural",
        //       date: "2022-05-31T00:00:00.000Z",
        //     },
        //     {
        //       organisation: "hindustan unilever ltd",
        //       source: "proactiveinvestors.co.uk",
        //       url: "https://www.proactiveinvestors.co.uk/companies/news/983644/ftse-100-hits-highest-point-since-2019-everywhere-else-in-the-red-983644.html",
        //       title:
        //         "FTSE 100 hits highest point since 2019, everywhere else in the red",
        //       state: "negative",
        //       date: "2022-05-31T00:00:00.000Z",
        //     },
        //     {
        //       organisation: "bajaj finance ltd",
        //       source: "newzealandstar.com",
        //       url: "https://www.newzealandstar.com/news/272570926/good--for-fd-investors-bajaj-finance-offers-profitable-fd-rates-up-to-745-per-cent-pa-to-grow-your-money",
        //       title:
        //         "Good news for FD investors Bajaj Finance offers profitable FD rates up to 7.45 per cent p.a. to grow your money",
        //       state: "neural",
        //       date: "2022-05-31T00:00:00.000Z",
        //     },
        //     {
        //       organisation: "hindustan unilever ltd",
        //       source: "msn.com",
        //       url: "https://www.msn.com/en-gb/money/other/uk-credit-card-borrowing-soars-by-most-since-2005-as-mortgage-approvals-slide-as-it-happened/ar-AAXUxRI",
        //       title:
        //         "UK credit card borrowing soars by most since 2005 as mortgage approvals slide &#x2013; as it happened",
        //       state: "negative",
        //       date: "2022-05-31T00:00:00.000Z",
        //     },
        //     {
        //       organisation: "hindustan unilever ltd",
        //       source: "thegrocer.co.uk",
        //       url: "https://www.thegrocer.co.uk/mergers-and-acquisitions/unilever-snaps-up-hair-loss-supplements-brand-nutrafol/668039.article",
        //       title: "Unilever snaps up hair loss supplements brand Nutrafol",
        //       state: "positive",
        //       date: "2022-05-31T00:00:00.000Z",
        //     },
        //   ])
        // );
      })
      .catch((error) => {
        dispatch(unset_loader());
        dispatch(
          setNewArticles([
            {
              organisation: "bajaj finance ltd",
              source: "boursakuwait.com",
              url: "https://www.boursakuwait.com.kw/ar/news/view#65266",
              title:
                "Good news for FD investors Bajaj Finance offers profitable FD rates up to 7.45 per cent p.a. to grow your money",
              state: "neural",
              date: "2022-05-31T00:00:00.000Z",
            },
            {
              organisation: "hindustan unilever ltd",
              source: "proactiveinvestors.co.uk",
              url: "https://www.proactiveinvestors.co.uk/companies/news/983644/ftse-100-hits-highest-point-since-2019-everywhere-else-in-the-red-983644.html",
              title:
                "FTSE 100 hits highest point since 2019, everywhere else in the red",
              state: "negative",
              date: "2022-05-31T00:00:00.000Z",
            },
            {
              organisation: "bajaj finance ltd",
              source: "newzealandstar.com",
              url: "https://www.newzealandstar.com/news/272570926/good--for-fd-investors-bajaj-finance-offers-profitable-fd-rates-up-to-745-per-cent-pa-to-grow-your-money",
              title:
                "Good news for FD investors Bajaj Finance offers profitable FD rates up to 7.45 per cent p.a. to grow your money",
              state: "neural",
              date: "2022-05-31T00:00:00.000Z",
            },
            {
              organisation: "hindustan unilever ltd",
              source: "msn.com",
              url: "https://www.msn.com/en-gb/money/other/uk-credit-card-borrowing-soars-by-most-since-2005-as-mortgage-approvals-slide-as-it-happened/ar-AAXUxRI",
              title:
                "UK credit card borrowing soars by most since 2005 as mortgage approvals slide &#x2013; as it happened",
              state: "negative",
              date: "2022-05-31T00:00:00.000Z",
            },
            {
              organisation: "hindustan unilever ltd",
              source: "thegrocer.co.uk",
              url: "https://www.thegrocer.co.uk/mergers-and-acquisitions/unilever-snaps-up-hair-loss-supplements-brand-nutrafol/668039.article",
              title: "Unilever snaps up hair loss supplements brand Nutrafol",
              state: "positive",
              date: "2022-05-31T00:00:00.000Z",
            },
          ])
        );
        console.log(error);
      });
  };
};
const setSentimentScoreData = (payload) => ({
  type: SET_SENTIMENT_SOCRE_DATA,
  payload,
});

export const setCompanyInsights = (payload) => ({
  type: SET_COMPANY_INSIGHTS,
  payload,
});
export const setScores = (payload) => ({
  type: SET_SCORES,
  payload,
});
export const setPeerScores = (payload) => ({
  type: SET_PEER_SCORES,
  payload,
});
export const setControversyData = (payload) => ({
  type: SET_CONTROVERSY_DATA,
  payload,
});
export const setPeers = (payload) => ({
  type: SET_PEERS,
  payload,
});

export const getScores = (token, isin, organization_id) => {
  return (dispatch) => {
    //  "INE009A01021"
    const data = encrypt({
      "user-token": token,
      isin: isin,
      organization_id: organization_id || "",
    });
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/get_company_risk_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        if (responseJson.status) {
          dispatch(
            setScores(
              responseJson?.result?.[0] || {
                scoreObject: {},
              }
            )
          );
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const getPeerScores = (token, isin, industry_code, year) => {
  return (dispatch) => {
    //  "INE009A01021"

    const data = encrypt({
      "user-token": token,
      isin: isin || "INE009A01021",
      industry_code: industry_code || "TC-SI",
      year,
    });
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/get_peer_esg_scores", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setPeerScores(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getControversyData = (token, isin) => {
  return (dispatch) => {
    console.log({
      "user-token": token,
      isin: isin || "INE009A01021",
    });
    const data = encrypt({
      "user-token": token,
      isin: isin || "INE009A01021",
    });
    dispatch(set_loader());
    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/get_company_controversy_details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setControversyData(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getPeers = (token, organization_id) => {
  console.log(organization_id);
  // api/organization/get_peer_organizations - user-token, organization_id

  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organization_id,
    });

    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/organization/get_peer_organizations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setPeers(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.log(error);
      });
  };
};

export const getCompanyInsights = (token, isin, year) => {
  // console.log('year',year,isin)
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      isin: isin || "INE009A01021",
      year,
    });

    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/get_company_insights", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCompanyInsights(responseJson?.result || []));
        } else {
          dispatch(setCompanyInsights([]));
          dispatch(setSentimentScoreData(""));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getSentimentCalculatedData = (organization_name) => {
  return (dispatch) => {
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/elastic_search/get_calculated_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ organization_name: organization_name }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          dispatch(setSentimentScoreData(responseJson));
          // console.log("getSentimentCalculatedData", responseJson);
        } else {
          dispatch(setSentimentScoreData(""));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const getBarChartData = () => {
  return (dispatch) => {
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/elastic_search/get_bar_chart_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("respgetNewArticlesonse", responseJson);
        // if (responseJson) {
        //   dispatch(setBarChartData(responseJson));
        // }
        dispatch(
          setBarChartData({
            labels: [
              "Sokouk Holding",
              "Kuwait Real Estate Co KSC",
              "Unites Real Estate K.S.C",
              "Salhia Real Estate",
              "Ajial Real Estate",
            ],
            datasets: [
              {
                label: "Management",
                data: [100, 100, 100, 99.24242401123047, 99.24466323852539],
              },
              {
                label: "Workforce",
                data: [
                  100, 100, 99.95888137817383, 99.30555534362793,
                  98.7164945602417,
                ],
              },
              {
                label: "Community",
                data: [100, 100, 100, 99.32659912109375, 98.75814247131348],
              },
              {
                label: "Product responsibility",
                data: [
                  100, 100, 99.9503968556722, 99.24242401123047,
                  98.76666069030762,
                ],
              },
              {
                label: "Human rights",
                data: [100, 100, 100, 99.24242401123047, 98.89255142211914],
              },
              {
                label: "Resource use",
                data: [100, 100, 100, 99.24242401123047, 98.48932647705078],
              },
              {
                label: "Shareholders",
                data: [100, 100, 100, 99.24242401123047, 98.48932647705078],
              },
            ],
          })
        );
        dispatch(unset_loader());
      })
      .catch((error) => {
        dispatch(
          setBarChartData({
            labels: [
              "Sokouk Holding",
              "Kuwait Real Estate Co KSC",
              "Unites Real Estate K.S.C",
              "Salhia Real Estate",
              "Ajial Real Estate",
            ],
            datasets: [
              {
                label: "Management",
                data: [100, 100, 100, 99.24242401123047, 99.24466323852539],
              },
              {
                label: "Workforce",
                data: [
                  100, 100, 99.95888137817383, 99.30555534362793,
                  98.7164945602417,
                ],
              },
              {
                label: "Community",
                data: [100, 100, 100, 99.32659912109375, 98.75814247131348],
              },
              {
                label: "Product responsibility",
                data: [
                  100, 100, 99.9503968556722, 99.24242401123047,
                  98.76666069030762,
                ],
              },
              {
                label: "Human rights",
                data: [100, 100, 100, 99.24242401123047, 98.89255142211914],
              },
              {
                label: "Resource use",
                data: [100, 100, 100, 99.24242401123047, 98.48932647705078],
              },
              {
                label: "Shareholders",
                data: [100, 100, 100, 99.24242401123047, 98.48932647705078],
              },
            ],
          })
        );
        dispatch(unset_loader());
        console.log(error);
      });
  };
};

export const getTopicWiseData = () => {
  return (dispatch) => {
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/elastic_search/get_topic_wise_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("respgetNewArticlesonse", responseJson);
        // if (responseJson) {
        //   dispatch(setTopicWiseData(responseJson));
        // }
        dispatch(unset_loader);
        dispatch(
          setTopicWiseData({
            org_names: [
              "Sokouk Holding",
              "Kuwait Real Estate Co KSC",
              "Unites Real Estate K.S.C",
              "Salhia Real Estate",
              "Ajial Real Estate",
            ],
            topic_names: [
              "Accounting controversies count",
              "Anti-competition controversy",
              "Business ethics  controversies",
              "Child labour controversies",
              "Consumer controversies",
              "Critical countries controversies",
              "Customer health and safety controversies",
              "Diversity and opportunity controversies",
              "Employee health and safety controversies",
              "Environmental controversies",
              "Human rights controversies",
              "Insider dealings controversies",
              "Intellectual property controversies",
              "Management compensation controversies count",
              "Privacy controversies",
              "Product access controversies",
              "Public health controversies",
              "Responsible R&D controversies",
              "Responsible marketing controversies",
              "Shareholder rights controversies",
              "Strikes",
              "Tax fraud controversies",
              "Wages or working conditions controversies",
            ],
            values: [
              [
                100, 100, 99.67105293273926, 98.98989868164062,
                98.18832397460938,
              ],
              [100, 100, 100, 99.49494934082031, 98.54043579101562],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.54043579101562],
              [100, 100, 100, 99.24242401123047, 98.48932647705078],
              [100, 100, 100, 99.24242401123047, 98.48932647705078],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.18832397460938],
              [100, 100, 100, 99.24242401123047, 98.48932647705078],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.48932647705078],
              [100, 100, 100, 99.24242401123047, 99.24466323852539],
              [100, 100, 100, 99.24242401123047, 99.24466323852539],
              [
                100, 100, 99.7023811340332, 99.24242401123047,
                98.89255142211914,
              ],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.54043579101562],
              [
                100, 100, 99.83552551269531, 99.24242401123047,
                98.54043579101562,
              ],
              [100, 100, 100, 99.49494934082031, 98.89255142211914],
              [100, 100, 100, 99.49494934082031, 99.24466323852539],
              [
                100, 100, 99.83552551269531, 99.24242401123047,
                98.89255142211914,
              ],
            ],
          })
        );
      })
      .catch((error) => {
        dispatch(unset_loader);
        dispatch(
          setTopicWiseData({
            org_names: [
              "Sokouk Holding",
              "Kuwait Real Estate Co KSC",
              "Unites Real Estate K.S.C",
              "Salhia Real Estate",
              "Ajial Real Estate",
            ],
            topic_names: [
              "Accounting controversies count",
              "Anti-competition controversy",
              "Business ethics  controversies",
              "Child labour controversies",
              "Consumer controversies",
              "Critical countries controversies",
              "Customer health and safety controversies",
              "Diversity and opportunity controversies",
              "Employee health and safety controversies",
              "Environmental controversies",
              "Human rights controversies",
              "Insider dealings controversies",
              "Intellectual property controversies",
              "Management compensation controversies count",
              "Privacy controversies",
              "Product access controversies",
              "Public health controversies",
              "Responsible R&D controversies",
              "Responsible marketing controversies",
              "Shareholder rights controversies",
              "Strikes",
              "Tax fraud controversies",
              "Wages or working conditions controversies",
            ],
            values: [
              [
                100, 100, 99.67105293273926, 98.98989868164062,
                98.18832397460938,
              ],
              [100, 100, 100, 99.49494934082031, 98.54043579101562],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.54043579101562],
              [100, 100, 100, 99.24242401123047, 98.48932647705078],
              [100, 100, 100, 99.24242401123047, 98.48932647705078],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.18832397460938],
              [100, 100, 100, 99.24242401123047, 98.48932647705078],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.48932647705078],
              [100, 100, 100, 99.24242401123047, 99.24466323852539],
              [100, 100, 100, 99.24242401123047, 99.24466323852539],
              [
                100, 100, 99.7023811340332, 99.24242401123047,
                98.89255142211914,
              ],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.89255142211914],
              [100, 100, 100, 99.24242401123047, 98.54043579101562],
              [
                100, 100, 99.83552551269531, 99.24242401123047,
                98.54043579101562,
              ],
              [100, 100, 100, 99.49494934082031, 98.89255142211914],
              [100, 100, 100, 99.49494934082031, 99.24466323852539],
              [
                100, 100, 99.83552551269531, 99.24242401123047,
                98.89255142211914,
              ],
            ],
          })
        );
        console.log(error);
      });
  };
};

export function submitRiskEvaluation(
  token,
  organization_id,
  evaluation_value,
  comment
) {
  const data = encrypt({
    "user-token": token,
    organization_id,
    evaluation_value,
    comment,
  });
  return (dispatch) => {
    dispatch(set_loader);
    return fetch(UNIVERSAL.BASEURL + "/organization/evaluate_risk", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(setSectorIndustries(responseJson.result));
        }
        dispatch(unset_loader);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getPublicReports(token, organization_id) {
  return (dispatch, getState) => {
    dispatch(set_loader);

    const ISIN = getState().brmData?.organizationDetails[0]?.isin;

    const data = encrypt({
      "user-token": token,
      organization_id,
      isin: ISIN,
    });

    return fetch(UNIVERSAL.BASEURL + "/organization/get_company_documents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson);
          dispatch(setPublicReports(responseJson.result));
        }
        dispatch(unset_loader);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function uploadFiles(
  token,
  isin,
  year,
  file,
  startdate,
  enddate,
  type,
  organization_id,
  source_url
) {
  // source url here
  console.log(source_url);

  var formData = new FormData();
  let data;
  type === "XBRL"
    ? (data = encrypt({
        "user-token": token,
        isin,
        year,
        startdate: startdate + "-01-01",
        enddate: enddate + "-12-31",
        type,
        organization_id,
        source_url,
        xbrl_url: file,
        application: "RISK",
      }))
    : (data = encrypt({
        "user-token": token,
        isin,
        year,
        startdate: startdate + "-01-01",
        enddate: enddate + "-12-31",
        type,
        organization_id,
        source_url,
        application: "RISK",
      }));
  console.log(isin, year, startdate, enddate, type, organization_id);
  // const data = encrypt({
  //   "user-token": token,
  //   isin: "INE860A01027",
  //   year,
  //   startdate: "2020-01-01",
  //   enddate: "2020-12-31",
  //   type,
  //   organization_id,
  // });
  if (type !== "XBRL") {
    formData.append("data", data);
    formData.append("file", file);
  }

  return (dispatch) => {
    dispatch(set_loader());

    return fetch(
      UNIVERSAL.BASEURL + "/python_apis/create_company_esg_profile",
      type === "XBRL"
        ? {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
          }
        : {
            method: "POST",
            body: formData,
          }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        dispatch(getPublicReports(token, organization_id));
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

// RISK RATING TAB

export const setRiskRating = (
  token,
  organization_id,
  rating,
  feedback_comment
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organization_id,
      rating,
      feedback_comment,
    });
    console.log(organization_id, rating, feedback_comment);
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/organization/set_esg_rating", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(setPeers(responseJson.result));
          dispatch(getRiskRating(token, organization_id));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.log(error);
      });
  };
};

export const getRiskRating = (token, organization_id) => {
  console.log(organization_id);

  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organization_id,
    });

    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/organization/get_esg_rating", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            setRiskRatingData({
              rating: responseJson?.result?.rating || "",
              comment: responseJson?.result?.feedback_comment || "",
            })
          );
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.log(error);
      });
  };
};
