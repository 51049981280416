import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getQuestion, setQuestions,
} from "../redux/actions/riskdashboard/riskDashboardActions";

const useInfiniteScroll = (scrollDivRef) => {
  const dispatch = useDispatch();
  const {
    sectionWiseData,
    selectedSection,
    sectionWiseQuestion,
    hasMoreData,
    offset,
  } = useSelector((state) => state.riskDashboard);
  const login = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  // const [offset, setOffset] = useState(0);


  useEffect(() => {
    setLoading(true);

    const element = scrollDivRef.current;

    const fetchData = async () => {
      try {
        setLoading(true);
        // if (initialSection !== selectedSection) {
        //   setInitialSection(selectedSection);
        //   setOffset(0);
        //   dispatch(setQuestions([]));
        //   dispatch({ type: "HAS_MORE_DATA", payload: true })
        // }
        if (selectedSection !== "section" && sectionWiseData.length > 0) {
          hasMoreData &&
            dispatch(
              getQuestion(
                login?.token,
                sectionWiseData.find((s) => s.title === selectedSection)?._id,
                10,
                offset,
                sectionWiseQuestion
              )
            );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const handleScroll = (e) => {
      //   const scrollHeight = e.target.documentElement.scrollHeight;
      // const currentHeight =
      // e.target.documentElement.scrollTop + window.innerHeight;
      const scrollHeight = e.target?.scrollHeight;

      const currentHeight = e.target?.clientHeight + e.target?.scrollTop;

   

      if (currentHeight + 1 >= scrollHeight) {
        e.target?.scrollTop !== 0 &&
          dispatch({ type: "SET_OFFSET", payload: offset + 10 });
      }
    };
    element !== undefined && element.addEventListener("scroll", handleScroll);
    return () => element !== undefined && element.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, selectedSection, sectionWiseData.length]);

  const resetHookState = () => {
    dispatch({ type: "SET_OFFSET", payload: 0 });
    dispatch(setQuestions([]));
    dispatch({ type: "HAS_MORE_DATA", payload: true });
  }

  return {loading, resetHookState};
};

export default useInfiniteScroll;
