import React, { useEffect } from "react";
import CustomButton from "../../UI/button/button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import proceedButtonUseStyles from "./proceedButtonStyles";

function ProceedButtonCard(props) {
  const classes = proceedButtonUseStyles();

  const {
    isEvaluator,
    brmData,
    setBrmMainTabNo,
    setBrmSubTabNo,
    setDeclarationTabNo,
    setControversyTabNo,
    setProfileTabNo,
  } = props;
  const {
    brmMainTabNo,
    brmSubTabNo,
    declarationTabNo,
    controversyTabNo,
    profileTabNo,
  } = brmData;


  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        {brmMainTabNo === 0 && brmSubTabNo === 0 ? (
          <Link
            className={classes.link}
            to={isEvaluator ? "/risk_evaluator" : "/risk_analysis"}
          >
            <CustomButton
              name="exitBtn"
              color="primary"
              variant="outlined"
              className={classes.costumButton}
              // disabled
            >
              {brmMainTabNo === 0 ? "Exit" : "Save & Exit"}
            </CustomButton>
          </Link>
        ) : (
          <CustomButton
            name="exitBtn"
            color="primary"
            variant="outlined"
            className={classes.costumButton}
            onClick={() => {
              if (isEvaluator) {
                if (brmMainTabNo === 0) {
                  if (brmSubTabNo <= 2) {
                    setBrmSubTabNo(brmSubTabNo - 1);
                  } else setBrmMainTabNo(brmMainTabNo - 1);
                }
                if (brmMainTabNo === 1) {
                  if (brmSubTabNo === 0) {
                    setBrmMainTabNo(brmMainTabNo - 1);
                  } else setBrmSubTabNo(brmSubTabNo - 1);
                }
                if (brmMainTabNo === 2) {
                  if (brmSubTabNo === 0) {
                    setBrmMainTabNo(brmMainTabNo - 1);
                  } else setBrmSubTabNo(brmSubTabNo - 1);
                }
                if (brmMainTabNo === 3) {
                  if (brmSubTabNo === 0) {
                    profileTabNo !== 0 && setProfileTabNo(profileTabNo - 1);
                    profileTabNo === 0 && setBrmMainTabNo(brmMainTabNo - 1);
                  } else if (brmSubTabNo === 1) {
                    controversyTabNo !== 0 &&
                      setControversyTabNo(controversyTabNo - 1);
                    controversyTabNo === 0 && setBrmSubTabNo(brmSubTabNo - 1);
                  } else if (brmSubTabNo === 2) {
                    setBrmSubTabNo(brmSubTabNo - 1);
                  } else if (brmSubTabNo === 3) {
                    declarationTabNo !== 0 &&
                      setDeclarationTabNo(declarationTabNo - 1);
                    declarationTabNo === 0 && setBrmSubTabNo(brmSubTabNo - 1);
                  } else setBrmSubTabNo(brmSubTabNo - 1);
                }
              } else {
                brmMainTabNo === 1 && setBrmMainTabNo(brmMainTabNo - 1);
              }
            }}
            // disabled={
            // fieldData.isUpsertMetricData ||
            // materialityAssessment.isUpsert ||
            // esgStrategy.isUpsert
            // }
          >
            {"Previous"}
          </CustomButton>
        )}
        {isEvaluator &&
          (brmMainTabNo === 0 || brmMainTabNo === 1 || brmMainTabNo === 2) && (
            // (brmMainTabNo === 3 && declarationTabNo === 0) ||
            // (brmMainTabNo === 3 &&
            //   brmSubTabNo !== 3 &&
            //   declarationTabNo !== 1)) &&
            <>
              <CustomButton
                name="proceedBtn"
                color="primary"
                variant="contained"
                data-tut="reactour__proceed"
                onClick={() => {
                  if (isEvaluator) {
                    if (brmMainTabNo === 0) {
                      if (brmSubTabNo === 1) {
                        setBrmSubTabNo(0);
                        setBrmMainTabNo(brmMainTabNo + 1);
                      } else setBrmSubTabNo(brmSubTabNo + 1);
                    }
                    if (brmMainTabNo === 1) {
                      if (brmSubTabNo === 3) {
                        setBrmSubTabNo(0);
                        setBrmMainTabNo(brmMainTabNo + 1);
                      } else setBrmSubTabNo(brmSubTabNo + 1);
                    }
                    if (brmMainTabNo === 2) {
                      if (brmSubTabNo === 0) {
                        profileTabNo !== 2 && setProfileTabNo(profileTabNo + 1);
                        profileTabNo === 2 && setProfileTabNo(0);
                        profileTabNo === 2 && setBrmSubTabNo(brmSubTabNo + 1);
                      }else if (brmSubTabNo === 4) {
                        setBrmSubTabNo(0);
                        setBrmMainTabNo(brmMainTabNo + 1);
                      }else setBrmSubTabNo(brmSubTabNo + 1);
                    }
                    if (brmMainTabNo === 3) {

                      if (brmSubTabNo === 0) {
                        profileTabNo !== 3 && setProfileTabNo(profileTabNo + 1);
                        profileTabNo === 3 && setProfileTabNo(0);
                        profileTabNo === 3 && setBrmSubTabNo(brmSubTabNo + 1);
                      } else if (brmSubTabNo === 1) {
                        controversyTabNo !== 1 &&
                          setControversyTabNo(controversyTabNo + 1);
                        controversyTabNo === 1 &&
                          setBrmSubTabNo(brmSubTabNo + 1);
                        controversyTabNo === 1 && setControversyTabNo(0);
                      } else if (brmSubTabNo === 3) {
                        profileTabNo !== 1 &&
                          setDeclarationTabNo(declarationTabNo + 1);
                        profileTabNo === 1 && setDeclarationTabNo(0);
                      } else setBrmSubTabNo(brmSubTabNo + 1);
                    }
                  } else {
                    if (brmMainTabNo === 0) {
                      if (brmSubTabNo !== 2) setBrmSubTabNo(brmSubTabNo + 1);
                      if (brmSubTabNo === 2) {
                        setBrmMainTabNo(brmMainTabNo + 1);
                        setBrmSubTabNo(0);
                      }
                    }
                  }
                }}
                className={classes.checkmargin}
              >
                Proceed
              </CustomButton>
            </>
          )}
        {!isEvaluator && brmMainTabNo === 0 && (
          <>
            <CustomButton
              name="proceedBtn"
              color="primary"
              variant="contained"
              data-tut="reactour__proceed"
              onClick={() => {
                if (brmMainTabNo === 0) {
                  if (brmSubTabNo !== 2) setBrmSubTabNo(brmSubTabNo + 1);
                  if (brmSubTabNo === 2) {
                    setBrmMainTabNo(brmMainTabNo + 1);
                    setBrmSubTabNo(0);
                  }
                }
              }}
              className={classes.checkmargin}
            >
              Proceed
            </CustomButton>
          </>
        )}
        {isEvaluator &&
          brmMainTabNo === 3 &&
          brmSubTabNo === 0 &&
          declarationTabNo === 0 && (
            <Link className={classes.link} to="/risk_evaluator">
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.costumButton}
                onClick={() => {
                  if (isEvaluator) {
                    setProfileTabNo(0);
                    setBrmMainTabNo(0);
                    setBrmSubTabNo(0);
                  }
                }}
                data-tut="reactour__proceed"
              >
                Finish
              </CustomButton>
            </Link>
          )}
        {!isEvaluator && brmMainTabNo === 1 && brmSubTabNo === 0 && (
          <Link className={classes.link} to="/risk_analysis">
            <CustomButton
              color="primary"
              variant="contained"
              className={classes.costumButton}
              onClick={() => {
                // if (isEvaluator) setProfileTabNo(0);
              }}
              data-tut="reactour__proceed"
            >
              Finish
            </CustomButton>
          </Link>
        )}
      </CardContent>
    </Card>
  );
}

export default ProceedButtonCard;
