import React, { useEffect, useState } from "react";

import { CardContent } from "@material-ui/core";

import { corporateSurveyQuestions } from "../../../../utils/corporateSurveyQuestion/corporateSurveyQuestions";

import SurveyQuestions from "../../surveyQuestions/surveyQuestions";
import PeerAnalysis from "../../peerAnalysis/peerAnalysis";
import EsgCompantProfile from "../../esgCompanyProfile/esgCompantProfile";
import PeerDocuments from "../../publicInfo/peerDocuments";
import ApiConnections from "../../publicInfo/apiConnections";
import Documents from "../../publicInfo/documents";
import StakeholderList from "../stakeholderList";
import DataBreakdown from "../DataBreakdown";
import SurveyResponses from "../../surveyResponses/surveyResponses";
import Contoversies from "../../controversiesTab/controversies";
import EsgRiskRating from "../../esgRiskRating/esgRiskRating";
import ShareDialog from "./ShareDialog";
import UserDialog from "./UserDialog";
import { brmRightStyles } from "./brmRightStyle";
import CompanyDetails from "./CompanyDetails";

function BrmDataRightEvaluator(props) {
  const classes = brmRightStyles();
  const { brmData, login } = props;

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);

  React.useEffect(() => {
    props.viewMember(
      login?.token ? login?.token : localStorage.getItem("user_token"),
      brmData?.organizationDetails
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id")
    );

    // props.getCalculatedSurvey(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails[0]?._id
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isEvaluator) {
      brmData?.brmMainTabNo === 2 &&
        brmData?.brmSubTabNo === 0 &&
        props.getScores(
          login.token,
          brmData?.organizationDetails[0]?.isin,
          brmData?.organizationDetails[0]?._id
        );
      brmData?.brmMainTabNo === 2 &&
        brmData?.brmSubTabNo === 3 &&
        props.getControversyData(
          login.token,
          brmData?.organizationDetails[0]?.isin
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brmData?.brmMainTabNo, brmData?.brmSubTabNo]);

  return (
    <>
      {shareDialogOpen && (
        <ShareDialog
          shareDialogOpen={shareDialogOpen}
          handleClosShareDialog={() => setShareDialogOpen(false)}
        />
      )}
      {addNewUserDialogOpen && (
        <UserDialog
          addNewUserDialogOpen={addNewUserDialogOpen}
          handleCloseUserDialog={() => setAddNewUserDialogOpen(false)}
        />
      )}
      <CardContent className={classes.cardContent}>
        {/* company setup */}
        {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 0 && (
          <CompanyDetails {...props} />
        )}
        {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 1 && (
          <StakeholderList
            setAddNewUserDialogOpen={setAddNewUserDialogOpen}
            // handleEditUser={handleEditUser}
            brmData={brmData}
          />
        )}

        {/* data setup */}

        {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 0 && (
          <ApiConnections {...props} />
        )}
        {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 1 && (
          <Documents riskEvaluator={props?.riskEvaluator} {...props} />
        )}
        {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 2 && (
          <PeerDocuments riskEvaluator={props?.riskEvaluator} {...props} />
        )}
        {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 3 && (
          <SurveyQuestions
            login={login}
            brmData={brmData}
            setShareDialogOpen={setShareDialogOpen}
            getSurveyeQuestions={props.getSurveyeQuestions}
            addSurveyeQuestions={props.addSurveyeQuestions}
            corporateSurveyQuestions={corporateSurveyQuestions}
          />
        )}

        {/* ESG DIAGNOSIS TABS */}

        {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 0 && (
          <EsgCompantProfile {...props} />
        )}
        {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 1 && (
          <DataBreakdown {...props} />
        )}
        {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 2 && (
          <PeerAnalysis {...props} />
        )}
        {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 3 && (
          <Contoversies {...props} />
        )}
        {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 4 && (
          <SurveyResponses {...props} />
        )}
        {brmData?.brmMainTabNo === 3 && brmData?.brmSubTabNo === 0 && (
          <EsgRiskRating {...props} />
        )}
      </CardContent>
    </>
  );
}

export default BrmDataRightEvaluator;
