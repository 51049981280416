import io from "socket.io-client";

let socket;
let socketCompany;

// connecting to socket io server from backend

// socket connection for general chat
const connectSocket = () => {
  socket = io("http://164.52.202.216:8001");
};

// socket connection for company based chat
const connectSocketCompany = () => {
  socketCompany = io("http://164.52.202.216:8002");
};

export { socket, socketCompany, connectSocket, connectSocketCompany };
