import React from "react";
import { connect } from "react-redux";
import {
  setBrmMainTabNo,
  setBrmSubTabNo,
  setControversyTabNo,
  setDeclarationTabNo,
  setProfileTabNo,
  shareSurvey,
} from "../../redux/actions/brm/BrmActions";
import BrmDetaails from "../../components/widgets/riskAssessment/brmDetaails";
import {
  getBarChartData,
  getCalculatedSurvey,
  getControversyData,
  getNewArticles,
  getCompanyInsights,
  getPeerScores,
  getPublicReports,
  getScores,
  getSentimentCalculatedData,
  getTopicWiseData,
  submitRiskEvaluation,
  uploadFiles,
} from "../../redux/actions/riskEvaluator/riskEvaluatorActions";
import {
  createOrganization,
  setOrganizationDetails,
  updateOrganization,
  viewDetailsOrganization,
} from "../../redux/actions/organization/organizationAction";
import {
  addMember,
  editMember,
  viewMember,
} from "../../redux/actions/member/member";
import {
  addSurveyeQuestions,
  getSurveyeQuestions,
} from "../../redux/actions/corporateSurvey/corporateSurveyActions";

function BrmDetailsCont(props) {
  return (
    <div>
      <BrmDetaails {...props} />
    </div>
  );
}

export const mapStateToProps = (store) => ({
  login: store.login,
  brmData: store.brmData,
  corporateSurvey: store.corporateSurvey,
  riskEvaluator: store.riskEvaluator,
});

export const mapDispatchToProps = (dispatch) => ({
  setBrmSubTabNo: (payload) => {
    dispatch(setBrmSubTabNo(payload));
  },
  setBrmMainTabNo: (payload) => {
    dispatch(setBrmMainTabNo(payload));
  },
  setDeclarationTabNo: (payload) => {
    dispatch(setDeclarationTabNo(payload));
  },
  setControversyTabNo: (payload) => {
    dispatch(setControversyTabNo(payload));
  },
  setProfileTabNo: (payload) => {
    dispatch(setProfileTabNo(payload));
  },

  shareSurvey: (token, organization_id, email) => {
    dispatch(shareSurvey(token, organization_id, email));
  },
  getCalculatedSurvey: (token, organization_id) => {
    dispatch(getCalculatedSurvey(token, organization_id));
  },
  getSurveyeQuestions: (token, organization_id) => {
    dispatch(getSurveyeQuestions(token, organization_id));
  },
  addSurveyeQuestions: (token, organization_id, question) => {
    dispatch(addSurveyeQuestions(token, organization_id, question));
  },
  updateOrganization: (
    token,
    organization_id,
    name,
    isin,
    year_of_assessment,
    logo,
    sectors,
    operation,
    no_of_employees,
    address,
    masterSectors,
    revenue,
    turnoverData,
    country_code,
    aliases
  ) => {
    dispatch(
      updateOrganization(
        token,
        organization_id,
        name,
        isin,
        year_of_assessment,
        logo,
        sectors,
        operation,
        no_of_employees,
        address,
        masterSectors,
        revenue,
        turnoverData,
        country_code,
        aliases
      )
    );
  },
  viewDetailsOrganization: (token, organization_id) => {
    dispatch(viewDetailsOrganization(token, organization_id));
  },
  viewMember: (token, organization_id) => {
    dispatch(viewMember(token, organization_id));
  },
  editMember: (token, user_id, organization_id, role, category) => {
    dispatch(editMember(token, user_id, organization_id, role, category));
  },
  addMember: (
    token,
    bank_id,
    organization_id,
    name,
    email,
    role,
    category,
    type,
    url
  ) => {
    dispatch(
      addMember(
        token,
        bank_id,
        organization_id,
        name,
        email,
        role,
        category,
        type,
        url
      )
    );
  },
  setOrganizationDetails: (payload) => {
    dispatch(setOrganizationDetails(payload));
  },

  getSentimentCalculatedData: (organization_name) => {
    dispatch(getSentimentCalculatedData(organization_name));
  },
  getNewArticles: (token, isin, limit, skip, filter) => {
    dispatch(getNewArticles(token, isin, limit, skip, filter));
  },

  getBarChartData: () => {
    dispatch(getBarChartData());
  },

  getTopicWiseData: () => {
    dispatch(getTopicWiseData());
  },
  submitRiskEvaluation: (token, organization_id, evaluation_value, comment) => {
    dispatch(
      submitRiskEvaluation(token, organization_id, evaluation_value, comment)
    );
  },
  getCompanyInsights: (token, company_isin, year) => {
    dispatch(getCompanyInsights(token, company_isin, year));
  },
  getScores: (token, isin, organization_id) => {
    dispatch(getScores(token, isin, organization_id));
  },
  getPeerScores: (token, isin, industry_code, year) => {
    dispatch(getPeerScores(token, isin, industry_code, year));
  },
  getControversyData: (token, isin) => {
    dispatch(getControversyData(token, isin));
  },
  getPublicReports: (token, organization_id) => {
    dispatch(getPublicReports(token, organization_id));
  },
  uploadFiles: (
    token,
    isin,
    year,
    file,
    startdate,
    enddate,
    type,
    organization_id,
    source_url
  ) => {
    dispatch(
      uploadFiles(
        token,
        isin,
        year,
        file,
        startdate,
        enddate,
        type,
        organization_id,
        source_url
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BrmDetailsCont);
