import { Grid, Icon, Typography } from "@material-ui/core";
import React from "react";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import docIcon from "../../../images/PDF.png";
import calendar from "../../../images/calendar.png";
import arrow from "../../../images/arrow-right.png";

const DocComplaintCard = ({ doc }) => {
  const { heading, name, date, status, description } = doc;
  return (
    <div style={{ padding: 12 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{ fontSize: 14, fontWeight: 500, padding: "10px 0px" }}
        >
          {heading}
        </Typography>
        <div
          style={{
            backgroundColor: "#BFE2CD",
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
            padding: "3px 5px",
          }}
        >
          <div
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              marginRight: 2,
              backgroundColor: "#154328",
            }}
          />
          <Typography
            style={{ fontSize: 11, fontWeight: 600, color: "#154328" }}
          >
            {status}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={docIcon} alt="docIcon" style={{ width: 20 }} />
          <Typography style={{ fontSize: 14, padding: 10 }}>{name}</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={calendar} alt="" style={{ width: 20 }} />
          <Typography style={{ fontSize: 13, padding: 10 }}>{date}</Typography>
          <img src={arrow} alt="" style={{ width: 20 }} />
          <Typography style={{ fontSize: 13, padding: 10 }}>
            23rd Aug 2030
          </Typography>
        </div>
      </div>

      <Typography
        style={{
          fontSize: 13,
          fontWeight: 400,
          padding: "8px 8px",
          wordBreak: "break",
          border: "1px solid #E9E9E9",
          backgroundColor: "#FCFCFC",
          borderRadius: "6px",
          // marginLeft: 5,
          width: "100%",
        }}
      >
        {description}
      </Typography>
    </div>
  );
};
function DoraCompilance() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Dora Compilance
        </Typography>
        <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
          more_vert
        </Icon>
      </div>

      <div
        className="scroll"
        style={{ height: "58vh", marginTop: "10px", paddingBottom: 20 }}
      >
        <BlueBackgroundCard
          heading={"document1"}
          subHeading={"Description for one or two lines"}
        />

        <Grid
          container
          justifyContent="space-between"
          style={{
            padding: 10,
            marginTop: 15,
            backgroundColor: "#EBF1F8",
            borderBottom: "1px solid #EBF1F8",
          }}
        >
          <Grid item md={7}>
            <Typography style={{ fontSize: 13, fontWeight: 500 }}>
              Description
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Status
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Reference
            </Typography>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {[
            {
              name: "Overview of the Incident Response Plan",
              status: "Compliant",
            },
            {
              name: "Document Retention and Destruction",
              status: "Not Compliant",
            },
            { name: "Data Residency and Sovereignty", status: "Compliant" },
          ].map((key) => (
            <Grid
              container
              justifyContent="space-between"
              style={{
                padding: 10,
                backgroundColor: "#FBFBFB",
                borderBottom: "1px solid #EBF1F8",
              }}
            >
              <Grid item md={7}>
                <Typography style={{ fontSize: 13, fontWeight: 400 }}>
                  {key.name}
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography style={{ fontSize: 13, fontWeight: 400 }}>
                  {key.status}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  coloe="primary"
                  style={{ fontSize: 13, fontWeight: 400, color: "#3374B9" }}
                >
                  View link
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>

        {[
          {
            heading: "Overview of the Incident Response Plan",
            name: "Document Name 1",
            date: " 24th Aug 2019",
            status: "Complainat",
            description: `Article 12: The Incident Response Plan (IRP) is a crucial component of
            an organization's cybersecurity strategy, designed to effectively and
            efficiently manage and mitigate security incidents. It serves as a
            structured approach for identifying, containing, eradicating, recovering
            from, and learning from security breaches or unexpected events that may
            impact the confidentiality, integrity, or availability of data and
            systems.`,
          },
          {
            heading: "Document Retention and Destruction",
            name: "Document Name 1",
            date: " 24th Aug 2019",
            status: "Complainat",
            description: `Article 124a : Document Retention and Destruction is a critical aspect of an organization's compliance strategy, focusing on the systematic management, storage, and secure disposal of business records. This process involves establishing policies and procedures to govern the lifecycle of documents, ensuring that information is retained for the necessary duration to meet legal, regulatory, and operational requirements while also being securely destroyed when it is no longer needed.`,
          },
        ].map((doc) => (
          <DocComplaintCard doc={doc} />
        ))}
      </div>
    </div>
  );
}

export default DoraCompilance;
