import { Card, CardContent } from "@material-ui/core";
import React from "react";
import TabPanel from "../../UI/tabPanel/tabPanel";


import LeftCard from "../../widgets/riskAssessment/brmDataLeft/LeftCard";
import { brmLeftStyles } from "../../widgets/riskAssessment/brmDataLeft/brmLeftStyle";

function VendorLeft(props) {
  const { vendorTabNo, vendorSubTabNo } = props.vendor;
  const { setVendorSubTabNo } = props;
  const classes = brmLeftStyles();
  const handleTabChange = (index) => {
    setVendorSubTabNo(index);
  };
  const basicDataFirstTab = [
    {
      id: 0,
      mainHead: "Basic Assessment Details",
      subHead:
        "Evaluate and improve your environmental, social, and governance performance with our comprehensive assessment tools and customized recommendations.",
    },
    {
      id: 1,
      mainHead: "Stakeholders List",
      subHead:
        "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
    },
  ];
  
  const dataSetup = [
    {
      id: 0,
      mainHead: "API Connection",
      subHead:
        "Seamlessly integrate ESG assessment data with your existing systems through our flexible and secure API connection",
    },
    {
      id: 1,
      mainHead: "Company Documents",
      subHead:
        "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
    },

    {
      id: 2,
      mainHead: "Survey",
      subHead:
        "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
    },
  ];

  const vendorDiagnosis = [
    {
      id: 0,
      mainHead: "Vendor Profile",
      subHead:
        "Seamlessly integrate ESG assessment data with your existing systems through our flexible and secure API connection",
    },
    {
      id: 1,
      mainHead: "Dora Compilance",
      subHead:
        "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
    },

    {
      id: 2,
      mainHead: "Survey Responses",
      subHead:
        "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
    },
  ];

  const contro = [
    {
      id: 0,
      mainHead: "Controversy Score",
      subHead:
        "Seamlessly integrate ESG assessment data with your existing systems through our flexible and secure API connection",
    },
    {
      id: 1,
      mainHead: "Articles",
      subHead:
        "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
    },

    {
      id: 2,
      mainHead: "Insights",
      subHead:
        "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
    },
  ];
  const vendorRisk = [
    {
      id: 0,
      mainHead: "Third Party Risk Rating",
      subHead:
        "Seamlessly integrate ESG assessment data with your existing systems through our flexible and secure API connection",
    },
    {
      id: 1,
      mainHead: "ESG Risk Rating",
      subHead:
        "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
    },
  ];

  return (
    <div>
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicLeft"
      >
        {" "}
        {
          <CardContent className={classes.cardContent}>
            {/* <div className="scroll" style={{ marginTop: 18 }}> */}
            <TabPanel value={vendorTabNo} index={0}>
              <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
                <LeftCard
                  toMap={basicDataFirstTab}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>

            {/* <TabPanel value={vendorTabNo} index={1}>
            <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
              {leftCard(basicDataThirdTab)}
            </div>
          </TabPanel> */}
            <TabPanel value={vendorTabNo} index={1}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                {" "}
                <LeftCard
                  toMap={dataSetup}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>
            <TabPanel value={vendorTabNo} index={2}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                <LeftCard
                  toMap={vendorDiagnosis}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>
            <TabPanel value={vendorTabNo} index={3}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                {" "}
                <LeftCard
                  toMap={contro}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>
            <TabPanel value={vendorTabNo} index={4}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                <LeftCard
                  toMap={vendorRisk}
                  handleTabChange={handleTabChange}
                  subTabNo={vendorSubTabNo}
                />
              </div>
            </TabPanel>
            {/* </div> */}
          </CardContent>
        }
      </Card>
    </div>
  );
}

export default VendorLeft;
