import { Icon, Typography } from "@material-ui/core";
import React from "react";
import CustomTextField from "../../UI/textfield/textfield";

function ThirdPartyRiskRating() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Third Party Risk Rating
        </Typography>
        <Icon
          style={{
            cursor: "pointer",
            color: "#3374b9",
            fontSize: 20,
          }}
        >
          more_vert
        </Icon>
      </div>

      <div className="scroll" style={{ marginTop: 18, height: "57vh" }}>
        <Typography style={{ fontSize: 13, fontWeight: 500, color: "#6C6C6C" }}>
          Assign the Risk score
        </Typography>

        <div style={{ display: "flex" }}>
          <Typography
            style={{
              color: "#6C6C6C",
              backgroundColor: "#F6F6F6",
              borderRadius: 12,
              padding: "5px 8px",
              fontSize: 13,
              width: "fit-content",
              margin: "5px 8px",
            }}
          >
            Low
          </Typography>
          <Typography
            style={{
              color: "#32A05F",
              backgroundColor: "#EBF6EF",
              borderRadius: 12,
              padding: "5px 8px",
              fontSize: 13,
              width: "fit-content",
              margin: "5px 8px",
            }}
          >
            Medium
          </Typography>
          <Typography
            style={{
              color: "#6C6C6C",
              backgroundColor: "#F6F6F6",
              borderRadius: 12,
              padding: "5px 8px",
              fontSize: 13,
              width: "fit-content",
              margin: "5px 8px",
            }}
          >
            High
          </Typography>
        </div>

        <div style={{ padding: "5px 8px" }}>
          <Typography
            style={{
              color: "#6C6C6C",
              fontSize: 13,
              width: "fit-content",
              padding: "5px 0px",
            }}
          >
            Comments
          </Typography>
          <CustomTextField
            variant="outlined"
            multiline
            size="large"
            inputProps={{
              style: {
                height: 80,
                padding: "0 14px",
              },
            }}
            style={{ width: "100%", height: 70 }}
          />
        </div>
      </div>
    </div>
  );
}

export default ThirdPartyRiskRating;
