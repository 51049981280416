import React from "react";
import { InputAdornment } from "@material-ui/core";
import Textfield from "../../UI/textfield/textfield";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearcheText,
  viewOrganizationEsgGpt,
} from "../../../redux/actions/gpt/esgGptAction";
import search from "../../../images/search.png"
import _debounce from "lodash/debounce"; // Import debounce from lodash

export default function EsgGptSearchBar({ EsgCompanyList }) {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);

  // Debounce the handleSearch function
  const debouncedSearch = _debounce((value) => {
    dispatch(setSearcheText(value));
    if (value !== "") {
      dispatch(viewOrganizationEsgGpt(login.token, 10, 0, value));
    } else {
      dispatch(viewOrganizationEsgGpt(login.token, 10, 0));
    }
  }, 800);

  const handleSearch = (value) => {
    // Call the debouncedSearch function
    debouncedSearch(value);
  };
  const companyLocation = window.location.pathname.includes("company_profiles");
  return (
    <div
      style={{
        width: "96%",
        marginLeft: "6px",
        marginTop: "8px",
        borderRadius: "5px",
        border: "1px solid #E9E9E9",
        fontFamily:"poppins"
      }}
    >
      <Textfield
        name="Search"
        variant="outlined"
        fullWidth
        size="small"
        type="text"
        label={
          companyLocation ? "Search By Company" : "Search by Ticket or Name"
        }
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img src={search} alt="searchIcon" />
              {/* <SearchIcon style={{ color: "#3374B9" }} /> */}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
