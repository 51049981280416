import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CustomTextfield from "../../UI/textfield/textfield";
import brief from "../../../images/briefcaseBlue.png";
import mail from "../../../images/mail.png";
import tick from "../../../images/tick.png";
import signupCardUseStyles from "../../screen/signup/signupCardStyles";
import { useDispatch, useSelector } from "react-redux";
import { sendPremiumPlanRequest } from "../../../redux/actions/gpt/esgGptAction";
import CustomButton from "../../UI/button/button";
function PlanExpired({ isOpen, handleClose, onSubmit, sourceName = "" }) {
  const dispatch = useDispatch();
  const login = useSelector((state) => state?.login);
  const [details, setDetails] = useState({
    name: login?.name,
    email: login?.email,
    orgName: login?.organization_name,
  });
  const classes = signupCardUseStyles();
  const subCard = (details) => {
    return (
      <div style={{ display: "flex", padding: "10px 10px" }}>
        <img alt="" src={tick} width="20px" height="20px" />
        <Typography className={classes.sideBarSubTitle}>
          {details || ""}
        </Typography>
      </div>
    );
  };

  const handleOnsubmit = () => {
    dispatch(
      sendPremiumPlanRequest(
        login?.token,
        details?.name,
        details?.email,
        details?.orgName,
        sourceName
      )
    );
    setDetails({
      name: login?.name,
      email: login?.email,
      orgName: login?.organization_data?.name,
    });
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <div style={{ width: "760px" }}>
        <DialogTitle style={{ backgroundColor: "#EBF1F8", padding: 10 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ padding: 5, fontSize: 18, fontWeight: 600 }}>
              Pricing
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography
            style={{
              padding: 5,
              fontSize: 12,
              fontWeight: 400,
              color: "#5C90C7",
              textTransform: "capitalize",
            }}
          >
            Choose transparency with our ESG Wise Pricing Plans—unlocking
            tailored insights at affordable rates, ensuring sustainable
            decision-making for businesses of all sizes.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between">
            <div item style={{ width: "48%" }}>
              <div>
                <Typography
                  style={{ padding: 5, fontSize: 18, fontWeight: 600 }}
                >
                  Free Plan
                </Typography>
              </div>
              <Typography
                style={{
                  padding: 5,
                  fontSize: 13,
                  fontWeight: 400,
                  textTransform: "capitalize",
                }}
              >
                For the premium plan please contact our team to reach out to
                you.
              </Typography>
              {subCard("50 Tokens to start")}
              {subCard("Access to library of ESG Data about companies")}
              {subCard("Access to library of ESG Data about companies")}
            </div>
            <div
              style={{
                border: "1px solid #5C90C7",
                height: "300px",
                alignSelf: "center",
              }}
            ></div>
            <div item style={{ width: "48%" }}>
              <Typography style={{ padding: 5, fontSize: 18, fontWeight: 600 }}>
                Premium Plan
              </Typography>
              <Typography
                style={{
                  padding: 5,
                  fontSize: 13,
                  fontWeight: 400,
                  textTransform: "capitalize",
                }}
              >
                For the premium plan please contact our team to reach out to
                you.
              </Typography>
              <Typography className={classes.labelText}>Name</Typography>
              <CustomTextfield
                id="name"
                name="name"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                // placeholder="@example.com"
                value={details.name}
                onChange={(e) =>
                  setDetails((prev) => ({ ...prev, name: e.target.value }))
                }
                className={classes.costumTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      name="passSvg"
                      position="end"
                      className={classes.inputStyle}
                    >
                      <AccountCircleOutlinedIcon style={{ color: "#5C90C7" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className={classes.labelText}>Work Email</Typography>
              <CustomTextfield
                id="email"
                name="email"
                variant="outlined"
                size="small"
                fullWidth
                type="email"
                placeholder="@example.com"
                value={details.email}
                onChange={(e) =>
                  setDetails((prev) => ({ ...prev, email: e.target.value }))
                }
                className={classes.costumTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      name="passSvg"
                      position="end"
                      className={classes.inputStyle}
                    >
                      <img alt="img" src={mail} />
                    </InputAdornment>
                  ),
                }}
              />
              <div>
                <Typography className={classes.labelText}>
                  Organization Name
                </Typography>
                <CustomTextfield
                  type="text"
                  name="orgName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={details.orgName}
                  onChange={(e) =>
                    setDetails((prev) => ({ ...prev, orgName: e.target.value }))
                  }
                  className={classes.costumTextField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        name="passSvg"
                        position="end"
                        className={classes.inputStyle}
                      >
                        <img alt="img" src={brief} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            autoFocus
            onClick={() => {
              handleClose();
              handleOnsubmit();
              // onSubmit(details);
            }}
            style={{
              textTransform: "none",
              // backgroundColor: "#5C90C7",
              // color: "#FFFF",
              padding: "2px 18px",
            }}
            color={"primary"}
            variant={"contained"}
            disabled={!details?.name || !details?.email || !details?.orgName}
          >
            Submit
          </CustomButton>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default PlanExpired;
