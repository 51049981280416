import { useState } from "react";
import { Box } from "@material-ui/core";
import { Tooltip } from "react-tooltip";

import MapChart from "./mapComponents/Map";
import Legend from "./mapComponents/Legend";
import { interestData } from "./mapUtils/interestData";

const InterestMap = () => {
  const [content, setContent] = useState("");
  return (
    // main container
    <Box
      sx={{
        mt: 5,
        ml: -5,

        display: "flex",
        alignItems: "flex-start",
      }}
    >
      {/* map container */}
      <Box
        sx={{
          width: "70em",
        }}
      >
        <MapChart setTooltipContent={setContent} countries={interestData} />
        <Tooltip anchorId="map" content={content} float />
      </Box>

      {/* legend container */}
      <Box sx={{ width: "30em", mr: 5 }}>
        <Legend countries={interestData} />
      </Box>
    </Box>
  );
};
export default InterestMap;
