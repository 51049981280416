import React from "react";
import { Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import companyLaod from "../../../images/companyLoading.png";

function CompanyLoading({margin}) {
  return (
    <div
      style={{
        padding: "20px 15px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",marginRight:`${margin}%`,
        // backgroundColor: "cyan",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ padding: 5, fontSize: 14, textAlign:'center',fontWeight: 500 }}>
            <img alt="test" style={{width:'60%'}} src={companyLaod} />
          </div>
          <Typography style={{ fontSize: 15, fontWeight: 500 }}>
            The Company Profile you have requested is being processed
          </Typography>
        </div>
      </div>
      <div style={{ padding: 5, display: "flex", alignItems: "center" }}>
        <Typography style={{ fontSize: 15, fontWeight: 500, color: "#878787" }}>
          Estimated Time :{" "}
        </Typography>
        <Typography
          style={{
            padding: "2px 8px",
            marginLeft: 10,
            borderRadius: "40px",
            backgroundColor: "lightgreen",
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          7 Days
        </Typography>
        <ExpandMoreIcon style={{ color: "#3374B9" }} />
      </div>
      <div
        style={{ display: "flex", alignItems: "center", padding: "15px 5px" }}
      >
        <Typography style={{ fontSize: 13, fontWeight: 400 }}>
          Please Check the Icon on the listing once the process is complete. it
          will change to
        </Typography>
        <div
          style={{
            padding: "2px 8px",
            display: "flex",
            alignItems: "center",
            borderRadius: "40px",
            backgroundColor: "lightgreen",
            fontSize: 13,
            fontWeight: 400,
            marginLeft: 10,
          }}
        >
          new
        </div>
      </div>
    </div>
  );
}

export default CompanyLoading;
