import React from "react";

import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import CustomButton from "../../../../../../UI/button/button";

export default function QuestionTabDrawer({
  open,
  handleClose,
  headerName,
  buttonText,
  children,
}) {
  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} open={open} onClose={() => handleClose(false)}>
          <Box
            style={{
              width: "40vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                //   borderBottom: "1px solid #3374B9",
                boxShadow: "0px 1px 3px 1px rgba(51, 116, 185, 0.15)",

                padding: "10px",
              }}
            >
              <Typography>{headerName}</Typography>
              <IconButton onClick={() => handleClose(false)}>
                <CloseIcon style={{ color: "#3374B9" }} />
              </IconButton>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                //   borderBottom: "1px solid #3374B9",

                padding: "10px",
              }}
            >
              {children}
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                //   borderBottom: "1px solid #3374B9",

                padding: "10px",
                marginTop: "auto",
              }}
            >
              <CustomButton
                style={{
                  textTransform: "none",
                  marginRight: 10,
                  width: "150px",
                }}
                size="small"
                color="primary"
                variant="text"
                onClick={() => handleClose(false)}
              >
                <Typography>Cancel</Typography>
              </CustomButton>
              <CustomButton
                style={{
                  textTransform: "none",
                  marginRight: 10,
                  width: "150px",
                }}
                size="small"
                color="primary"
                variant="contained"
                onClick={() => handleClose(false)}
                // onClick={() => handleQuestionSave(id, selectedQuestion, true)}
              >
                <Typography>{buttonText}</Typography>
              </CustomButton>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
