import React, { useEffect, useRef, useState } from "react";
import TabPanel from "../../UI/tabPanel/tabPanel";
import CustomTextfield from "../../UI/textfield/textfield";
import PublicReports from "./publicReports";
import DataBreakdown from "../riskAssessment/DataBreakdown";
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Button,
  TextField,
} from "@material-ui/core";

import CalendarIcon from "@material-ui/icons/DateRange";

import Analytics from "../../../images/Analytics.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomButton from "../../UI/button/button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector } from "react-redux";
import { UserCard } from "../riskAssessment/riskUtilComponents";
import { appTheme } from "../riskAssessment/brmDetaails";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import YearRangePicker from "./documentsElements/YearRangePicker";

const docStyles = makeStyles((theme) => ({
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },

  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  emptyCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyImg: {
    width: "40%",
  },
  text: { fontWeight: 500, paddingBottom: 5 },
  subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
  uploadBtn: {
    width: "20%",
    textTransform: "Capitalize",
    margin: "0px 5px",
  },

  endButton: {
    position: "fixed",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    textTransform: "capitalize",
    marginRight: "5px",
    fontSize: "14px",
    padding: 10,
    color: "#fff",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374D9",
      boxShadow: "1px 1px 3px #3374B9",
    },
  },
}));

function Documents({
  showPeerDocs,
  peerCompany,
  setShowpeerDocs,
  brmData,
  getPublicReports,
  login,
  ...props
}) {
  const riskEvaluator = useSelector((state) => state.riskEvaluator);
  // const login = useSelector(state => state?.login)

  const classes = docStyles();

  const inputRef = useRef(null);

  const [reportsTab, setReportsTab] = useState(0);
  const [file, setFile] = React.useState([]);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
  const [isrequestUpload, setIsRequestUpload] = useState(false);
  const [requestTabNo, setRequestTabNo] = useState(0);

  const [fileData, setFileData] = useState({});
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  // States for categories
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [yearPicker, setYearPicker] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState({});

  const handleAddCategory = () => {
    setCategories((prevCategories) => [...prevCategories, {}]);
  };

  const handleCategoryChange = (index, key, value) => {
    setCategoryData((prevData) => ({
      ...prevData,
      [index]: {
        ...prevData[index],
        [key]: value,
      },
    }));
  };

  const toggleYearPicker = () => {
    setYearPicker(!yearPicker);
  };

  const handleRangeData = (rangeData) => {
    handleCategoryChange(selectedCategoryIndex, "year", rangeData);
    setSelectedCategory((preValue) => ({ ...preValue, year: rangeData }));
    setFileData((prev) => ({
      ...prev,
      year: rangeData,
    }));
  };

  const handleFileUpload = (e) => {
    const uploadFile = Object.values(e.target.files);
    // console.log(uploadFile);
    uploadFile !== undefined &&
      setFileData((prev) => ({
        ...prev,
        file: uploadFile[0],
      }));
  };
  const handleFile = (e) => {
    const uploadFile = Object.values(e.target.files);
    uploadFile !== undefined && setFile([...file, ...uploadFile]);
  };

  useEffect(() => {
    reportsTab === 0 &&
      getPublicReports(
        login.token,
        peerCompany?.name
          ? peerCompany._id
          : brmData?.organizationDetails[0]?._id
      );

    reportsTab === 1 &&
      props.getScores(
        login.token,
        brmData?.organizationDetails[0]?.isin,
        brmData?.organizationDetails[0]?._id
      );
  }, [reportsTab]);
  // console.log(fileData);
  //   useEffect(()=>{
  //     const copyfile = new Blob([file], { type: "application/pdf" });
  //     //Build a URL from the file
  //     const fileURL = URL.createObjectURL(copyfile);
  //     //Open the URL on new Window
  //     //  const pdfWindow = window.open();
  //     //  pdfWindow.location.href = fileURL;
  //     console.log('s',fileURL)
  //   },[file])

  const handleFileRemove = (value) => {
    setFile(value);
  };
  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  useEffect(() => {
    // console.log("new mount", fileData.year);
    fileData.year && setStartYear(fileData.year?.split("-")[0]);
    fileData.year && setEndYear(fileData.year?.split("-")[1]);
  }, [fileData.year]);
  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  useEffect(() => {
    const data = [];
    if (!fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(fileData.year.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                xs={12}
                md={12}
                gap={1}
                spacing={2}
                style={{
                  padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                }}
              >
                {yearRange.map((year) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      md={4}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setFileData((prev) => ({
                  ...prev,
                  yearOfAssessment: "",
                }));
                fileData.year && setStartYear(fileData.year.split("-")[0]);
                fileData.year && setEndYear(fileData.year.split("-")[1]);
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setFileData((prev) => ({
                  ...prev,
                  year: `${startYear}-${endYear}`,
                }));
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  const FileUploadDrawer = () => {
    const { members } = brmData;
    const [searchText, setSearchText] = React.useState("");

    // source url states
    const [sourceUrl, setSourceUrl] = useState("");
    const [isValidUrl, setIsValidUrl] = useState(true);

    // xbrl url states
    const [xbrlUrl, setXbrlUrl] = useState("");
    const [isXBRLValidUrl, setIsXBRLValidUrl] = useState(true);

    const isValid = (url) => {
      const urlRegex =
        /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
      return urlRegex.test(url);
    };

    const handleSourceUrlChange = (e) => {
      e.preventDefault();
      const value = e.target.value;
      setSourceUrl(value);
      setIsValidUrl(isValid(value));
    };

    const handleXBRLUrlChange = (e) => {
      e.preventDefault();
      const value = e.target.value;
      setXbrlUrl(value);
      setIsXBRLValidUrl(isValid(value));
    };

    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
        anchor="right"
        onClose={() => {
          setIsRequestUpload(false);
          setIsUploadDrawerOpen(false);
        }}
        // variant="persistent"
        // variant="permanent"
        width="lg"
        open={isUploadDrawerOpen}
      >
        <div style={{ width: "500px", padding: 10 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              {isrequestUpload ? "Request File" : "Upload File"}
            </Typography>
            <Typography
              onClick={() => {
                setIsRequestUpload(false);
                setIsUploadDrawerOpen(false);
                setCategoryData({});
                setCategories([]);
                setRequestTabNo(0);
                setSelectedCategory({});
              }}
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              <CloseIcon style={{ cursor: "pointer" }} />
            </Typography>
          </div>
          {isrequestUpload ? (
            <div>
              <MuiThemeProvider theme={appTheme}>
                <AppBar
                  position="static"
                  color="inherit"
                  className={classes.AppBar}
                >
                  <Tabs
                    value={requestTabNo}
                    onChange={(e, newVal) => {
                      setRequestTabNo(newVal);
                      // setReportsTab(0);
                    }}
                  >
                    <Tab
                      disableRipple
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>Participants</span>
                      }
                    />
                    <Tab
                      disableRipple
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>Document Type</span>
                      }
                    />
                  </Tabs>
                </AppBar>
              </MuiThemeProvider>
              <TabPanel value={requestTabNo} index={0}>
                <div style={{ marginTop: 40 }}>
                  <CustomTextfield
                    name="Search"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Search user by name or email Id or employee id"
                    onChange={(e) => {
                      // setSearchCompanyText(e.target.value);
                      // onSearchHandler(e);
                      setSearchText(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon style={{ color: "#3374B9" }} />
                        </InputAdornment>
                      ),
                    }}
                    // value={searchCompanyText}
                  />
                  {members
                    .filter(
                      (m) =>
                        m.name.includes(searchText) ||
                        m.email.includes(searchText)
                    )
                    .map((member) => (
                      <UserCard
                        member={{
                          name: member?.name,
                          email: member?.email,
                          role: member?.role,
                        }}
                        onClick={() => {}}
                      />
                    ))}
                </div>
              </TabPanel>
              <TabPanel value={requestTabNo} index={1}>
                <div style={{ marginTop: 40 }}>
                  <BlueBackgroundCard heading="Click on add categories and select the required document type and the year." />

                  {categories.map((_, index) => (
                    <Grid container key={index} style={{ margin: "1.5em 0" }}>
                      <Grid md={6} style={{}}>
                        <CustomTextfield
                          name={`category-${index}`}
                          value={categoryData[index]?.category}
                          label="Select Type of Document"
                          variant="outlined"
                          size="small"
                          fullWidth
                          select
                          onChange={(e) =>
                            handleCategoryChange(
                              index,
                              "category",
                              e.target.value
                            )
                          }
                        >
                          {[
                            "Annual Report",
                            "ESG Report",
                            "GRI Report",
                            "Other",
                          ].map((cat) => (
                            <MenuItem key={cat} value={cat}>
                              {cat}
                            </MenuItem>
                          ))}
                        </CustomTextfield>
                      </Grid>
                      <Grid md={6}>
                        <CustomTextfield
                          name={`selectYear-${index}`}
                          value={categoryData[index]?.year}
                          label={!categoryData[index]?.year && "Select Year"}
                          variant="outlined"
                          style={{ marginLeft: "1em", cursor: "pointer" }}
                          InputProps={{
                            style: {
                              pointerEvents: "none",
                            },
                            endAdornment: (
                              <CalendarIcon style={{ color: "#3374B9" }} />
                            ),
                          }}
                          size="small"
                          onClick={() => {
                            setSelectedCategory(categoryData[index]);
                            setSelectedCategoryIndex(index);
                            toggleYearPicker();
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <CustomButton
                    color="primary"
                    style={{ textTransform: "none" }}
                    onClick={handleAddCategory}
                  >
                    Add Category
                  </CustomButton>
                </div>
              </TabPanel>

              {/* submit button for Request File */}
              <Button
                variant="text"
                style={{
                  color: "#3374B9",
                  position: "fixed",
                  bottom: 10,
                  right: 160,
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  padding: 10,
                }}
                onClick={() => {
                  setIsRequestUpload(false);
                  setIsUploadDrawerOpen(false);
                  setCategoryData({});
                  setCategories([]);
                  setRequestTabNo(0);
                  setSelectedCategory({});
                }}
              >
                Back
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                className={classes.endButton}
                disabled={
                  requestTabNo === 0
                    ? false
                    : !categories.length === 0 ||
                      Object.keys(selectedCategory).length === 0
                }
                onClick={() => {
                  if (requestTabNo === 0) {
                    setRequestTabNo(1);
                  } else {
                    setCategoryData({});
                    setCategories([]);
                    setIsRequestUpload(false);
                    setIsUploadDrawerOpen(false);
                    setRequestTabNo(0);
                    setSelectedCategory({});
                  }
                }}
              >
                {requestTabNo === 0 ? "Save & Continue" : "Assign to Users"}
              </Button>
            </div>
          ) : (
            <div>
              <CustomTextfield
                // name={`category-${index}`}
                value={fileData?.type}
                label="Select Type of Document"
                variant="outlined"
                size="small"
                fullWidth
                select
                style={{ cursor: "pointer", marginTop: "25px" }}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setFileData((prev) => ({
                    ...prev,
                    type: value,
                  }));
                }}
              >
                {brmData.organizationDetails[0].country_name === "India"
                  ? ["Annual Report", "ESG Report", "GRI Report", "XBRL"].map(
                      (item) => (
                        <MenuItem
                          key={item}
                          value={item}
                          style={{ backgroundColor: "white" }}
                        >
                          {item}
                        </MenuItem>
                      )
                    )
                  : ["Annual Report", "ESG Report", "GRI Report"].map(
                      (item) => (
                        <MenuItem
                          key={item}
                          value={item}
                          style={{ backgroundColor: "white" }}
                        >
                          {item}
                        </MenuItem>
                      )
                    )}
              </CustomTextfield>
              <CustomTextfield
                value={fileData?.year}
                label="Year Of Assessment"
                variant="outlined"
                fullWidth
                style={{ cursor: "pointer", margin: "25px 0px" }}
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                  endAdornment: <CalendarIcon style={{ color: "#3374B9" }} />,
                }}
                size="small"
                onClick={() => {
                  toggleYearPicker();
                }}
              />

              <Box
                width="100%"
                style={{
                  margin: "10px 5px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                {fileData.type !== "XBRL" && (
                  <>
                    <AttachFileIcon
                      style={{ backgroundColor: "#ced4da", padding: 4 }}
                      color="disabled"
                    />
                    <input
                      name="actUpload"
                      id="contained-button-activityFile"
                      type="file"
                      accept=".pdf"
                      ref={inputRef}
                      onChange={(e) => handleFileUpload(e)}
                      style={{ display: "none" }}
                      multiple
                    />
                    <div
                      style={{
                        borderBottom: "2px solid #ced4da",
                        marginLeft: 20,
                        width: "100%",
                      }}
                    >
                      {fileData?.file?.name
                        ? fileData.file.name
                        : "Upload file"}
                    </div>
                  </>
                )}
              </Box>

              {fileData.type === "XBRL" && (
                <CustomTextfield
                  value={xbrlUrl}
                  label="XBRL URL"
                  variant="outlined"
                  fullWidth
                  size="small"
                  style={{ margin: "-25px 0px" }}
                  // error={!isXBRLValidUrl} // Show error if URL is invalid
                  // helperText={!isXBRLValidUrl && "Please enter a valid URL"}
                  onChange={handleXBRLUrlChange}
                />
              )}

              <CustomTextfield
                key={"sourceUrl"}
                value={sourceUrl}
                label="Source URL"
                variant="outlined"
                fullWidth
                style={{
                  margin: "25px 0px",
                }}
                size="small"
                // error={!isValidUrl} // Show error if URL is invalid
                // helperText={!isValidUrl && "Please enter a valid URL"}
                onChange={handleSourceUrlChange}
              />

              <Button
                disabled={
                  !fileData.type ||
                  !fileData.year ||
                  // !isValidUrl ||
                  !sourceUrl ||
                  (fileData?.type === "XBRL" && !xbrlUrl)
                }
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  textTransform: "none",
                  color: "#3374b9",
                  marginRight: "5px",
                  fontSize: "14px",
                  padding: 10,
                }}
                onClick={() => {
                  setFile((prev) => {
                    let data = [...prev];
                    data.push(fileData?.file);
                    return data;
                  });
                  setIsUploadDrawerOpen(false);

                  setFileData("");
                  fileData.type === "XBRL"
                    ? props.uploadFiles(
                        login.token,
                        brmData?.organizationDetails[0]?.isin,
                        fileData.year,
                        xbrlUrl,
                        fileData.year.split("-")[0],
                        fileData.year.split("-")[1],
                        fileData.type,
                        brmData?.organizationDetails[0]?._id,
                        sourceUrl
                      )
                    : props.uploadFiles(
                        login.token,
                        brmData?.organizationDetails[0]?.isin,
                        fileData.year,
                        fileData.file,
                        fileData.year.split("-")[0],
                        fileData.year.split("-")[1],
                        fileData.type,
                        brmData?.organizationDetails[0]?._id,
                        sourceUrl
                      );
                }}
              >
                {fileData.type === "XBRL" ? "Submit" : "Upload file"}
              </Button>
              {/* <Typography
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  cursor: "pointer",
                  textTransform: "none",
                  color: "#3374b9",
                  marginRight: "5px",
                  fontSize: "14px",
                  padding: 10,
                }}
                onClick={() => {
                  setFile((prev) => {
                    let data = [...prev];
                    data.push(fileData?.file);
                    return data;
                  });
                  setIsUploadDrawerOpen(false);

                  setFileData("");

                  props.uploadFiles(
                    login.token,
                    brmData?.organizationDetails[0]?.isin,
                    fileData.year,
                    fileData.file,
                    fileData.year.split("-")[0],
                    fileData.year.split("-")[1],
                    fileData.type,
                    brmData?.organizationDetails[0]?._id
                  );
                }}
              >
                Upload file
              </Typography> */}
            </div>
          )}
        </div>
      </Drawer>
    );
  };

  return (
    <div style={{ height: "63vh" }}>
      {isUploadDrawerOpen && <FileUploadDrawer />}
      {isDatePickerDialogOpen && yearPickerDialog()}

      <YearRangePicker
        open={yearPicker}
        onClose={toggleYearPicker}
        currentRange={selectedCategory?.year}
        handleRangeData={handleRangeData}
        singleYear={true}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showPeerDocs && brmData?.brmSubTabNo === 2 && (
            <Icon>
              <ArrowBackIcon
                onClick={() => setShowpeerDocs(false)}
                style={{ color: "#3374B9", cursor: "pointer" }}
              />
            </Icon>
          )}
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "16px",
              marginLeft: showPeerDocs ? "5px" : 0,
            }}
          >
            {showPeerDocs && brmData?.brmSubTabNo === 2
              ? peerCompany?.name
              : "Company Documents"}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "20%",
          }}
        >
          <Typography
            style={{
              textTransform: "none",
              color: "#3374b9",
              marginRight: "15px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Filter
          </Typography>

          {/* <input
            name="actUpload"
            id="contained-button-activityFile"
            type="file"
            accept=".pdf"
            onChange={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
            style={{ display: "none" }}
            multiple
          />
          <label htmlFor="contained-button-activityFile"> */}
          {/* <CustomButton
            color="primary"
            style={{ textTransform: "none", padding: "3px 10px" }}
          >
            Request Files
          </CustomButton> */}
          {/* {console.log("button", isrequestUpload, isUploadDrawerOpen)} */}
          <Typography
            role="button"
            onClick={() => {
              setIsUploadDrawerOpen(!isUploadDrawerOpen);
              setIsRequestUpload(!isrequestUpload);
            }}
            style={{
              cursor: "pointer",
              textTransform: "none",
              color: "#3374b9",
              marginRight: "15px",
              fontSize: "14px",
            }}
          >
            Request File
          </Typography>
          <Typography
            role="button"
            onClick={() => {
              setIsUploadDrawerOpen(!isUploadDrawerOpen);
            }}
            style={{
              cursor: "pointer",
              textTransform: "none",
              color: "#3374b9",
              marginRight: "5px",
              fontSize: "14px",
            }}
          >
            Upload File
          </Typography>
          {/* </label> */}
        </div>
      </div>
      {props.riskEvaluator?.publicDocuments.length > 0 ? (
        <>
          {/* <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          <Tabs
            value={reportsTab}
            onChange={(e, newVal) => {
              setReportsTab(newVal);
              // setReportsTab(0);
            }}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Reports</span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Data Breakdown</span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider> */}
          <TabPanel value={reportsTab} index={0}>
            <PublicReports
              {...props}
              publicDocuments={props.riskEvaluator?.publicDocuments}
              file={file}
              handleFileRemove={handleFileRemove}
            />
          </TabPanel>
          {/* <TabPanel value={reportsTab} index={1}>
        <DataBreakdown
          riskEvaluator={riskEvaluator}
          scores={riskEvaluator?.scores}
        />
      </TabPanel> */}
        </>
      ) : (
        <div className={classes.emptyCont}>
          <img className={classes.emptyImg} src={Analytics} alt="analytics" />
          <Typography className={classes.text}>
            Currently No File has Been Upladed To Showcase
          </Typography>
          <Typography className={classes.subText}>
            Upload file type (.xls, .pdf, .doc) Max size of 5 Mb
          </Typography>
          <Grid container justifyContent="center">
            <CustomButton
              className={classes.uploadBtn}
              // variant="contained"
              color="primary"
              onClick={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
            >
              Upload File
            </CustomButton>
            <CustomButton
              className={classes.uploadBtn}
              variant="contained"
              color="primary"
              onClick={() => {
                setIsUploadDrawerOpen(!isUploadDrawerOpen);
                setIsRequestUpload(true);
              }}
            >
              Request File
            </CustomButton>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default Documents;
