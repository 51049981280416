import {
  SET_VENODR_SUB_TAB_NO,
  SET_VENODR_TAB_NO,
} from "../../../constants/vendorConstants";

const initialState = {
  vendorTabNo: 0,
  vendorSubTabNo: 0,
};

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENODR_TAB_NO:
      return { ...state, vendorTabNo: action.payload };
    case SET_VENODR_SUB_TAB_NO:
      return { ...state, vendorSubTabNo: action.payload };
    default:
      return state;
  }
};
export default vendorReducer