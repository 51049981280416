import {
  SET_CORPORATE_QUESTIONS,
  RESET_CORPORATE,
  SET_CORPORATE_ANSWERS,
  SET_CORPORATE_ORGANIZATIONS,
  SET_ASSIGNED_SECTION,
  SET_SELECTED_ORGANIZATIO_CORP,
  SET_SELECTED_OPTION,
  SET_PUBLISH_ANSWER,
} from "../../../constants/corporateSurveyConstants";

const initial_state = {
  corporateQuestions: "",
  corporateAnswers: "",
  corporateOrganizations: [],
  assignedSection: [],
  selectedOrganizationCorp: {},
  selectedOption: null,
  publishAnswer: [],
};

export default function corporateReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_CORPORATE_QUESTIONS:
      return (state = { ...state, corporateQuestions: action.payload });
    case SET_CORPORATE_ANSWERS:
      return (state = { ...state, corporateAnswers: action.payload });
    case SET_CORPORATE_ORGANIZATIONS:
      return (state = { ...state, corporateOrganizations: action.payload });
    case SET_ASSIGNED_SECTION:
      return (state = { ...state, assignedSection: action.payload });
    case SET_SELECTED_ORGANIZATIO_CORP:
      return (state = { ...state, selectedOrganizationCorp: action.payload });
    case SET_SELECTED_OPTION:
      return (state = { ...state, selectedOption: action.payload });
    case SET_PUBLISH_ANSWER:
      return (state = { ...state, publishAnswer: action.payload });
    case RESET_CORPORATE:
      return (state = initial_state);
    default:
      return state;
  }
}
