import React from "react";
import { Bar } from "react-chartjs-2";

const StackedChart = ({ chartData, chartLabel, defaultColors }) => {
  const months = Object.keys(chartData);
  const days = Array.from(
    new Set([].concat(...months.map((month) => Object.keys(chartData[month]))))
  );

  const datasets = days.map((day, index) => {
    const data = months.map((month) =>
      chartData[month][day] ? chartData[month][day] : 0
    );

    return {
      label: `${chartLabel} ${day}`,
      data,
      backgroundColor: defaultColors[index % defaultColors.length],
      borderWidth: 1,
      barThickness: 30,
    };
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
  };

  const data = {
    labels: months,
    datasets,
  };

  return (
    <div style={{ height: "100%" }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default StackedChart;
