import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';
import logo from '../../../images/ImpactGrows White Corrected.svg';
import CustomTextfield from '../../UI/textfield/textfield';
import CustomButton from '../../UI/button/button';
import SearchIcon from '@material-ui/icons/Search';
import FAQ from './faq';
import Privacy from './privacy';
import TnC from './T&C';
import '../../../styles/drawer.css';
import SwipeableViews from 'react-swipeable-views';
import drawerUseStyles from './drawerStyles';

export default function PolicyDrawer(props) {
  const classes = drawerUseStyles();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    props.getCompanyLogo && props.getCompanyLogo(window.location.hostname);

    if (window.location.pathname === '/faq') {
      setIndex(0);
    } else if (window.location.pathname === '/tnc') {
      setIndex(1);
    } else if (window.location.pathname === '/privacy_policy') {
      setIndex(2);
    }
  }, []);

  const handleChange = (event, value) => {
    setIndex(value);
  };

  const handleChangeIndex = (index) => {
    setIndex(index);
  };
 
  return (
    <>
      <Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AppBar className={classes.appBar}>
            <Grid container justifyContent="center">
              <Grid
               
                item
                xs={12}
                sm={11}
                md={3}
                lg={5}
              >
                <img className={classes.logo}
                 style={
                  JSON.parse(localStorage.getItem('logo_styles')) ? JSON.parse(localStorage.getItem('logo_styles')) : props.login.company_logo ? props.login.company_logo.logo_tyles : {}
                }
                  src={
                    localStorage.getItem('logo')
                      ? localStorage.getItem('logo')
                      : props.login.company_logo && props.login.company_logo.logo
                      ? props.login.company_logo.logo
                      : logo
                  }
                  alt="logo"
                />
              </Grid>
              <Grid
                container
                xs={12}
                sm={12}
                md={8}
                lg={6}
                className={classes.paddings}
              >
                <Grid xs={12}>
                  {' '}
                  <Tabs value={index} onChange={handleChange}>
                    <Tab label="FAQ" />
                    <Tab label="Terms & Conditions" />
                    <Tab label="Privacy Policy" />
                    <CustomButton
                      variant="outlined"
                      size="large"
                      className={classes.buttonstyle}
                    >
                      Contact Us
                    </CustomButton>
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.paddings}
            >
              <Grid item xs={12} sm={8} md={8} lg={4}>
                <SwipeableViews
                  index={index}
                  onChangeIndex={handleChangeIndex}
                  style={{ marginBottom: '-15px' }}
                >
                  <div className={classes.largeText}>Faq</div>
                  <div className={classes.largeText}>T&C</div>
                  <h2 className={classes.largeText}>Privacy</h2>
                </SwipeableViews>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={7}>
                {/* <CustomTextfield
                  size="small"
                  variant="outlined"
                  placeholder="search"
                  className={classes.searchBox}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon color="primary" size="small" />
                      </InputAdornment>
                    ),
                  }}
                ></CustomTextfield> */}
              </Grid>
            </Grid>
          </AppBar>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {index === 0 ? <FAQ /> : index === 1 ? <TnC /> : <Privacy />}
        </Grid>
      </Grid>
    </>
  );
}
