import encrypt from "../../../redux/actions/shared/sharedActions";
import UNIVERSAL from "../../../config";
import { set_loader, unset_loader } from "../loader/loader_action";
import {
  SET_CONTROVERSY_TAB_NO,
  SET_COUNTRIES,
  SET_DECLARATION_TAB_NO,
  SET_PROFILE_TAB_NO,
  SET_SECTOR_INDUSTRIES,
  SET_SELECTED_SECTOR_INDUSTRIES,
} from "../../../constants/brmConstants";
import { viewMember } from "../member/member";
import { set_snack_bar } from "../snackbar/snackbar_action";

export const setCorporateAnswers = (payload) => {
  return {
    // type: SET_CORPORATE_ANSWERS,
    payload,
  };
};

export const shareSurvey = (token, organization_id, email) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id,
      email,
      url: "http://localhost:3001/corporate_questions",
    });

    return fetch(UNIVERSAL.BASEURL + "/assign_member/assign_member", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewMember(token, organization_id));
        }
        dispatch(unset_loader);
      });
  };
};
export function setBrmMainTabNo(payload) {
  return {
    type: "SET_BRM_MAIN_TAB_NO",
    payload: payload,
  };
}
export function setBrmSubTabNo(payload) {
  return {
    type: "SET_BRM_SUB_TAB_NO",
    payload: payload,
  };
}
export function setDeclarationTabNo(payload) {
  return {
    type: SET_DECLARATION_TAB_NO,
    payload: payload,
  };
}
export function setControversyTabNo(payload) {
  return {
    type: SET_CONTROVERSY_TAB_NO,
    payload: payload,
  };
}
export function setProfileTabNo(payload) {
  return {
    type: SET_PROFILE_TAB_NO,
    payload: payload,
  };
}

export const addCompanyBrm = (payload) => {
  return {
    type: "ADD_COMPANY_BRM",
    payload: payload,
  };
};
export const setCorporateAns = (payload) => {
  return {
    type: "SET_CORPORATE_ANS",
    payload: payload,
  };
};

export function setSectorIndustries(payload) {
  return {
    type: SET_SECTOR_INDUSTRIES,
    payload: payload,
  };
}
export function setSelectedSectorIndustries(payload) {
  return {
    type: SET_SELECTED_SECTOR_INDUSTRIES,
    payload: payload,
  };
}
export function setCountries(payload) {
  return {
    type: SET_COUNTRIES,
    payload: payload,
  };
}

export function getSectorIndustries(token) {
  const data = encrypt({
    "user-token": token,
  });

  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/organization/get_sector_industries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSectorIndustries(responseJson.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function getCountries(token) {
  const data = encrypt({
    "user-token": token,
  });

  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/organization/get_countries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCountries(responseJson.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function assignUserSurvey(
  token,
  user_ids,
  organization_id,
  frequency_type,
  survey_date,
  survey_time,
  survey_reminder,
  survey_id = "65ba207c058e57a4a9a37b35",
  assigned_version = 1
) {

  const data = encrypt({
    "user-token": token,
    user_ids,
    organization_id,
    frequency_type,
    survey_id,
    assigned_version,
    survey_date,
    survey_time,
    survey_reminder,
  });

  return (dispatch) => {
    dispatch(set_loader);

    return fetch(UNIVERSAL.BASEURL + "/survey/assign_user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // if (responseJson.status) {
        //   dispatch(setCountries(responseJson.result));
        // }

        dispatch(unset_loader);
        dispatch(set_snack_bar(responseJson?.status, responseJson?.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
