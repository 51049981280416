import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";

import InfoIcon from "../../../../../images/info.svg";
import UpdatedDisclaimerDialog from "./UpdatedDisclaimerDialog";

const UpdatedDisclaimer = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setShowDisclaimer(true);
  }, []);

  const dismissButton = () => {
    setShowDisclaimer(false);
  };

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  return (
    showDisclaimer && (
      <Box
        style={{
          padding: "16px",
          marginTop: "16px",
          marginBottom: "5px",
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          border: "1px solid #C0D4E9",
          borderRadius: "8px",
          gap: "16px",
        }}
      >
        {/* info text */}
        <Box style={{ display: "flex", gap: "16px" }}>
          <img src={InfoIcon} alt="InfoIcon" />
          <Typography
            style={{
              color: "#15314E",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Your conversations are processed by human reviewers to improve this
            technologies powering ESG Wise. Do no enter anything you would’t
            want reviewed or used.
          </Typography>
        </Box>

        {/* disclaimer buttons */}
        <Box style={{ display: "flex", gap: "16px" }}>
          <Button
            style={{
              color: "#3374B9",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              textTransform: "none",
            }}
            onClick={toggleDialog}
          >
            How it Works
          </Button>
          <Button
            style={{
              color: "#3374B9",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              textTransform: "none",
            }}
            onClick={dismissButton}
          >
            Dismiss
          </Button>
        </Box>

        {/* dialog box */}
        <UpdatedDisclaimerDialog open={showDialog} onClose={toggleDialog} />
      </Box>
    )
  );
};
export default UpdatedDisclaimer;
