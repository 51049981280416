import { useDispatch, useSelector } from "react-redux";
import { getGptAnswer } from "../../../../redux/actions/gpt/esgGptAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function useChat() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const { newOrganization, selectedChat } = useSelector(
    (state) => state.esgGptReducer
  );
  const history = useHistory();
  const { location } = history;

  const is_company_based = location.pathname === "/esg_gpt/chat" ? false : true;

  const handleChats = (question, companyName) => {
    dispatch(
      getGptAnswer(
        login?.token,
        newOrganization?._id === undefined ? "" : newOrganization?._id,
        question,
        selectedChat?._id === undefined ? "" : selectedChat?._id,
        is_company_based,
        newOrganization?.isin,
        companyName
      )
    );
  };
  return { handleChats };
}

export default useChat;
