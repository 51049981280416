import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  addCompanyBrm,
  setCorporateAns,
} from "../../redux/actions/brm/BrmActions";
// import { getOrganizations } from "../../actions/surveyListing/surveyListingAction";
// import RiskAssessment from "../../components/widgets/riskAssessment/riskAssessment";
// import RiskSurvey from "../../components/widgets/riskAssessment/riskSurvey";
import CorporateSurvey from "../../components/widgets/corporateSuevey/corporateSurvey";
import {
  getAssignedQuestions,
  submitAnswer,
} from "../../redux/actions/corporateSurvey/corporateSurveyActions";

function CorporateAnsCont(props) {
  // useEffect(() => {
  //   props.getOrganizations(props.login.token, window.location.hostname);
  // }, [props.login.token]);

  return <CorporateSurvey {...props} />;
}

export const mapStateToProps = (store) => ({
  login: store.login,
  brmData: store.brmData,
  corporateSurvey: store.corporateSurvey,
  riskEvaluator: store.riskEvaluator,
});

export const mapDispatchToProps = (dispatch) => ({
  // getOrganizations: (payload, domain_name) => {
  //   dispatch(getOrganizations(payload, domain_name));
  // },
  setCorporateAns: (payload) => {
    dispatch(setCorporateAns(payload));
  },
  addCompanyBrm: (payload) => {
    dispatch(addCompanyBrm(payload));
  },
  submitAnswer: (token, organization_id,question_id,value,file) => {
    dispatch(submitAnswer(token, organization_id,question_id,value,file));
  },
  getAssignedQuestions: (token, organization_id) => {
    dispatch(getAssignedQuestions(token, organization_id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CorporateAnsCont);
