import React from "react";
import { connect } from "react-redux";
import {
  getCompanyData,
  getControversyLinks,
  getElasticOrganizations,
  publishInsights,
  publishLinks,
  regenerateInsights,
  setCompanyData,
  updateCompanyData,
  updateControversyType,
} from "../../redux/actions/riskdashboard/riskDashboardActions";
import RiskDocument from "../../components/widgets/riskDashboard/riskDocument";
import {
  getControversyData,
  getCompanyInsights,
} from "../../redux/actions/riskEvaluator/riskEvaluatorActions";

function RiskDocumentCont(props) {
  return <RiskDocument {...props} />;
}

export const mapStateToProps = (store) => ({
  login: store.login,
  riskDashboard: store.riskDashboard,
  controversyData: store.riskEvaluator.controversyData,
  riskEvaluator: store.riskEvaluator,
});

export const mapDispatchToProps = (dispatch) => ({
  getElasticOrganizations: (token, limit, starting_after, keyword) => {
    dispatch(getElasticOrganizations(token, limit, starting_after, keyword));
  },
  getCompanyData: (token, company_id) => {
    dispatch(getCompanyData(token, company_id));
  },
  getControversyLinks: (token, isin, limit, startingAfter) => {
    dispatch(getControversyLinks(token, isin, limit, startingAfter));
  },
  getControversyData: (token, isin) => {
    dispatch(getControversyData(token, isin));
  },
  updateCompanyData: (
    token,
    company_id,
    isin,
    year,
    answers,
    setAdminTabNo
  ) => {
    dispatch(
      updateCompanyData(token, company_id, isin, year, answers, setAdminTabNo)
    );
  },
  updateControversyType: (
    token,
    _id,
    controversy_type,
    isin,
    setCurrentLink,
    sentiment_label
  ) => {
    dispatch(
      updateControversyType(
        token,
        _id,
        controversy_type,
        isin,
        setCurrentLink,
        sentiment_label
      )
    );
  },
  publishLinks: (token, ids, isin, limit, startingAfter, year) => {
    dispatch(publishLinks(token, ids, isin, limit, startingAfter, year));
  },
  getCompanyInsights: (token, company_isin, year) => {
    dispatch(getCompanyInsights(token, company_isin, year));
  },
  regenerateInsights: (token, isin, company_id, year) => {
    dispatch(regenerateInsights(token, isin, company_id, year));
  },
  publishInsights: (token, isin, id, year) => {
    dispatch(publishInsights(token, isin, id, year));
  },
  setCompanyData: (payload) => {
    dispatch(setCompanyData(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RiskDocumentCont);
