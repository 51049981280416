import RadioButton from "../../UI/radioButton/radioButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { brmRightStyles } from "../riskAssessment/brmDataRight.js/brmRightStyle";
import CustomTextfield from "../../UI/textfield/textfield";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import CustomButton from "../../UI/button/button";
import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import ListIcon from "@material-ui/icons/List";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { SurveyListingCard } from "../riskAssessment/riskUtilComponents";

import plusIcon from "../../../images/plusIcon.svg";
import minusIcon from "../../../images/minusIcon.svg";
import { Question, Wrapper, survey } from "../selfAssessment/selfAssessmentResponse";
export const ViewSurveyDrawer = ({ viewListing, setViewListing }) => {
  const classes = brmRightStyles();
  let [list, setList] = useState([
    {
      name: "survey 1",
      created: "me",
      date: "Feb 1, 2020",
      selected: true,
    },
    {
      name: "survey 2",
      created: "Test",
      date: "Feb 12, 2021",
    },
  ]);
  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      onClose={() => setViewListing(false)}
      // variant="persistent"
      width="lg"
      open={viewListing}
      style={{ position: "relative" }}
    >
      <div style={{ width: "500px", position: "relative", padding: 8 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <Typography
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            Survey Listing
          </Typography>
          <Typography
            onClick={() => setViewListing(!viewListing)}
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            <CloseIcon style={{ cursor: "pointer", color: "#3374b9" }} />
          </Typography>
        </div>

        <CustomTextfield
          name="Search"
          variant="outlined"
          fullWidth
          size="small"
          type="text"
          label="Search"
          onChange={(e) => {
            // setSearchCompanyText(e.target.value);
            // onSearchHandler(e);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#3374B9" }} />
              </InputAdornment>
            ),
          }}
          // value={searchCompanyText}
        />
        <div
          style={{
            display: "flex",
            backgroundColor: "#EBF1F8",
            padding: "8px 10px",
            marginTop: 10,
          }}
        >
          <img
            src={plusIcon}
            alt="plusIcon"
            style={{ color: "#505050", width: 22 }}
          />
          <Typography
            style={{
              fontSize: "13px",
              fontWeight: 500,
              padding: 5,
            }}
          >
            Active (4)
          </Typography>
        </div>
        <SurveyListingCard member={{}} onClick={() => {}} />
        <SurveyListingCard member={{}} onClick={() => {}} />
        <SurveyListingCard member={{}} onClick={() => {}} />
        <div
          style={{
            display: "flex",
            backgroundColor: "#EBF1F8",
            padding: "8px 10px",
            marginTop: 10,
          }}
        >
          <img
            src={minusIcon}
            alt="minusIcon"
            style={{ color: "#505050", width: 22 }}
          />
          <Typography
            style={{
              fontSize: "13px",
              fontWeight: 500,
              padding: 5,
            }}
          >
            Inactive (5)
          </Typography>
        </div>
        {/*  <div
          style={{ display: "flex", backgroundColor: "#EBF1F8", padding: 5 }}
        >
          <div style={{ width: "33%" }}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                padding: 5,
              }}
            >
              Survey Name
            </Typography>
          </div>
          <div style={{ width: "33%" }}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                padding: 5,
              }}
            >
              Survey Created
            </Typography>
          </div>
          <div style={{ width: "33%" }}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                padding: 5,
              }}
            >
              Survey Dated
            </Typography>
          </div>
        </div>
        {list.map((v, i) => {
          return (
            <ListItem
              key={i + v.name}
              className={classes.onListHover}
              onClick={() => {
                setList((prev) => {
                  let copy = [...prev];
                  console.log(copy);
                  copy[i].selected = !copy[i].selected;
                  return copy;
                });
              }}
              style={{
                padding: 5,
                gap: 5,
                backgroundColor: !v.selected ? "" : "#EBF1F8",
              }}
            >
              <div
                style={{
                  width: "33%",
                  display: "flex",
                  alignItems: "center",
                  selected: true,
                }}
              >
                <CustomCheckBox checked={v?.selected} />
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    padding: 5,
                  }}
                >
                  {v.name}
                </Typography>
              </div>
              <div style={{ width: "33%" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    padding: 5,
                  }}
                >
                  {v.created}
                </Typography>
              </div>
              <div style={{ width: "33%" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    padding: 5,
                  }}
                >
                  {v.date}
                </Typography>
              </div>
            </ListItem>
          );
        })} */}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 5,
          right: 5,
        }}
      >
        <Button
          style={{
            textTransform: "none",
            color: "#3374B9",
          }}
          onClick={() => setViewListing(!viewListing)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            backgroundColor: "#3374B9",
            color: "white",
          }}
          onClick={() => setViewListing(!viewListing)}
        >
          Save & Continue
        </Button>
      </div>
    </Drawer>
  );
};

function ControverciesQuestions(props) {
  const { corporateSurveyQuestions, setShareDialogOpen } = props;
  const classes = brmRightStyles();

  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  const [questions, setQuestions] = useState(corporateSurveyQuestions || []);
  const [data, setData] = useState({
    type: "",
    question: "",
    options: [["", 0]],
  });
  const [value, setValue] = useState({});
  const handleChange = (event, id) => {
    setValue((preValue) => ({ ...preValue, [id]: event.target.value }));
  };

  const handleAddquestion = () => {
    const copy = [...questions];
    copy.push({
      order: questions.length + 1,
      category: "",
      type: data.type,
      question: data.question,
      answers: data.options,
    });
    setQuestions(copy);
    setIsAddQuestionOpen(false);
    setData({
      type: "",
      question: "",
      options: [["", 0]],
    });
  };

  const addQuestionDialog = () => {
    return (
      <Dialog fullWidth open={isAddQuestionOpen} minWidth="md" maxWidth="md">
        <DialogTitle>
          <Grid
            style={{ display: "flex", justifyContent: "space-between" }}
            container
            justifyContent="center"
            className={classes.mainGrid}
          >
            {/* <Grid item xs={11}> */}
            <Typography style={{ fontWeight: 500 }}>Add Question</Typography>
            {/* </Grid> */}
            {/* <Grid  item xs={1}> */}
            <CloseOutlinedIcon onClick={() => setIsAddQuestionOpen(false)} />
            {/* </Grid> */}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}></Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={12} className={classes.grid5}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  color: "#9A9A9A",
                }}
              >
                Type of Question
              </Typography>

              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={data.type}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, type: e.target.value };
                  });
                }}
              >
                {["radio Select", "textField"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid4}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  color: "#9A9A9A",
                }}
              >
                Enter Question
              </Typography>
              <CustomTextfield
                name="userName"
                label="Question"
                variant="outlined"
                size="small"
                fullWidth
                value={data.question}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, question: e.target.value };
                  });
                }}
              />
            </Grid>
            {/* {data.type !== "textField" && (
              <Grid item xs={12} md={12} className={classes.grid5}>
                <Typography>option 1</Typography>
                <CustomTextfield
                  name="userRole"
                  label="option"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={data.options}
                  onChange={(e) => {
                    setData((prev, j) => {
                      const opt =[...prev.options]
                      return {
                        ...prev,
                        options: opt.push([e.target.value, j + 1]),
                      };
                    });
                  }}
                />
              </Grid>

            )} */}
            {data.type !== "textField" &&
              data.options.map((option, index) => (
                <Grid item xs={12} md={12} className={classes.grid5}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#9A9A9A",
                    }}
                  >
                    option {`${index + 1}`}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CustomTextfield
                      name="userRole"
                      label="option"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={option[0]}
                      onChange={(e) => {
                        const copy = { ...data };
                        const copyOptions = [...copy.options];
                        copyOptions[index][0] = e.target.value;
                        setData(copy);
                      }}
                    />
                    <RemoveCircleOutlineOutlinedIcon
                      style={{
                        color: "#3374b9",
                        display: "inline",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const copy = { ...data };
                        copy?.options.splice(index, 1);
                        setData(copy);
                      }}
                    />
                  </div>
                </Grid>
              ))}

            {data.type !== "textField" && (
              <Button
                onClick={() => {
                  const copy = { ...data };
                  copy?.options.push(["", 1]);
                  setData(copy);
                }}
              >
                add option
              </Button>
            )}
            {/* <Grid item xs={12} md={12} className={classes.grid5}>
              <Typography>option 1</Typography>
              <CustomTextfield
                name="userRole"
                label="option"
                variant="outlined"
                size="small"
                fullWidth
                // value={role}
                // onChange={(e) => setRole(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            {/* <Grid item xs={12} md={12} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </Grid> */}

            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setIsAddQuestionOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={handleAddquestion}
                disabled={!data.question}
              >
                Add Question
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  const [viewListing, setViewListing] = useState(false);

  return (
    <div style={{ height: "63vh" }} className={classes.mainDiv}>
      {isAddQuestionOpen && addQuestionDialog()}
      <ViewSurveyDrawer
        viewListing={viewListing}
        setViewListing={setViewListing}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Controversies Survey Questions
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => setViewListing(!viewListing)}
          >
            <ListIcon color="#3374b9" /> View Survey Listing
          </Button>
          {/* <Button
            // variant="outlined"
            size="small"
            // startIcon={<Add />}
            // color="primary"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => setIsAddQuestionOpen(!isAddQuestionOpen)}
          >
            Add Question
          </Button> */}
          <Icon style={{ color: "#3374b9", cursor: "pointer" }}>
            <ShareOutlinedIcon onClick={() => setShareDialogOpen(true)} />
          </Icon>
        </div>
      </div>
      <div className="scroll" style={{ height: "61vh" }}>
      <Wrapper users={survey} hidesearch>
          <div className="scroll" style={{ width: "78%", height: "59vh" }}>
            {questions.map((question) => (
              <Question
                question={question.question}
                options={question.answers}
                type={question.type}
                comment={question?.comment}
              />
            ))}
          </div>
        </Wrapper>
        {/* {questions.map((que, i) => {
          return (
            <div key={i}>
              <br />
              <Grid container>
                <Grid item md={1}>
                  <Typography className={classes.qno}>Q{i + 1}.</Typography>
                </Grid>
                <Grid item md={11}>
                  <Typography>{que?.question}</Typography>
                  <FormControl on component="fieldset">
                    <RadioGroup
                      value={value[que?.order]}
                      onChange={(e) => handleChange(e, que?.order)}
                      name={que?._id + ""}
                    >
                      {que?.answers &&
                        que?.answers.map((ans, j) => {
                          return (
                            <div key={j} style={{ width: "100%" }}>
                              <FormControlLabel
                                style={{ width: "100%" }}
                                value={ans[0]}
                                control={
                                  que.type === "textField" ? (
                                    <CustomTextfield
                                      style={{
                                        width: "50vw",
                                        padding: "15px 0px",
                                      }}
                                      name="userName"
                                      label="Answer"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      // value={data.question}
                                    />
                                  ) : (
                                    // <TextField
                                    //   // className={clsx(classes.root, classes.checked)}
                                    //   onChange={(e) => {
                                    //     // checkBoxChange(e, i, j);
                                    //   }}
                                    //   name={que.id + ""}
                                    //   id={ans[1] + ""}
                                    // />
                                    <RadioButton id={ans[0] + ""} />
                                    //  <FormControlLabel value="female" control={<Radio />} label="Female" />
                                  )
                                }
                                label={ans[0]}
                                // onChange={(e) => {
                                //   addSurvey(e, i);
                                // }}
                              />
                            </div>
                          );
                        })}
                    </RadioGroup>
                  </FormControl>
                  <hr></hr>
                </Grid>
              </Grid>
            </div>
          );
        })} */}
        <br /> <br />
      </div>
    </div>
  );
}

export default ControverciesQuestions;
