import React, { useState } from "react";

import {
  AppBar,
  Button,
  Drawer,
  FormLabel,
  MenuItem,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import TabPanel from "../../../UI/tabPanel/tabPanel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextfield from "../../../UI/textfield/textfield";
import { appTheme } from "../brmDetaails";
import SearchIcon from "@material-ui/icons/Search";
import { UserCard } from "../riskUtilComponents";
import { useDispatch, useSelector } from "react-redux";
import { assignUserSurvey } from "../../../../redux/actions/brm/BrmActions";

import { brmRightStyles } from "./brmRightStyle";

export default function ShareDialog({
  shareDialogOpen,
  handleClosShareDialog,
}) {
  const classes = brmRightStyles();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state?.login);
  const { organizationDetails, members } = useSelector(
    (state) => state?.brmData
  );

  const [shareTabNo, setShareTabNo] = useState(0);
  const [userIds, setUserIds] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState({
    date: "",
    time: "",
  });
  const [surveyReminder, setSurveyReminder] = React.useState(1);
  const [category, setCategory] = React.useState("");

  const handleDateChange = (key, value) => {
    setSelectedDate((preValue) => ({ ...preValue, [key]: value }));
  };
  const handleStateReset = () => {
    setShareTabNo(0);
    setUserIds([]);
    setSearchText("");
    setSelectedDate({ date: "", time: "" });
    setCategory("");
    handleClosShareDialog();
  };

  const handleShare = () => {
    dispatch(
      assignUserSurvey(
        token,
        userIds,

        organizationDetails[0]?._id,
        category,
        selectedDate?.date,
        selectedDate?.time,
        surveyReminder
      )
    );
    handleStateReset();
  };

  const handleCheckboxChange = (id) => {
    const isSelected = userIds.includes(id);

    if (!isSelected) {
      setUserIds([...userIds, id]);
    } else {
      setUserIds(userIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleDisableButton = category !== "" && selectedDate?.date !== "";

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      onClose={() => handleStateReset()}
      // variant="persistent"
      width="lg"
      open={shareDialogOpen}
      style={{ position: "relative" }}
    >
      <div style={{ width: "550px", position: "relative", padding: 8 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "fit-content",
          }}
        >
          <Typography
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            Share Survey
          </Typography>
          <Typography
            onClick={() => handleStateReset()}
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            <CloseIcon style={{ cursor: "pointer", color: "#3374b9" }} />
          </Typography>
        </div>
        <MuiThemeProvider theme={appTheme}>
          <AppBar position="static" color="inherit" className={classes.AppBar}>
            <Tabs
              value={shareTabNo}
              onChange={(e, newVal) => {
                setShareTabNo(newVal);
              }}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Participants</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Timing</span>}
                disabled={userIds.length === 0 ? true : false}
              />
            </Tabs>
          </AppBar>
        </MuiThemeProvider>

        <TabPanel value={shareTabNo} index={0}>
          <div className="scroll" style={{ marginTop: "10%", height: "70vh" }}>
            <CustomTextfield
              style={{ marginTop: "10px" }}
              name="Search"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="Search"
              onChange={(e) => {
                // setSearchCompanyText(e.target.value);
                // onSearchHandler(e);
                setSearchText(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: "#3374B9" }} />
                  </InputAdornment>
                ),
              }}
              // value={searchCompanyText}
            />

            {members
              .filter(
                (m) =>
                  m.name.includes(searchText) || m.email.includes(searchText)
              )
              .map((member) => (
                <UserCard
                  key={member?._id}
                  member={{
                    name: member?.name,
                    email: member?.email,
                    role: member?.role,
                    surveySentStatus: member?.survey_sent,
                  }}
                  onClick={() => handleCheckboxChange(member?._id)}
                />
              ))}
          </div>
        </TabPanel>
        <TabPanel value={shareTabNo} index={1}>
          <div
            className="scroll"
            style={{ marginTop: 18, height: "60vh", paddingTop: 5 }}
          >
            <FormLabel style={{ fontSize: 12, marginBottom: "10px" }}>
              Survey Frequency
            </FormLabel>
            <CustomTextfield
              name="userCategory"
              label="Choose the required frequency"
              variant="outlined"
              size="small"
              fullWidth
              select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              style={{ marginTop: "20px" }}
            >
              {[
                { title: "Every 1 month (Every 30 days)", value: "Monthly" },
                { title: "Every 3 months (Quarterly)", value: "Quarterly" },
                {
                  title: "Every 6 months (Half yearly)",
                  value: "Half Yearly",
                },
                { title: "Every 1 Year", value: "Yearly" },
              ].map((cat) => (
                <MenuItem key={cat} value={cat?.value}>
                  {cat?.title}
                </MenuItem>
              ))}
            </CustomTextfield>
            <div style={{ display: "flex", margin: "12px 0px", gap: 8 }}>
              <div>
                <FormLabel style={{ fontSize: 12, marginBottom: "5px" }}>
                  Date in which the survey will be sent
                </FormLabel>
                <CustomTextfield
                  style={{ marginTop: "20px" }}
                  name="date"
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Pick your date"
                  onChange={(e) => {
                    // setSearchCompanyText(e.target.value);
                    // onSearchHandler(e);
                    handleDateChange(e.target.name, e.target.value);
                  }}
                  id="date"
                  type="date"
                  value={selectedDate?.date}
                  // defaultValue="2017-05-24"
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <FormLabel style={{ fontSize: 12, marginBottom: "15px" }}>
                  Time in which the survey will be sent
                </FormLabel>
                <CustomTextfield
                  style={{ marginTop: "20px" }}
                  name="time"
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Pick your time"
                  onChange={(e) => {
                    // setSearchCompanyText(e.target.value);
                    // onSearchHandler(e);
                    handleDateChange(e.target.name, e.target.value);
                  }}
                  id="time"
                  type="time"
                  // defaultValue="07:30"
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  value={selectedDate?.time}
                />
              </div>
            </div>
            <FormLabel style={{ fontSize: 12 }}>
              How many reminders should be sent ? (max 3)
            </FormLabel>
            <CustomTextfield
              name="Search"
              variant="outlined"
              fullWidth
              select
              size="small"
              style={{ marginTop: "20px" }}
              onChange={(e) => {
                // setSearchCompanyText(e.target.value);
                // onSearchHandler(e);
                setSurveyReminder(e.target.value);
              }}
              value={surveyReminder}
            >
              {[
                { title: "1", value: 1 },
                { title: "2", value: 2 },
                {
                  title: "3",
                  value: 3,
                },
              ].map((cat) => (
                <MenuItem key={cat} value={cat?.value}>
                  {cat?.title}
                </MenuItem>
              ))}
            </CustomTextfield>
          </div>
        </TabPanel>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 5,
          right: 5,
        }}
      >
        <Button
          style={{
            textTransform: "none",
            color: "#3374B9",
          }}
          onClick={() => handleStateReset()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          // style={{
          //   textTransform: "none",
          //   backgroundColor: "#3374B9",
          //   color: "white",
          // }}
          onClick={() => (shareTabNo === 0 ? setShareTabNo(1) : handleShare())}
          disabled={
            shareTabNo === 0
              ? userIds.length > 0
                ? false
                : true
              : !handleDisableButton
          }
        >
          Save & Continue
        </Button>
      </div>
    </Drawer>
  );
}
