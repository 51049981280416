import React from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const appTheme = createTheme({
  palette: {
    secondary: {
      main: "#3374B9",
    },
  },
});

export default function EsgGptDrawerTabs() {
  const useStyles = makeStyles((theme) => ({
    root: {
      // flexGrow: 1,
      borderBottom: `1px solid ${theme.palette.background.paper}`,
    },
    palette: {
      
      secondary: {
        main: "#47BB76",
      },
    },
    AppBar: { boxShadow: "none",},

    tab: {
      fontSize: 13,
      fontWeight: 500,
      minWidth: "30px",
      textTransform: "capitalize",
      padding: '6px 10px',
      color:"#76A2D0",
      "&.Mui-selected": {
        backgroundColor: "#FEFEFE",
        color:"#15314E"
      },
    },
    tabs: {
      minWidth: "400px",
      minHeight:'25px'
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const { location } = history;

  const tabValue = location.pathname === "/esg_gpt/chat" ? 1 : 0;

  const [value, setValue] = React.useState(tabValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRoute = (newPath) => {
    let path = newPath;
    history.push(path);
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <AppBar position="static" color="transparent" className={classes.AppBar} >
        <Tabs className={classes.tabs} value={value} onChange={handleChange}>
          <Tab
            className={classes.tab}
            onClick={() => handleChangeRoute("/esg_gpt/company_profiles")}
            label={<span className={classes.tabLabel}>Companies</span>}
            data-tut='companies-tab'
          />
          <Tab
            className={classes.tab}
            onClick={() => handleChangeRoute("/esg_gpt/chat")}
            label={<span className={classes.tabLabel}>Chats</span>}
            data-tut='chat-tab'
          />
        </Tabs>
      </AppBar>
     </MuiThemeProvider>
  );
}
