import React, { useEffect, useRef, useState } from "react";
import RadioButton from "../../UI/radioButton/radioButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { brmRightStyles } from "../riskAssessment/brmDataRight.js/brmRightStyle";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import useGeoLocation from "../../../customHooks/getGeoLocation";
import GoogleMaps from "./googleMaps";
import { ViewSurveyDrawer } from "../controverciesQuestions/controverciesQuestions";
import ListIcon from "@material-ui/icons/List";
import {
  Question,
  Wrapper,
  survey,
} from "../selfAssessment/selfAssessmentResponse";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestion,
  getSurveySectionQuestion,
  setSelectedSection,
} from "../../../redux/actions/riskdashboard/riskDashboardActions";
import useInfiniteScroll from "../../../customHooks/useInfiniteScroll";

function SurveyQuestions(props) {
  const {
    getSurveyeQuestions,
    addSurveyeQuestions,
    login,
    brmData,
    setShareDialogOpen,
    corporateSurveyQuestions,
  } = props;
  const classes = brmRightStyles();
  const dispatch = useDispatch();
  const scroll = useRef();
  const scrollDivRef = useRef();

  const { loading, resetHookState } = useInfiniteScroll(scrollDivRef);
  const { sectionWiseData, selectedSection, sectionWiseQuestion } = useSelector(
    (state) => state.riskDashboard
  );

  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  const [questions, setQuestions] = useState(brmData?.surveyQuestions);
  const [controversiesQuestions, setControversiesQuestions] = useState(
    corporateSurveyQuestions || []
  );
  const [viewListing, setViewListing] = useState(false);
  const [selected, setSelected] = useState(0);
  const [data, setData] = useState({
    type: "",
    question: "",
    options: [["", 0]],
  });
  const [value, setValue] = React.useState({});

  const location = useGeoLocation();


  useEffect(() => {
    dispatch(getSurveySectionQuestion(login.token, false, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   sectionWiseData.length > 0 &&
  //     dispatch(
  //       getQuestion(
  //         login?.token,
  //         sectionWiseData.find((s) => s.title === selectedSection)?._id,
  //         5,
  //         0,
  //         sectionWiseQuestion
  //       )
  //     );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedSection, sectionWiseData.length]);

  useEffect(() => {
    getSurveyeQuestions(
      login.token,
      brmData?.organizationDetails[0]?._id ||
        localStorage.getItem("organization_id")
    );
    setControversiesQuestions(corporateSurveyQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // scroll?.current?.scrollTo(0, 0);
  //   resetHookState();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selected]);
  useEffect(() => {
    // scroll?.current?.scrollTo(0, 0);
    return () => {
      resetHookState();
      dispatch(setSelectedSection("Self Assessment Section"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (event, id) => {
    setValue((preValue) => ({ ...preValue, [id]: event.target.value }));
  };

  const handleAddquestion = () => {
    let question = {
      order: brmData?.surveyQuestions.length + 1,
      category: "custom question",
      type: data.type,
      question: data.question,
      answers: data.options,
    };

    addSurveyeQuestions(
      login.token,
      brmData?.organizationDetails[0]?._id ||
        localStorage.getItem("organization_id"),
      question
    );
    // setQuestions(copy);
    setIsAddQuestionOpen(false);
    setData({
      type: "",
      question: "",
      options: [["", 0]],
    });
  };

  const addQuestionDialog = () => {
    return (
      <Dialog fullWidth open={isAddQuestionOpen} minWidth="md" maxWidth="md">
        <DialogTitle>
          <Grid
            style={{ display: "flex", justifyContent: "space-between" }}
            container
            justifyContent="center"
            className={classes.mainGrid}
          >
            <Typography style={{ fontWeight: 500 }}>Add Question</Typography>
            <CloseOutlinedIcon onClick={() => setIsAddQuestionOpen(false)} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}></Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={12} className={classes.grid5}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  color: "#9A9A9A",
                }}
              >
                Type of Question
              </Typography>

              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={data.type}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, type: e.target.value };
                  });
                }}
              >
                {[
                  "radio",
                  "textField",
                  "multi select",
                  "file",
                  "location",
                  "date",
                ].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid4}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  color: "#9A9A9A",
                }}
              >
                Enter Question
              </Typography>
              <CustomTextfield
                name="userName"
                label="Question"
                variant="outlined"
                size="small"
                fullWidth
                value={data.question}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, question: e.target.value };
                  });
                }}
              />
            </Grid>

            {(!data.type ||
              data.type === "radio" ||
              data.type === "multi select") &&
              data.options.map((option, index) => (
                <Grid item xs={12} md={12} className={classes.grid5}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#9A9A9A",
                    }}
                  >
                    option {`${index + 1}`}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CustomTextfield
                      name="userRole"
                      label="option"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={option[0]}
                      onChange={(e) => {
                        const copy = { ...data };
                        const copyOptions = [...copy.options];
                        copyOptions[index][0] = e.target.value;
                        setData(copy);
                      }}
                    />
                    <RemoveCircleOutlineOutlinedIcon
                      style={{
                        color: "#3374b9",
                        display: "inline",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const copy = { ...data };
                        copy?.options.splice(index, 1);
                        setData(copy);
                      }}
                    />
                  </div>
                </Grid>
              ))}

            {(!data.type ||
              data.type === "radio" ||
              data.type === "multi select") && (
              <Button
                onClick={() => {
                  const copy = { ...data };
                  copy?.options.push(["", copy.options.length]);
                  setData(copy);
                }}
              >
                add option
              </Button>
            )}

            {data.type === "file" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Select File
                </Typography>
                <CustomTextfield
                  name="userName"
                  variant="outlined"
                  type="file"
                  size="small"
                  fullWidth
                  value={""}
                />
              </Grid>
            )}
            {data.type === "date" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Select File
                </Typography>
                <CustomTextfield
                  name="userName"
                  variant="outlined"
                  type="date"
                  size="small"
                  fullWidth
                  value={""}
                />
              </Grid>
            )}
            {data.type === "location" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Grant permission to access location
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  latitude: {location.coords.latitude}, longitude:{" "}
                  {location.coords.longitude}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>

            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setIsAddQuestionOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={handleAddquestion}
                disabled={!data.question}
              >
                Add Question
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };


  return (
    <div style={{ height: "63vh" }} className={classes.mainDiv}>
      {isAddQuestionOpen && addQuestionDialog()}
      <ViewSurveyDrawer
        viewListing={viewListing}
        setViewListing={setViewListing}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Survey
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon style={{ color: "#3374b9", cursor: "pointer" }}>
            <ShareOutlinedIcon onClick={() => setShareDialogOpen(true)} />
          </Icon>
        </div>
      </div>
      {/* <div ref={scrollDivRef}> */}
      {sectionWiseData.length > 0 && (
        <Wrapper
          users={sectionWiseData}
          selected={selected}
          setSelected={setSelected}
          hidesearch
          handleUserClick={(section, id) => {
            resetHookState();

            dispatch(setSelectedSection(section?.title));
          }}
        >
          <div
            className="scroll"
            ref={scrollDivRef}
            style={{ width: "78%", height: "59vh" }}
          >
            {/* {selected === 0 && ( */}
            <div style={{ pointerEvents: "none" }}>
              <BlueBackgroundCard
                heading={selectedSection}
                subHeading="Empower stakeholders with a personalized journey through our Self Assessment survey, fostering individual reflection on ESG principles and practices."
              />
              {sectionWiseQuestion.map((question) => (
                <Question
                  key={question?._id}
                  question={question.question}
                  options={question.answers}
                  type={question.question_type}
                  weightage={question?.weightage}
                  removeEditOption={true}
                  comment={question?.comment}
                />
              ))}
            </div>
          </div>
        </Wrapper>
      )}
      {/* </div> */}
      <br /> <br />
    </div>
  );
}

export default SurveyQuestions;
