import React from "react";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptChatField from "../EsgGptChatField";

import { ModifiedList } from "../esgGptAddNewCompany/EsgGptUpdatedList";
import analytics from "../../../../images/Analytics.png";
import { ChatPlayGround } from "../esgGptCompanyProfile/EsgGptCompanyRight";
import { useSelector } from "react-redux";
import GptTour, {
  TourContent,
  tourStyle,
} from "../../../screen/gptTour/GptTour";

const chatSteps = [
  {
    selector: '[data-tut="chat-tab"]',
    content: () => (
      <TourContent
        heading={"Chats Tab"}
        content={
          "Engage in real-time conversations with our intelligent assistant, gaining instant clarity and deeper understanding of ESG intricacies through personalized chats tailored to your queries."
        }
      />
    ),
    style: tourStyle,
  },
  {
    selector: '[data-tut="user-profile"]',
    content: () => (
      <TourContent
        heading={"User Profile"}
        content={
          "Utilize this tab for updating your personal details and logging out as needed."
        }
      />
    ),
    style: tourStyle,
  },
  {
    selector: '[data-tut="token"]',
    content: () => (
      <TourContent
        heading={"Tokens"}
        content={
          "Each token serves as a question, allowing you to inquire about companies' ESG practices or any other information with our chatbot."
        }
        token={true}
      />
    ),
    style: tourStyle,
  },
  // {
  //   selector: '[data-tut="setting"]',
  //   content: () => (
  //     <TourContent
  //       heading={"Settings"}
  //       content={
  //         "Fine-tune your ESG Wise experience with precision through our Settings hub—customize preferences, manage notifications, and tailor the platform to align seamlessly with your sustainability objectives."
  //       }
  //     />
  //   ),
  //   style: tourStyle,
  // },
  // {
  //   selector: '[data-tut="help"]',
  //   content: () => (
  //     <TourContent
  //       heading={"Help"}
  //       content={
  //         "If you encounter any challenges, please don't hesitate to contact us through the Help Center."
  //       }
  //     />
  //   ),
  //   style: tourStyle,
  // },
];

function EsgGptChat(props) {
  const { selectedChat } = useSelector((state) => state.esgGptReducer);
  const [chatInitiate, setChatInitiate] = React.useState(false);
  const handleChat = () => {
    setChatInitiate(true);
  };
  const isChatTourCompleted = JSON.parse(
    localStorage.getItem("chat_tour_completed")
  );
  return (
    <>
      {!isChatTourCompleted && <GptTour steps={chatSteps} resource={"chat"} />}
      <EsgGptlayout
        EsgCompanyList={<ModifiedList />}
        login={props.login}
        logOut={props.logOut}
      >
        <div
          style={{
            height: "88%",
            width: "100%",
            overflow: "scroll",
            // marginBottom: "1%",
          }}
        >
          {Object.keys(selectedChat).length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "70%",
              }}
            >
              <img src={analytics} alt="analytics" />
            </div>
          )}
          <div onClick={handleChat}>
            <ChatPlayGround />
          </div>
        </div>

        <EsgGptChatField />
      </EsgGptlayout>
    </>
  );
}

export default EsgGptChat;
