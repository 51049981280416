import React from "react";
import { Typography } from "@material-ui/core";

function CustomCompaniesRange(props) {
  const {
    lowerRange,
    upperRange,
    score,
    companyScores,
    fullwidth,
    peer_companies,
    hideHeading,
    is_premium = true
  } = props;
  let mainScore = score || 0;
  const colors = [
    "#6AC346",
    "#F04187",
    "#FF7675",
    "#FF6A00",
    "#8246AF",
    "#5b8ff9",
    "#5ad8a6",
    "#5e7092",
    "#f6bd18",
    "#6f5efa",
    "#6ec8ec",
    "#945fb9",
    "#ff9845",
    "#299796",
    "#fe99c3",
    "#d7e3fd",
    "#daf5e9",
    "#d6dbe4",
    "#fdeecd",
    "#dad8fe",
    "#dbf1fa",
    "#e4d7ed",
    "#ffe5d2",
    "#cce5e4",
    "#ffe6f0",
  ];
  const companies = [
    "company1",
    "company2",
    "company3",
    "company4",
    "company5",
  ];
  // console.log("peer_companies", peer_companies);
  return (
    <div id="chartLenovo">
      <div style={{ border: "none", height: "34vh", position: "relative", filter: is_premium ? 'blur(0px)' : 'blur(5px)' }}>
        {!hideHeading && (
          <Typography
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: 14,
              marginTop: "10px",
            }}
          >
            Peer Group Range
          </Typography>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: "4%",
            alignItems: "center",
          }}
        >
          {peer_companies &&
            peer_companies.map(({ company_name }, i) => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  // marginLeft: "4%",
                  alignItems: "center",
                }}
                key={i}
              >
                <span
                  style={{
                    backgroundColor: colors[i],
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    margin: "0px 2px 0px 8px",
                  }}
                ></span>
                <Typography style={{ fontSize: 12 }}>{company_name}</Typography>
              </div>
            ))}
        </div>

        <div
          style={{
            borderRight: "3px dashed #5198E0",
            height: "50%",
            width: "1px",
            // marginLeft: "5%",
            position: "relative",
            top: "4%",
            right: "4px",
            // padding: "0px 1px",
            backgroundColor: "white",
            color: "#5198E0",
            zIndex: 1,
            //pass the score value here
            marginLeft: `${4 + 0.9 * mainScore}% `,
            display: "flex",
            justifyContent: "center",
          }}
        ></div>

        <div
          style={{
            borderLeft: "3px dashed #5198E0",
            height: "50%",
            width: "1px",
            // marginLeft: "5%",
            position: "relative",
            top: "-46%",
            // padding: "0px 1px",
            backgroundColor: "white",
            color: "#5198E0",
            zIndex: 1,
            //pass the score value here
            marginLeft: `${4 + 0.9 * mainScore}% `,
            display: "flex",
            justifyContent: "center",
          }}
        ></div>

        {/* ///////////////////////////// */}
        <div
          style={{
            borderLeft: "2px solid #5198E0",
            height: "16%",
            width: "1px",
            // marginLeft: "5%",
            position: "relative",
            top: "-48%",
            // padding: "0px 1px",
            backgroundColor: "white",
            zIndex: 1,
            //pass the score value here
            marginLeft: `${4 + 0.9 * mainScore}% `,
          }}
        >
          <span
            style={{
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#5198E0",
              position: "absolute",
              bottom: 0,
              marginLeft: "2px",
            }}
          >
            <Typography
              style={{ color: "white", fontSize: "12px", padding: "1px 6px" }}
            >
              {`${mainScore}%`}
            </Typography>
          </span>
        </div>

        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            top: "-112%",
          }}
        >
          <div
            style={{
              border: "1px solid lightgrey",
              // borderLeft: "1px solid lightgrey",
              // borderRight: "1px solid lightgrey",
              // borderBottom: "1px solid lightgrey",
              height: "25%",
              margin: "0% 4% 0% 5%",
            }}
          ></div>

          <div
            style={{
              display: "flex",
              borderLeft: "1px solid lightgrey",
              borderRight: "1px solid lightgrey",
              borderBottom: "1px solid lightgrey",
              height: "25%",
              margin: "0% 4% 0% 5%",
              position: "relative",
            }}
          >
            {/* <div
                style={{
                  borderRight: "3px solid red",
                  height: "200%",
                  width: "1px",
                  // marginLeft: "5%",
                  position: "relative",
                  top: "-100%",
                  padding: "0px 2px",
                  backgroundColor: "white",
                  color: "#5198E0",
                  zIndex: 100,
                  //pass the score value here
                  left: `${score -1}% `,
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div> */}

            {peer_companies &&
              peer_companies.map(({ score }, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      borderRight: `4px solid ${colors[i]}`,
                      height: "200%",
                      width: "1px",
                      // marginLeft: "5%",
                      position: "absolute",
                      top: "-100%",
                      // padding: "0px 1px",
                      backgroundColor: "white",
                      color: "#5198E0",
                      zIndex: 100,
                      //pass the score value here make the othe number to 0.3 if used fullwidth
                      left: `${fullwidth ? score - 0.3 : score - 2}% `,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  ></div>
                );
              })}

            {/* {companyScores.map((score, i) => (
              <div
                style={{
                  borderLeft: "2px solid red",
                  height: "50%",
                  width: "1px",
                  // marginLeft: "5%",
                  position: "relative",
                  top: "-46%",
                  // padding: "0px 2px",
                  backgroundColor: "white",
                  color: "#5198E0",
                  zIndex: 1,
                  //pass the score value here
                  left: `${score - 0.3}% `,
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>
            ))} */}
          </div>
          <div
            style={{
              zIndex: 0,
              border: "1px dashed grey",
              // backgroundColor: "#8246AF",
              opacity: 1,
              height: "50%",
              margin: "0% 5%",
              position: "relative",
              //pass the value range from left
              marginLeft: `${4 + 0.9 * lowerRange}% `,
              //pass the value range from right
              marginRight: `${5 + 0.9 * (100 - upperRange)}% `,
              bottom: "51.1%",
            }}
          >
            {/* //new */}
            {/* <div
              style={{
                borderRight: "2px dashed #5198E0",
                height: "50%",
                width: "1px",
                // marginLeft: "5%",
                position: "relative",
                top: "4%",
                right: "4px",
                padding: "0px 2px",
                backgroundColor: "white",
                color: "#5198E0",
                zIndex: 1,
                //pass the score value here
                marginLeft: `${4 + 0.9 * 50}% `,
                display: "flex",
                justifyContent: "center",
              }}
            ></div> */}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0% 2% 0% 4%",
              position: "relative",
              bottom: "30%",
            }}
          >
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              0%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              10%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              20%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              30%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              40%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              50%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              60%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              70%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              80%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              90%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              100%
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomCompaniesRange;
