import React from 'react';
import { connect } from 'react-redux';
import Router from '../../components/router/router';
import { logOut } from '../../redux/actions/login/loginAction';
// import {
//   getCompanyLogo,
// } from '../../actions/login/loginActions';
// import { set_snack_bar } from '../../actions/snackbar/snackbar_action';


class RouterCon extends React.PureComponent {
  render() {
    return <Router {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  brmData:store.brmData,
  login:store.login
});

export const mapDispatchToProps = (dispatch) => ({
  // getCompanyLogo: (domain) => {
  //   dispatch(getCompanyLogo(domain));
  // },
  logOut: (payload,isDisclaimer) => {
    dispatch(logOut(payload,isDisclaimer));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterCon);
