import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const UpdatedDisclaimerDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{ backgroundColor: "#EBF1F8", padding: "24px 20px 48px 20px" }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              color: "#15314E",
              fontWeight: "600",
              fontSize: "22px",
              lineHeight: "28px",
            }}
          >
            How It Works
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ color: "#15314E" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box style={{ gap: "16px", padding: "20px" }}>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "10px",
              color: "#242424",
            }}
          >
            Just by having a conversation with ESG WISE, youre making our
            services better, including the machine-learning models that power
            our system.
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "10px",
              color: "#242424",
            }}
          >
            As a part that improvement, trained reviewers will need to process
            your conversation.
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "10px",
              color: "#242424",
            }}
          >
            So when using ESG WISE, please be mindful to not enter anything that
            you wouldn't want a reviewer to see or ESG WISE to use.
          </Typography>
          {/* <Typography
            style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "10px",
              color: "#242424",
            }}
          >
            Your Google Workspace content, like from Gmail or Drive, is not
            reviewed or used to improve ESG Wise.
          </Typography>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "10px",
              color: "#242424",
            }}
          >
            You can turn ESG Wise Activity off
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#242424",
            }}
          >
            If you don’t want future conversations reviewed or used to improve
            machine-learning models, turn off ESG Wise ActivityOpens in a new
            window.
          </Typography> */}
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button
          style={{
            color: "#3374B9",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          Learn More
        </Button> */}
        <Button
          style={{
            color: "#3374B9",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
          }}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatedDisclaimerDialog;
