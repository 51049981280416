import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  MenuItem,
  MuiThemeProvider,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { useHistory } from "react-router-dom";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "../../UI/button/button";
import TabPanel from "../../UI/tabPanel/tabPanel";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";
import { appTheme } from "../riskAssessment/brmDetaails";
import OverallProfile from "../esgCompanyProfile/overallProfile";
import RefreshIcon from "@material-ui/icons/Refresh";
import PublishIcon from "@material-ui/icons/Publish";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useDispatch } from "react-redux";
import {
  publish_company,
  searchArticles,
} from "../../../redux/actions/riskdashboard/riskDashboardActions";

const negativeControversies = {
  "Anti-competition controversy": 0,
  "Business ethics controversies": 0,
  "Intellectual property controversies": 0,
  "Critical countries controversies": 0,
  "Public health controversies": 0,
  "Tax fraud controversies": 0,
  "Child labour controversies": 0,
  "Human rights controversies": 0,
  "Management compensation controversies count": 0,
  "Consumer controversies": 0,
  "Customer health and safety controversies": 0,
  "Privacy controversies": 0,
  "Product access controversies": 0,
  "Responsible marketing controversies": 0,
  "Responsible R&D controversies": 0,
  "Environmental controversies": 0,
  "Accounting controversies count": 0,
  "Insider dealings controversies": 0,
  "Shareholder rights controversies": 0,
  "Diversity and opportunity controversies": 0,
  "Employee health and safety controversies": 0,
  "Wages or working conditions controversies": 0,
  Strikes: 0,
  "Management compensation controversies": 0,
};

const positiveControversies = {
  "Environmental Sustainability Initiatives": 0,
  "Carbon Emissions Reduction Efforts": 0,
  "Sustainable Sourcing Practices": 0,
  "Waste Reduction and Recycling Programs": 0,
  "Community Engagement and Philanthropy": 0,
  "Employee Diversity and Inclusion Programs": 0,
  "Fair Labor Practices and Employee Benefits": 0,
  "Ethical Supply Chain Management": 0,
  "Transparency and Accountability in Governance": 0,
  "Board Diversity and Independence": 0,
  "Responsible Corporate Citizenship": 0,
  "Stakeholder Engagement and Dialogue": 0,
  "Innovation in Sustainable Technologies": 0,
  "Social Impact Investing and Financing": 0,
  "Climate Change Adaptation Strategies": 0,
  "Water Conservation and Management Practices": 0,
  "Biodiversity Conservation Efforts": 0,
  "Product Safety and Quality Assurance": 0,
  "Health and Wellness Programs for Employees and Customers": 0,
};

function RiskDocument(props) {
  const {
    login,
    riskDashboard,
    updateCompanyData,
    getCompanyData,
    getControversyLinks,
    getControversyData,
    updateControversyType,
    controversyData,
    publishLinks,
    publishInsights,
    regenerateInsights,
    riskEvaluator,
    setCompanyData,
    location,
  } = props;
  const { state } = location;
  const dispatch = useDispatch();

  console.log(controversyData);

  let limit = 5;
  const history = useHistory();
  const [answers, setAnswers] = useState({});
  const [isControversyOpen, setIsControversyOpen] = useState(false);
  const [currentControversy, setCurrentControversy] = useState(false);
  const [kpiList, setKpiList] = useState(
    riskDashboard?.currentCompanyData || {}
  );
  const [pages, setPages] = useState(null);
  const [startingAfter, setStartingAfter] = useState(0);
  const [linksList, setLinksList] = useState([]);

  // 54 ,

  const classes = appbarStyles();

  const [adminTabNo, setAdminTabNo] = useState(0);
  var result = { ...answers }; //<- copy roomRent into result
  const handleInputChange = (key, value, i) => {
    // setKpiList((prev) => {
    //   return {
    //     ...prev,
    //     [key]: [prev[key][0], value],
    //   };
    // });

    result[key] = returnType(key) === "number" ? Number(value) : value;

    // setAnswers(result);
    // result = result.map((x) => { //<- use map on result to find element to update using id
    //   if (x.id === id) x.room = value;
    //   return x;
    // });

    // setAnswers({
    //   ...answers,
    //   [key]: returnType(key) === "number" ? Number(value) : value,
    // });
  };

  const returnType = (key) => {
    return key.toLowerCase().includes("percentage")
      ? "number"
      : key.toLowerCase().includes("rate") ||
        key.toLowerCase().includes("per") ||
        key.toLowerCase().includes("number") ||
        key.toLowerCase().includes("total") ||
        key.toLowerCase().includes("kilo") ||
        key.toLowerCase().includes("liter")
      ? "number"
      : "text";
  };

  useEffect(() => {
    adminTabNo === 0 && getCompanyData(login.token, state._id);

    adminTabNo === 1 && getControversyData(login.token, state?.isin);
    // adminTabNo === 2 && getCompanyData(login.token, state._id);

    return () => {
      result = {};
      setAnswers({});
      setCompanyData({});
    };
  }, [login.token, adminTabNo]);

  useEffect(() => {
    adminTabNo === 1 &&
      getControversyLinks(login.token, state?.isin, limit, startingAfter);
  }, [startingAfter, adminTabNo]);

  useEffect(() => {
    setLinksList(
      riskDashboard?.controversyLinks.map((link) => {
        if (link.is_published) {
          link.isSelected = true;
        } else {
          link.isSelected = false;
        }
        return link;
      })
    );
    setPages(Math.ceil(riskDashboard?.totalLinks / limit));
  }, [riskDashboard.controversyLinks]);

  useEffect(() => {
    riskDashboard?.currentCompanyData &&
      setKpiList(riskDashboard?.currentCompanyData);
    let ans = {};
    if (riskDashboard?.currentCompanyData) {
      for (let keyOfIndex in riskDashboard?.currentCompanyData) {
        ans[keyOfIndex] = riskDashboard?.currentCompanyData[keyOfIndex][1];
      }
      setAnswers(ans);
    }
  }, [riskDashboard?.currentCompanyData]);

  const viewKpiDataList = (key, i) => {
    return (
      <div
        key={key}
        name={key + i}
        style={{
          display: "flex",
          borderBottom: "1px solid #EBF1F8",
          borderRadius: 5,
          padding: "10px 0px 5px 5px",
          alignItems: "center",
          // backgroundColor: "#FEFEFE",
        }}
        //   onClick={() => handleViewDetailsOrganization(row?._id)}
      >
        <div style={{ width: "50%", display: "flex" }}>
          {/* <Icon
          style={{ cursor: "pointer", color: "#15314E" }}
          //   onClick={() => handleViewDetailsOrganization(row._id)}
          fontSize="small"
        >
          descriptionicon
        </Icon> */}

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {`${i + 1})`}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {key}
          </Typography>
        </div>
        <div style={{ width: "30%" }} align="center">
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {kpiList[key][0]}
          </Typography>
        </div>
        <div style={{ width: "20%" }} align="center" n>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            <CustomTextfield
              key={key + i + "1"}
              name={key + i + "1"}
              margin="dense"
              variant="outlined"
              size="small"
              defaultValue={result[key]}
              type={returnType(key)}
              onChange={(e) => {
                handleInputChange(key, e.target.value, i);
              }}
            />
          </Typography>
        </div>
        {/* <div style={{ width: "5%" }} align="center">
        <CustomButton margin="dense" variant="outlined" size="small">
          Submit
        </CustomButton>
      </div> */}
      </div>
    );
  };

  const contoData = {
    ...controversyData,
    // "Anti-competition controversy": 0,
    // "Business ethics controversies": 1,
    // "Intellectual property controversies": 1,
    // "Critical countries controversies": 0,
    // "Public health controversies": 0,
    // "Tax fraud controversies": 0,
    // "Child labour controversies": 0,
    // "Human rights controversies": 0,
    // "Management compensation controversies count": 1,
    // "Consumer controversies": 0,
    // "Customer health and safety controversies": 0,
    // "Privacy controversies": 0,
    // "Product access controversies": 0,
    // "Responsible marketing controversies": 0,
    // "Responsible R&D controversies": 0,
    // "Environmental controversies": 0,
    // "Accounting controversies count": 0,
    // "Insider dealings controversies": 0,
    // "Shareholder rights controversies": 0,
    // "Diversity and opportunity controversies": 0,
    // "Employee health and safety controversies": 0,
    // "Wages or working conditions controversies": 0,
    // Strikes: 0,
  };

  const viewControversyList = (name, value) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #EBF1F8",
          borderRadius: 5,
          padding: "10px 20px 5px 20px",
        }}
      >
        <div>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {name}
          </Typography>
        </div>

        <div>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {value}
          </Typography>
        </div>
        {/* <div style={{ width: "10%" }}> */}
        {/* <CustomButton
            onClick={() => {
              setIsControversyOpen(true);
              setCurrentControversy(name);
            }}
            margin="dense"
            variant="outlined"
            size="small"
          >
            view
          </CustomButton> */}
        {/* <MoreVertIcon />
        </div> */}
      </div>
    );
  };

  const [currentLink, setCurrentLink] = useState("");
  const [checked, setChecked] = useState([]);
  const [sentiment, setSentiment] = useState("positive");

  console.log(currentLink);

  const viewLinks = (links, index) => {
    let { isSelected, title, url, sentiment_label, is_published, id } = links;
    // console.log(links);
    return (
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #EBF1F8",
          borderRadius: 5,
          padding: "10px 0px 5px 5px",
          alignItems: "center",
        }}
      >
        <div>
          <CustomCheckBox
            key={title}
            onChange={() => {
              setLinksList((prev) => {
                return [...prev].map((li) => {
                  if (li.url === url && !li.is_published) {
                    li.isSelected = !li.isSelected;
                  }
                  return li;
                });
              });
              // if (!riskDashboard?.is_published) {
              setChecked((prev) => {
                if (prev.includes(id)) {
                  // console.log(prev.indexOf(id));
                  let copy = [...prev];
                  copy.splice(prev.indexOf(id), 1);
                  return copy;
                } else {
                  let copy = [...prev];
                  copy.push(id);
                  return copy;
                }
              });
              // }
            }}
            checked={isSelected || checked.includes(id)}
          />
        </div>

        <div style={{ width: "28%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            {title}
          </Typography>
        </div>
        <div style={{ width: "40%", padding: 5 }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: "14px",
              paddingLeft: "5px",
              wordWrap: "break-word",
            }}
            href={url}
          >
            {url}
          </a>
        </div>

        {/* <div style={{ width: "35%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            <KeyboardArrowRightIcon />
          </Typography>
        </div> */}
        <div style={{ width: "20%" }} align="center">
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "3px 8px",
              bordere: "none",
              backgroundColor:
                sentiment_label === "Neutral"
                  ? "yellow"
                  : sentiment_label === "Positive"
                  ? "green"
                  : "red",
              color: sentiment_label === "Neutral" ? "inherit" : "#FFF",
              width: "45%",
              borderRadius: "8px",
            }}
          >
            {sentiment_label}
          </Typography>
        </div>
        <div style={{ width: "10%" }} align="center">
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px 5px",
            }}
          >
            <KeyboardArrowRightIcon
              style={{ cursor: "pointer" }}
              onClick={() => setCurrentLink(links)}
            />
          </Typography>
        </div>
      </div>
    );
  };
  console.log(contoData);
  const LinkDrawer = ({ setCurrentLink }) => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={currentLink}
        onClose={() => setCurrentLink("")}
        variant="persistent"
        width="lg"
      >
        <div
          className="scroll"
          style={{ width: "50vw", height: "90vh", padding: 10 }}
        >
          <CloseIcon
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => setCurrentLink("")}
          />
          {currentLink.sentiment_label === "Negative" && (
            <Select
              fullWidth
              style={{
                border: "1px solid #C0D4E9",
                backgroundColor: "#FEFEFE",
                borderRadius: "8px",
                // height: "40px",
                margin: "15px 0px",
              }}
              value={currentLink.controversy_category}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setCurrentLink((prev) => ({
                  ...prev,
                  controversy_category: value,
                }));
              }}
              input={<OutlinedInput />}
            >
              {(contoData?.negative || negativeControversies) &&
                Object?.keys(contoData?.negative ?? negativeControversies)?.map(
                  (item) => {
                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{ backgroundColor: "white" }}
                      >
                        {item}
                      </MenuItem>
                    );
                  }
                )}
            </Select>
          )}

          {currentLink.sentiment_label === "Positive" && (
            <Select
              fullWidth
              style={{
                border: "1px solid #C0D4E9",
                backgroundColor: "#FEFEFE",
                borderRadius: "8px",
                // height: "40px",
                margin: "15px 0px",
              }}
              value={currentLink.controversy_category}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setCurrentLink((prev) => ({
                  ...prev,
                  controversy_category: value,
                }));
              }}
              input={<OutlinedInput />}
            >
              {(contoData?.positive || positiveControversies) &&
                Object.keys(contoData?.positive ?? positiveControversies)?.map(
                  (item) => {
                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{ backgroundColor: "white" }}
                      >
                        {item}
                      </MenuItem>
                    );
                  }
                )}
            </Select>
          )}

          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
              marginTop:
                currentLink.sentiment_label !== "Negative" &&
                currentLink.sentiment_label !== "Positive"
                  ? 30
                  : 0,
            }}
          >
            Title : {currentLink.title}
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
            }}
          >
            {/* Sentiment : {currentLink.sentiment_label}  */}
            Sentiment :{" "}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentLink.sentiment_label}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setCurrentLink((prev) => ({
                  ...prev,
                  sentiment_label: value,
                }));
              }}
            >
              <MenuItem value={"Positive"}>Positive</MenuItem>
              <MenuItem value={"Neutral"}>Neutral</MenuItem>
              <MenuItem value={"Negative"}>Negative</MenuItem>
            </Select>
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
            }}
          >
            Overall Sentiment : {currentLink.sentiment_score}
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
            }}
          >
            Link :
            <a
              rel="noopener noreferrer"
              style={{ fontSize: "16px", paddingLeft: "5px" }}
              href={currentLink.url}
              target="_blank"
            >
              {currentLink.url}
            </a>
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              padding: "5px 5px",
            }}
          >
            Sentences : {currentLink.sentences}
          </Typography>
        </div>
        <div style={{ position: "fixed", bottom: 0, right: 2 }}>
          {/* <CustomButton
            style={{
              textTransform: "none",
              margin: "20px 10px",
              float: "right",
            }}
            size="small"
            color="primary"
            variant="outlined"
          >
            <Typography>Hide Controversy</Typography>
          </CustomButton> */}
          <CustomButton
            style={{
              textTransform: "none",
              margin: "20px 10px",
              float: "right",
            }}
            size="small"
            color="primary"
            variant="contained"
            disabled={
              currentLink.sentiment_label !== "Negative"
                ? false
                : !currentLink.controversy_category
            }
            onClick={() => {
              updateControversyType(
                login.token,
                currentLink.id,
                currentLink.sentiment_label === "Neutral"
                  ? ""
                  : currentLink.controversy_category,
                state?.isin,
                setCurrentLink,
                currentLink?.sentiment_label
              );
            }}
          >
            <PublishIcon style={{ paddingRight: 5 }} />
            <Typography>{` Save `}</Typography>
          </CustomButton>
        </div>
      </Drawer>
    );
  };

  const ViewControversy = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            marginTop: "2%",
            paddingLeft: 10,
            paddingTop: "10px",
          }}
        >
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsControversyOpen(false);
              setCurrentControversy("");
            }}
          />{" "}
          <Typography style={{ paddingLeft: 10 }}>
            {currentControversy}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: "5px 25px",
          }}
        >
          <CustomButton
            style={{ textTransform: "none", marginRight: 10 }}
            size="small"
            color="primary"
            variant="contained"
          >
            <RefreshIcon style={{ paddingRight: 5 }} />{" "}
            <Typography>Regenerate Links</Typography>
          </CustomButton>
          {/* <CustomButton
            style={{ textTransform: "none", marginRight: 10 }}
            size="small"
            color="primary"
            variant="outlined"
          >
            <Typography>Hide Controversy</Typography>
          </CustomButton> */}
        </div>
        <div>
          {linksList?.length > 0 &&
            linksList.map((link, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    paddingLeft: 10,
                    padding: "8px 35px",
                  }}
                >
                  <Typography> {`${i + 1})`}</Typography>{" "}
                  <a
                    rel="noopener noreferrer"
                    style={{ fontSize: "16px", paddingLeft: "5px" }}
                    href={link.link}
                    target="_blank"
                  >
                    {link.link}
                  </a>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  const handlePublishControversies = () => {
    let selectedToPublsh = linksList
      .filter((link) => (link?.isSelected ? link.id : null))
      .map((link) => link.id);

    const year = state?.year;
    publishLinks(
      login.token,
      {
        ids: checked,
      },
      state?.isin,
      limit,
      startingAfter,
      year
    );
    setChecked([]);
  };

  const handlePublishInsights = () => {
    publishInsights(login.token, state?.isin, state?._id, state?.year);
  };

  const handleRegenerateInsights = () => {
    regenerateInsights(login.token, state?.isin, state._id, state.year);
  };
  // pagination function
  const handleChangePagination = (event, value) => {
    setStartingAfter(value * limit - limit);
    return;
  };

  return (
    <div style={{ overflow: "none" }}>
      <div style={{ display: "flex", paddingLeft: 10 }}>
        <ArrowBackIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.goBack();
            result = {};
            setAnswers({});
            setCompanyData({});
          }}
        />
        <Typography style={{ paddingLeft: 10 }}>
          {state.company_name}
        </Typography>
      </div>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "95%",
          }}
        >
          <div>
            <MuiThemeProvider theme={appTheme}>
              {/* ADMIN TABS */}
              <AppBar
                position="static"
                color="inherit"
                className={classes.AppBar}
              >
                <Tabs
                  value={adminTabNo}
                  onChange={(e, newVal) => {
                    setAdminTabNo(newVal);
                  }}
                  // onChange={handleTabChange}
                >
                  <Tab
                    disableRipple
                    className={classes.tab}
                    label={<span className={classes.tabLabel}>KPI Data</span>}
                  />
                  <Tab
                    disableRipple
                    className={classes.tab}
                    label={
                      <span className={classes.tabLabel}>Digital Reputation</span>
                    }
                  />
                  <Tab
                    disableRipple
                    className={classes.tab}
                    label={<span className={classes.tabLabel}> Insights</span>}
                  />
                </Tabs>
              </AppBar>
            </MuiThemeProvider>
          </div>

          <div>
            <CustomButton
              size="small"
              color="primary"
              variant="contained"
              disabled={state?.is_published}
              style={{
                textTransform: "capitalize",
                // backgroundColor: "#3374B9",
                // fontSize: "12px",
                // width:"300px"
              }}
              onClick={() =>
                dispatch(
                  publish_company(
                    login?.token,
                    state?.isin,
                    state?.year,
                    state?._id
                  )
                )
              }
            >
              {state?.is_published ? "Published" : "Publish"}
            </CustomButton>
          </div>
        </div>

        {/* CONTROVERSY AND LINKS TAB PANNEL */}
        <TabPanel value={adminTabNo} index={0}>
          {/* <CompanyProfile /> */}
          <Grid
            className="scroll"
            style={{ height: "75vh", padding: "10px 60px", marginTop: "20px" }}
          >
            <div
              style={{
                display: "flex",
                borderRadius: 5,
                backgroundColor: "#EBF1F8",
              }}
            >
              <div style={{ width: "3%" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    padding: "0px 5px",
                  }}
                >
                  {`No.`}
                </Typography>
              </div>
              <div style={{ width: "47%" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    padding: "0px 5px",
                  }}
                >
                  {`Field`}
                </Typography>
              </div>
              <div style={{ width: "30%" }} align="center">
                <Typography> Values</Typography>
              </div>
              <div style={{ width: "20%" }} align="center">
                <Typography>Actions</Typography>
              </div>
              {/* <div style={{ width: "20%" }} align="center">
              <Typography> country</Typography>
            </div>
            <div style={{ width: "10%" }} align="center">
              <Typography> Actions</Typography>
            </div> */}
            </div>
            <div style={{ marginTop: "15px", paddingBottom: "40px" }}>
              {Object.keys(kpiList)?.length > 0 &&
                Object?.keys(kpiList)?.map((key, i) => viewKpiDataList(key, i))}
              {/* {viewKpiDataList(kpiList)} */}
              {/* {riskDashboard &&
              riskDashboard?.currentCompanyData &&
              viewKpiDataList(riskDashboard?.currentCompanyData)} */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  paddingTop: 10,
                }}
              >
                <CustomButton
                  // disabled={Object.values(answers).some(
                  //   (item) => item !== null
                  // )}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    updateCompanyData(
                      login.token,
                      state._id,
                      state.isin,
                      state.year,
                      result,
                      setAdminTabNo
                    );
                  }}
                >
                  Submit
                </CustomButton>
              </div>
            </div>
          </Grid>
        </TabPanel>

        {/* CONTROVERSY AND LINKS TAB PANNEL */}

        <TabPanel value={adminTabNo} index={1}>
          <Grid className="scroll" style={{ height: "75vh" }}>
            {currentLink && <LinkDrawer setCurrentLink={setCurrentLink} />}
            {!isControversyOpen ? (
              <Grid style={{ padding: "10px 60px", marginTop: "10px" }}>
                <Box style={{ display: "flex", gap: "70px" }}>
                  <Box style={{ flex: 1 }}>
                    {/* <Typography
                      style={{ marginBottom: "15px", fontWeight: 500 }}
                    >
                      Positive Controversies
                    </Typography> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 5,
                        backgroundColor: "#EBF1F8",
                        padding: "10px 20px ",
                      }}
                    >
                      <div>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            padding: "0px 5px",
                          }}
                        >
                          {`Positive Articles`}
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            padding: "0px 5px",
                          }}
                        >
                          {`Count`}
                        </Typography>
                      </div>

                      {/* <div style={{ width: "20%" }} align="right">
                      <Typography>Actions</Typography>
                    </div> */}
                    </div>

                    <div style={{ marginTop: "15px", paddingBottom: "40px" }}>
                      {(contoData?.positive || positiveControversies) &&
                        Object.entries(
                          contoData?.positive ?? positiveControversies
                        )?.map(
                          ([key, value]) =>
                            key !== null && viewControversyList(key, value)
                        )}
                    </div>
                  </Box>

                  <Box style={{ flex: 1 }}>
                    {/* <Typography>Negative Controversies</Typography> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 5,
                        backgroundColor: "#EBF1F8",
                        padding: "10px 20px ",
                      }}
                    >
                      <div>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            padding: "0px 5px",
                          }}
                        >
                          {`Negative Articles`}
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            padding: "0px 5px",
                          }}
                        >
                          {`Count`}
                        </Typography>
                      </div>

                      {/* <div style={{ width: "20%" }} align="right">
                      <Typography>Actions</Typography>
                    </div> */}
                    </div>

                    <div style={{ marginTop: "15px", paddingBottom: "40px" }}>
                      {(contoData?.negative || negativeControversies) &&
                        Object.entries(
                          contoData?.negative ?? negativeControversies
                        )?.map(
                          ([key, value]) =>
                            key !== null && viewControversyList(key, value)
                        )}
                    </div>
                  </Box>
                </Box>

                {linksList.length > 0 && (
                  <div style={{ marginTop: "15px", paddingBottom: "40px" }}>
                    <div
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                        gap: 10,
                      }}
                    >
                      {riskDashboard?.is_published && (
                        <Typography>Articles Published</Typography>
                      )}
                      <Pagination
                        // count={Math.round(documents.length / rowsPerPage)}
                        count={pages}
                        siblingCount={0}
                        defaultPage={1}
                        // onChange={handleChangePage}
                        onChange={handleChangePagination}
                        variant="outlined"
                        color="primary"
                        size="small"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        borderRadius: 5,
                        backgroundColor: "#EBF1F8",
                        padding: "10px 0",
                      }}
                    >
                      <div style={{ width: "4%" }}></div>
                      <div style={{ width: "28%" }}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            padding: "0px 5px",
                          }}
                        >
                          {`Title`}
                        </Typography>
                      </div>

                      <div style={{ width: "40%" }}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            padding: "0px 5px",
                          }}
                        >
                          {`Links`}
                        </Typography>
                      </div>
                      <div style={{ width: "20%" }} align="center">
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            padding: "0px 5px",
                          }}
                        >
                          {`Sentiment`}
                        </Typography>
                      </div>

                      <div style={{ width: "10%" }} align="center">
                        <Typography>Actions</Typography>
                      </div>
                    </div>

                    {linksList &&
                      linksList?.map((link, index) => viewLinks(link, index))}
                  </div>
                )}
                <div>
                  <CustomButton
                    style={{
                      textTransform: "none",
                      margin: "20px 10px",
                      float: "right",
                    }}
                    // disabled={riskDashboard?.is_published}
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handlePublishControversies}
                  >
                    <PublishIcon style={{ paddingRight: 5 }} />
                    <Typography>Publish Digital Reputation</Typography>
                  </CustomButton>
                  {!state?.controversy_process_in_progress && (
                    <CustomButton
                      style={{
                        textTransform: "none",
                        margin: "20px 10px",
                        float: "right",
                      }}
                      size="small"
                      color="primary"
                      variant="contained"
                      // disabled={riskEvaluator?.overallData}
                      onClick={() =>
                        dispatch(
                          searchArticles(login?.token, state?.isin, state?.year)
                        )
                      }
                    >
                      <RefreshIcon style={{ paddingRight: 5 }} />
                      <Typography>Search Articles</Typography>
                    </CustomButton>
                  )}
                </div>
              </Grid>
            ) : (
              <ViewControversy />
            )}
          </Grid>
        </TabPanel>

        {/* INSIGHTS TAB PANNEL */}
        <TabPanel value={adminTabNo} index={2}>
          <div style={{ padding: 20 }}>
            {/* INSIGHTS ACTION BUTTONS */}
            {Array.isArray(riskEvaluator.overallData) ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  padding: "5px 25px",
                }}
              >
                <CustomButton
                  style={{ textTransform: "none", marginRight: 10 }}
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={!riskEvaluator?.overallData}
                  onClick={handlePublishInsights}
                >
                  <ArrowUpwardIcon style={{ paddingRight: 5 }} />{" "}
                  <Typography>Publish Insights</Typography>
                </CustomButton>
                <CustomButton
                  style={{ textTransform: "none", marginRight: 10 }}
                  size="small"
                  color="primary"
                  variant="contained"
                  // disabled={riskEvaluator?.overallData}
                  onClick={handleRegenerateInsights}
                >
                  <RefreshIcon style={{ paddingRight: 5 }} />
                  <Typography>Generate Insights</Typography>
                </CustomButton>
              </div>
            ) : (
              // riskEvaluator?.overallData?.is_proccess_completed &&
              // !riskEvaluator?.overallData?.is_published &&
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  padding: "5px 25px",
                }}
              >
                <CustomButton
                  style={{ textTransform: "none", marginRight: 10 }}
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={!riskEvaluator?.overallData}
                  onClick={handlePublishInsights}
                >
                  <ArrowUpwardIcon style={{ paddingRight: 5 }} />{" "}
                  <Typography>Publish Insights</Typography>
                </CustomButton>
                <CustomButton
                  style={{ textTransform: "none", marginRight: 10 }}
                  size="small"
                  color="primary"
                  variant="contained"
                  // disabled={riskEvaluator?.overallData}
                  onClick={handleRegenerateInsights}
                >
                  <RefreshIcon style={{ paddingRight: 5 }} />
                  <Typography>Regenerate Insights</Typography>
                </CustomButton>
                {/* <CustomButton
                style={{ textTransform: "none", marginRight: 10 }}
                size="small"
                color="primary"
                variant="outlined"
              >
                <Typography>Hide Controversy</Typography>
                
              </CustomButton>
                */}
              </div>
            )}

            {/* INSIGHTS DISPLAYING COMPONENT */}
            <OverallProfile {...props} />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default RiskDocument;
