import React from "react";

import { CardContent } from "@material-ui/core";

import TabPanel from "../../../UI/tabPanel/tabPanel";

import LeftCard from "./LeftCard";

import { brmLeftStyles } from "./brmLeftStyle";

import {
  basicDataFirstTab,
  basicDataSecondTab,
  esgProfileTab,
  esgRisk,
} from "./leftTabData";

function BrmLeftEvaluator(props) {
  const { setBrmSubTabNo, brmData } = props;
  const classes = brmLeftStyles();
  const handleTabChange = (index) => {
    setBrmSubTabNo(index);
  };

  return (
    <CardContent className={classes.cardContent}>
      <TabPanel value={brmData.brmMainTabNo} index={0}>
        <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
          <LeftCard
            toMap={basicDataFirstTab}
            handleTabChange={handleTabChange}
            subTabNo={brmData?.brmSubTabNo}
          />
        </div>
      </TabPanel>

      <TabPanel value={brmData.brmMainTabNo} index={1}>
        <div className="scroll" style={{ marginTop: 0, height: "62.5vh" }}>
          <LeftCard
            toMap={basicDataSecondTab}
            handleTabChange={handleTabChange}
            subTabNo={brmData?.brmSubTabNo}
          />
        </div>
      </TabPanel>
      <TabPanel value={brmData.brmMainTabNo} index={2}>
        <div className="scroll" style={{ marginTop: 0, height: "62.5vh" }}>
          <LeftCard
            toMap={esgProfileTab}
            handleTabChange={handleTabChange}
            subTabNo={brmData?.brmSubTabNo}
          />
        </div>
      </TabPanel>
      <TabPanel value={brmData.brmMainTabNo} index={3}>
        <div className="scroll" style={{ marginTop: 0, height: "62.5vh" }}>
          <LeftCard
            toMap={esgRisk}
            handleTabChange={handleTabChange}
            subTabNo={brmData?.brmSubTabNo}
          />
        </div>
      </TabPanel>
    </CardContent>
  );
}

export default BrmLeftEvaluator;
