import React from "react";

import { useHistory } from "react-router-dom";

import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import analytics from "../../../images/Analytics.png";
import useHoverEffect from "../../../customHooks/getHoveringEffects";

function EsgGptMainTitle({ title, newPath }) {
  const history = useHistory();
  const { isHovering, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  const handleChangeRoute = () => {
    let path = newPath;
    history.push(path);
  };

  return (
    <Grid
      item
      style={{
        display: "flex",
        border: "1px solid #EBF1F8",
        borderRadius: "8px",
        backgroundColor: isHovering ? "#EBF1F8" : "#FBFBFB",
        padding: "10px 12px",
        justifyContent: "space-between",
        width: "70%",
        marginBottom: "10px",
        cursor: "pointer",
        alignItems: "center",
      }}
      onClick={handleChangeRoute}
      onMouseEnter={() => handleMouseEnter(true)}
      onMouseLeave={() => handleMouseLeave(true)}
    >
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 600,
          letterSpacing: "0.014px",
          textTransform: "capitalize",
          //   cursor:"pointer"
        }}
      >
        {title}
      </Typography>
      <IconButton
        variant="contained"
        style={{
          color: "#3374B9",
          marginLeft: "auto",
          backgroundColor: "#FBFBFB",
          borderRadius: "8px",
          height: "32px",
          width: "32px",
          // marginRight: "5px",
          padding: "5px",
        }}
        aria-label="add to shopping cart"
      >
        <ArrowForwardIosIcon
          style={{ color: "#3374B9", fontSize: 16 }}
          fontSize="medium"
        />
      </IconButton>
    </Grid>
  );
}

function EsgGptMainRight() {
  return (
    <Grid
      container
      // justifyContent="center"
      // direction="column"
      // alignItems="center"
      style={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-evenly",
        alignItems: "center",

        height: "100%",
      }}
    >
      <Grid item>
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 500,
            letterSpacing: "0.024px",
            textTransform: "capitalize",
          }}
        >
          ESG GPT
        </Typography>
      </Grid>
      <Grid item>
        <img
          src={analytics}
          style={{ height: "38vh", marginTop: "auto" }}
          alt="analytics"
        />
      </Grid>
      <Grid
        item
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "auto",
        }}
      >
        <EsgGptMainTitle
          title={"Review the our list of ESG Profiles"}
          newPath={"/esg_gpt/company_profiles"}
        />
        <EsgGptMainTitle
          title={"Ask any under the sun with regards to ESG"}
          newPath={"/esg_gpt/chat"}
        />
        <EsgGptMainTitle
          title={"Request us to Build an ESG profile for Custom Company"}
          newPath={"/esg_gpt/add_company"}
        />
      </Grid>
    </Grid>
  );
}

export default EsgGptMainRight;
