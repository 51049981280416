import UNIVERSAL from "../../../config";
import {
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION_DETAILS,
} from "../../../constants/brmConstants";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";

export function createVendorOrganization(
  token,
  bankId,
  name,
  isin,
  year_of_assessment,
  logo,
  sectors,
  operation,
  no_of_employees,
  address,
  masterSectors,
  rowsPerPage,
  revenue,
  turnover,
  country
) {
  return (dispatch) => {
    dispatch(set_loader());
    var sectorData = [],
      sectorData2 = [];

    sectors &&
      sectors.map((sector, index) => {
        sectorData.push({
          sector: sector._id,
          industry: [],
        });

        sector &&
          sector.industry_data.map((industry, index2) => {
            if (industry.isSelected) {
              sectorData[index].industry.push(industry._id);
            }
            return true;
          });
        return true;
      });

    sectorData.map((sector, index) => {
      if (sector.industry.length > 0) {
        sectorData2.push(sector);
      }
      return true;
    });

    console.log(
      "create api ",
      // token,
      // bankId,
      // name,
      // isin,
      // year_of_assessment,
      // logo,
      // sectors,
      // operation,
      // no_of_employees,
      // address,
      // masterSectors,
      // sectorData2,
      country,
      revenue,
      turnover
    );
    // const dummy = {
    //   "user-token":
    //     "eyJhbGciOiJIUzI1NiJ9.c2FhZEBhcHBzdG9uZS5pbg.HjRS3-CblA1TKudOd0Hw3oaofnSiwuJIZQiVqkGVHwE",
    //   bank_id: "63da5f8a6369492c38bedef1",
    //   name: "vendor 2",
    //   isin: "ISIN002",
    //   year_of_assessment: "2023-2024",
    //   sectors: [
    //     {
    //       sector: "6197687b956d954194412524",
    //       industry: ["6197687b956d95419441254d"],
    //     },
    //   ],
    //   operation: "ops",
    //   no_of_employees: "251-1000",
    //   address: [
    //     {
    //       landmark: "",
    //       state: "Odisha",
    //       companyAddress: "12 fv",
    //       landMark: "land mark",
    //       pinCode: "560061",
    //       country: "India",
    //     },
    //   ],
    //   revenue: [
    //     {
    //       revenue: "27",
    //       year: "2023-2024",
    //       currency: "USD",
    //       figures: "Million",
    //     },
    //   ],
    //   turnover: [],
    //   country: "IN",
    // };
    const data = encrypt({
      "user-token": token,
      bank_id: bankId,
      name: name,
      isin,
      year_of_assessment,
      sectors: sectorData2,
      operation,
      no_of_employees,
      address,
      revenue: revenue || [],
      turnover: turnover || [],
      country,
    });

    var formData = new FormData();
    formData.append("data", data);
    formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/vendor/create_vendor_organization", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "content-type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewVendorOrganization(token, bankId, rowsPerPage, 0));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateVendorOrganization(
  token,
  organization_id,
  name,
  isin,
  year_of_assessment,
  logo,
  sectors,
  operation,
  no_of_employees,
  address,
  masterSectors,
  revenue,
  turnover,
  country
) {
  return (dispatch) => {
    dispatch(set_loader());
    console.log("api",  token,
    organization_id,
    name,
    isin,
    year_of_assessment,
    logo,
    sectors,
    operation,
    no_of_employees,
    address,
    masterSectors,
    revenue,
    turnover,
    country);

    var sectorData = [],
      sectorData2 = [];

    sectors &&
      sectors.map((sector, index) => {
        sectorData.push({
          sector: sector._id,
          industry: [],
        });

        sector &&
          sector.industry_data.map((industry, index2) => {
            if (industry.isSelected) {
              sectorData[index].industry.push(industry._id);
            }
            return true;
          });
        return true;
      });

    sectorData.map((sector, index) => {
      if (sector.industry.length > 0) {
        sectorData2.push(sector);
      }
      return true;
    });
    // console.log({
    // "user-token": token,
    // organization_id,
    // name: name,
    // isin,
    // year_of_assessment,
    // sectorData2,
    // logo,
    // operation,
    // no_of_employees,
    // address: address[0],
    // });
    const data = encrypt({
      "user-token": token,
      organization_id,
      name: name,
      isin,
      year_of_assessment,
      sectors: sectorData2,
      operation,
      no_of_employees,
      address: address[0],
      country,
      revenue,
      turnover,
    });
    var formData = new FormData();

    formData.append("data", data);
    logo && formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/vendor/update_vendor_organization", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "content-type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewVendorDetailsOrganization(token, organization_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function setVendorOrganizationDetails(payload) {
  return {
    type: SET_ORGANIZATION_DETAILS,
    payload: payload,
  };
}
export function viewVendorDetailsOrganization(token, organizationId) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      organization_id: organizationId,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/vendor/view_vendor_organization_details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setVendorOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const setOrganizationsList = (payload, total) => {
  return {
    type: SET_ORGANIZATIONS_LIST,
    payload,
    total,
  };
};

export function viewVendorOrganization(
  token,
  bankId,
  limit,
  starting_after,
  keyword
) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      bank_id: bankId,
      limit,
      starting_after,
      keyword: keyword || "",
    });
    return fetch(UNIVERSAL.BASEURL + "/vendor/view_vendor_organizations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            setOrganizationsList(responseJson.result, responseJson.total)
          );
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
