export const RESET_RISK_EVALUATOR = "RESET_RISK_EVALUATOR";
export const SET_CALCULATED_SURVEY = "SET_CALCULATED_SURVEY";
export const GET_CALCULATED_SURVEY = "GET_CALCULATED_SURVEY";
export const SET_CALCULATED_SENTIMENT = "SET_CALCULATED_SENTIMENT";
export const SET_CALCULATED_SENTIMENT_TOPIC_WISE =
  "SET_CALCULATED_SENTIMENT_TOPIC_WISE";
export const SET_CALCULATED_SENTIMENT_BAR_GRAPH =
  "SET_CALCULATED_SENTIMENT_BAR_GRAPH";
export const SET_CALCULATED_SENTIMENT_ARTICLE =
  "SET_CALCULATED_SENTIMENT_ARTICLE";
export const SET_SENTIMENT_SOCRE_DATA = "SET_SENTIMENT_SOCRE_DATA";
export const SET_COMPANY_INSIGHTS = "SET_COMPANY_INSIGHTS";
export const SET_SCORES = "SET_SCORES";
export const SET_PEER_SCORES = "SET_PEER_SCORES";
export const SET_CONTROVERSY_DATA = "SET_CONTROVERSY_DATA";
export const SET_PUBLIC_REPORTS = "SET_PUBLIC_REPORTS";
export const SET_PEERS = "SET_PEERS";
export const SET_SELF_ASSESSMENT_SOCRE = "SET_SELF_ASSESSMENT_SOCRE";
export const SET_RISK_RATING_DATA = "SET_RISK_RATING_DATA";

