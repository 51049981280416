export const SET_CORPORATE_QUESTIONS = "SET_CORPORATE_QUESTIONS";
export const SET_SURVEY_QUESTIONS = "SET_SURVEY_QUESTIONS";
export const RESET_CORPORATE = "RESET_CORPORATE";
export const SET_CORPORATE_ANSWERS = "SET_CORPORATE_ANSWERS";
export const SET_CORPORATE_ORGANIZATIONS = "SET_CORPORATE_ORGANIZATIONS";
export const SET_ASSIGNED_SECTION = "SET_ASSIGNED_SECTION";
export const SET_SELECTED_ORGANIZATIO_CORP = "SET_SELECTED_ORGANIZATIO_CORP";
export const SET_VERSION_HISTORY ="SET_VERSION_HISTORY";
export const SET_SELECTED_OPTION = "SET_SELECTED_OPTION";
export const SET_PUBLISH_ANSWER = "SET_PUBLISH_ANSWER"
