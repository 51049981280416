import { SET_VERSION_HISTORY } from "../../../constants/corporateSurveyConstants";
import {
  SET_ELASTIC_ORGANIZATIONS,
  SET_COMPANY_DATA,
  SET_COMPANY_ID,
  SET_TOTAL_COMPANIES,
  SET_CONTROVERSY_LINKS,
  SET_PAGINATION_PAGE,
  SET_SURVEY_DATA,
  SET_SURVEY_PAGINATION_PAGE,
  SET_SELECTED_SURVEY,
  SET_SELECTED_SURVEY_RESPONSE,
  SET_SELECTED_RES_COMPANY,
  SET_SECTION,
  SET_QUESTIONS,
  SET_SELECTED_SECTION,
  SET_RESPONDED_ORGAIZATIONS,
  SET_SURVEY_RESPONDED_USERS,
  SET_SUBMITTED_QUESTIONS,
} from "../../../constants/riskDashboardConstants";

const initial_state = {
  companies: [],
  currentCompanyId: "",
  totalCompanies: "",
  currentCompanyData: {},
  controversyLinks: [],
  totalLinks: 0,
  is_published: false,
  dahboardPaginationPage: 1,

  // survey data
  surveyData: [],
  selectedSurveyData: null,
  surveyPaginationPage: 1,

  // survey inner data
  surveyRespondedUsers: [],
  total_no_of_questions: 0,
  surveyStatus:false,
  respondedOrganizations: [],
  submittedQuestions: [],
  selectedResponse: null,
  selectedResCompany: null,
  sectionWiseData: [],
  sectionWiseQuestion: [],
  selectedSection: "Self Assessment Section",
  versionHistory: [],
  hasMoreData:true,
  offset:0
};

export default function riskDashboardReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ELASTIC_ORGANIZATIONS:
      return (state = { ...state, companies: action.payload });
    case SET_COMPANY_DATA:
      return (state = { ...state, currentCompanyData: action.payload });
    case SET_TOTAL_COMPANIES:
      return (state = { ...state, totalCompanies: action.payload });
    case SET_COMPANY_ID:
      return (state = { ...state, currentCompanyId: action.payload });
    case SET_PAGINATION_PAGE:
      return (state = { ...state, dahboardPaginationPage: action.payload });
    case SET_CONTROVERSY_LINKS:
      return (state = {
        ...state,
        controversyLinks: action.payload.payload,
        totalLinks: action.payload.total,
        is_published: action.payload.is_published,
      });

    // survey cases
    case SET_SURVEY_DATA:
      return {
        ...state,
        surveyData: [...state.surveyData, action.payload],
      };
    case SET_SELECTED_SURVEY:
      return (state = { ...state, selectedSurveyData: action.payload });
    case SET_SURVEY_PAGINATION_PAGE:
      return (state = { ...state, surveyPaginationPage: action.payload });

    // survey inner cases
    case SET_SURVEY_RESPONDED_USERS:
      return (state = { ...state, surveyRespondedUsers: action.payload });
    case SET_SUBMITTED_QUESTIONS:
      return (state = {
        ...state,
        submittedQuestions: action.payload,
        total_no_of_questions: action.total_no_of_questions,
        surveyStatus:action.is_completed
      });
    case SET_SELECTED_SURVEY_RESPONSE:
      return (state = { ...state, selectedResponse: action.payload });
    case SET_RESPONDED_ORGAIZATIONS:
      return (state = { ...state, respondedOrganizations: action.payload });
    case SET_SELECTED_RES_COMPANY:
      return (state = { ...state, selectedResCompany: action.payload });
    case SET_SECTION:
      return (state = { ...state, sectionWiseData: action.payload });
    case SET_QUESTIONS:
      return (state = { ...state, sectionWiseQuestion: action.payload });
    case SET_SELECTED_SECTION:
      return (state = { ...state, selectedSection: action.payload });
    case SET_VERSION_HISTORY:
      return (state = { ...state, versionHistory: action.payload });
      case "HAS_MORE_DATA" : 
      return (state = { ...state, hasMoreData: action.payload });
      case "SET_OFFSET" : 
      return (state = { ...state, offset: action.payload });
    default:
      return state;
  }
}
