import React from "react";

import { CardContent } from "@material-ui/core";

import TabPanel from "../../../UI/tabPanel/tabPanel";

import LeftCard from "./LeftCard";

import { brmLeftStyles } from "./brmLeftStyle";

import { rmBasicDataFirstTab, rmSurveyTabs } from "./leftTabData";

function BrmLeftManager(props) {
  const { setBrmSubTabNo, brmData } = props;
  const classes = brmLeftStyles();
  const handleTabChange = (index) => {
    setBrmSubTabNo(index);
  };

  return (
    <CardContent className={classes.cardContent}>
      <TabPanel value={brmData.brmMainTabNo} index={0}>
        <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
          <LeftCard
            toMap={rmBasicDataFirstTab}
            handleTabChange={handleTabChange}
            subTabNo={brmData?.brmSubTabNo}
          />
        </div>
      </TabPanel>

      <TabPanel value={brmData.brmMainTabNo} index={1}>
        <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
          <LeftCard
            toMap={rmSurveyTabs}
            handleTabChange={handleTabChange}
            subTabNo={brmData?.brmSubTabNo}
          />
        </div>
      </TabPanel>
    </CardContent>
  );
}

export default BrmLeftManager;
