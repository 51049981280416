import blueIcon from "../../images/IconBlue.png";
import greenIcon from "../../images/IconGreen.png";
import purpuleIcon from "../../images/IconPurpule.png";
import yellowIcon from "../../images/IconYellow.png";
import orangeIcon from "../../images/IconOrange.png";

export const getColoredLogo = (topic) => {
  if (topic === "Environment") return greenIcon;
  if (topic === "Social") return purpuleIcon;
  if (topic === "Governance") return blueIcon;
  if (topic === "Business") return orangeIcon;
  if (topic === "Human Capital") return yellowIcon;
  else return null;
};

export const returnCategoryOfdataPoint = (data) => {
  let environment_categories = [
      "Energy",
      "Water",
      "Waste",
      "Analytic CO2",
      "Green revenues",
    ],
    social_categories = [
      "Career development and training",
      "Data privacy",
      "Diversity and Inclusion",
      "Health and Safety",
      "HumanRights",
      "Responsible Marketing",
    ],
    governance_categories = ["Compensation", "Structure"];

  if (environment_categories.includes(data)) return "Environment";
  if (social_categories.includes(data)) return "Social";
  if (governance_categories.includes(data)) return "Governance";
};

export const gptPeerAnalysisText = () => {
  let arr = [
    `“Good sustainability practices positively influenced the stock price performance of 80% of companies and lowered the cost of capital of 90% of companies. Solid ESG practices are found to have resulted in better operational performance in 88% of companies”`,
    `“Strong ESG initiatives have significantly boosted the stock price performance of 80% of companies, vividly demonstrating the concrete financial advantages derived from embracing sustainable practices within today's market environment.”`,
    `“The compelling link between effective ESG strategies and enhanced operational performance is vividly illustrated by the 88% of companies experiencing tangible improvements in their day-to-day business functions.”`,
    `“ESG is the compass guiding businesses towards a future where profitability intertwines with purpose, where investors increasingly recognize that sustainable practices are pivotal for mitigating risks and seizing opportunities.”`,
    `“Beyond compliance, ESG represents a commitment to ethics, transparency, and accountability, creating a framework that not only resonates with stakeholders but also fortifies a company's position in a world where sustainable practices are becoming non-negotiable benchmarks for success.”`,
    `“Companies embracing comprehensive ESG frameworks have witnessed a convergence of financial success and sustainable practices, with significant proportions reaping the rewards through heightened stock prices and lowered capital costs.”`,
  ];
  let i = Math.round(Math.random() * arr.length);
  return [arr[i]]
};
export const riskArea = [
  {
    topic: "Human Capital",
    heading: "Labor Standards and Human Rights",
    description:
      "Poor labor standards and human rights violations can result in reputational damage, legal liabilities, and loss of investor confidence.",
  },
  {
    topic: "Governance",
    heading: "Risk Management",
    description:
      "Potential for business disruptions and financial losses, suggestions for enterprise risk management frameworks",
  },
  {
    topic: "Human Capital",
    heading: "Community Relations",
    description:
      "Best practices in employee education and career development, potential for enhanced employee retention and productivity",
  },
  {
    topic: "Environment",
    heading: "Regulatory Compliance",
    description:
      "Poor compliance with environmental regulations may result in fines, legal penalties, and reputational damage",
  },
  {
    topic: "Environment",
    heading: "Greenhouse Gas Emissions",
    description:
      "High emissions contribute to climate change, which poses a significant threat to the environment and human well-being",
  },
];

export const strongAreas = [
  {
    topic: "Business",
    heading: "Board Composition and Diversity",
    description:
      "A diverse and effective board can provide oversight and strategic guidance, improving corporate performance and reducing risk.",
  },
  {
    topic: "Business",
    heading: "Corporate Governance Policies and Procedures",
    description:
      "Implementing strong corporate governance policies and procedures can reduce risk, increase transparency, and enhance investor confidence.",
  },
  {
    topic: "Business",
    heading: "Ethical Business Practices:",
    description:
      "Maintaining ethical business practices can improve reputation, reduce risk, and enhance long-term value creation",
  },
  {
    topic: "Business",
    heading: "Transparency and Disclosure",
    description:
      "Transparency and disclosure can enhance investor confidence, improve reputation, and promote accountability.",
  },
];

export const improvementAreas = [
  {
    topic: "Environment",
    heading: "Transparency and Disclosure",
    description:
      "Transparency and disclosure can enhance investor confidence, improve reputation, and promote accountability.",
  },
  {
    topic: "Environment",
    heading: "Waste Reduction and Recycling",
    description:
      "Implementing waste reduction and recycling programs can reduce waste generation, conserve resources, and reduce costs",
  },
  {
    topic: "Human Capital",
    heading: "Employee Training and Development",
    description:
      "Providing employee training and education programs can help improve workplace safety and reduce the risk of accidents and injuries",
  },
];

export const financialInsights = [
  {
    heading: "For the financial year",
    description:
      "Report shows strong financial health, with a high current ratio indicating a good ability to pay off short-term liabilities. However, the company's debt-to-equity ratio is relatively high, indicating a potential risk of overleveraging. The company has a good history of meeting its debt obligations, with timely payments and no defaults. Overall, the report suggests that ABC Company has a solid financial footing and is a low-risk investment or lending opportunity.",
  },
];
