import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Tour from "reactour";

import Typography from "@material-ui/core/Typography";

import CustomButton from "../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setHelpCenterDrawer,
  updateGptTour,
} from "../../../redux/actions/gpt/esgGptAction";
import Group from "../../../images/Group.svg";

export const TourContent = ({ heading, content, token = false }) => {
  return (
    <div>
      <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
        {heading}
      </Typography>
      {token && (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <img src={Group} alt="" />
          <Typography
            style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
          >
            50
          </Typography>
          <Typography
            style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
          >
            {`=`}
          </Typography>
          <Typography
            style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
          >
            50 Questions
          </Typography>
        </div>
      )}
      <Typography style={{ fontSize: 13, marginTop: token ? 10 : 25 }}>
        {content}
      </Typography>
    </div>
  );
};

export const tourStyle = {
  minWidth: "450px",
};

function GptTour({ steps, resource }) {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  const handleTourCompleteStatus = (isCompany) => {
    dispatch(updateGptTour(login?.token, isCompany));
  };

  const handleLastStepButton = (isCompany) => {
    if (isCompany) {
      handleTourCompleteStatus(isCompany);
      history.push("/esg_gpt/chat");
    } else {
      // dispatch(setHelpCenterDrawer());
      handleTourCompleteStatus(isCompany);
    }
  };

  return (
    <>
      {steps && (
        <Tour
          steps={steps}
          isOpen={isOpen}
          onRequestClose={() => {
            setIsOpen(false);
            currentStep !== steps.length &&
              handleTourCompleteStatus(resource === "chat" ? false : true);
          }}
          rounded={8}
          lastStepNextButton={
            <CustomButton
              variant="contained"
              color="primary"
              style={{ marginLeft: "50px" }}
              onClick={() =>
                handleLastStepButton(resource === "chat" ? false : true)
              }
            >
              {resource === "company" ? "Move to chats" : "Finish"}
            </CustomButton>
          }
          maskSpace={10}
          nextButton={
            <CustomButton
              variant="contained"
              color="primary"
              style={{ marginLeft: "50px" }}
            >
              Next
            </CustomButton>
          }
          prevButton={
            currentStep !== steps.length ? (
              <CustomButton
                variant="text"
                color="primary"
                // style={{ marginTop: 15 }}
                style={{ marginRight: "50px" }}
              >
                Previous
              </CustomButton>
            ) : (
              <></>
            )
          }
          // prevStep={() => setIsOpen(false)}
          showNumber={false}
          getCurrentStep={(c) => setCurrentStep(c + 1)}
          disableInteraction={true}
        />
      )}
    </>
  );
}

export default GptTour;
