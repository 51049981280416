import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";

// const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: (props) => props.width - 60,
    flexShrink: 0,
  },
  drawerPaper: {
    width: (props) => props.width,
    display: "flex",
    justifyContent: "flex-start",
    border: "1px solid #EBF1F8",
    height: "100vh",
    // flexDirection: "column",
    // zIndex: 999999,
  },
  drawerContentWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // border: "3px solid yellow",
    height: "calc(100vh - 66px)",
    // flex:1
    // over:"auto"
  },
  // necessary for content to be below app bar
}));

export default function EsgGptMainLeft({
  drawerTabs,
  drawerSearchBar,
  drawerCompanyList,
  drawerMoreButton,
  drawerAddCompanyButton,
  children,
  ...props
}) {
  const classes = useStyles(props);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Toolbar />
      <div data-tut="esg-navigator" className={classes.drawerContentWrapper}>
        {/* {drawerTabs} */}
        {drawerAddCompanyButton}
        {drawerSearchBar}
        {drawerCompanyList && (
          <div
            // className="customScroll"
            style={{
              // height: window.height > 1000 ? "70vh" : "62vh",
              // flex:3,
              // flexBasis:"100px",
              // border:"solid red",
              // flex:"auto"
              display: "flex",
              flexDirection: "column",
              flex: 1, // This will make it take the remaining space
              overflowY: "scroll",
              // border: "solid red",
            }}
            data-tut="company-listing"
          >
            {drawerCompanyList}
          </div>
        )}
        {drawerMoreButton && (
          <div style={{ marginTop: "auto" }} data-tut="insight-guideline">
            {drawerMoreButton}
          </div>
        )}
        {children && <div style={{ marginTop: "auto" }}>{children}</div>}
      </div>
    </Drawer>
  );
}
