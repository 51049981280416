import React, { Suspense, useEffect, useRef, useState } from "react";

import {
  Box,
  Chip,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomButton from "../../../UI/button/button";
import CancelIcon from "@material-ui/icons/Cancel";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomTextfield from "../../../UI/textfield/textfield";
import countryList from "../../../../utils/countries/countryList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import { TurnoverYearPickerDialog } from "../riskAssessment";

import SectorIndustry from "./SectorIndustry";

import { brmRightStyles } from "./brmRightStyle";
import OptimizeInitialRender from "./OptimizeInitialRender";

function CompanyDetails(props) {
  const classes = brmRightStyles();
  const { brmData, login } = props;

  const [turnoverData, setTurnoverData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyLogoURI, setCompanyLogoURI] = useState("");
  const [turnoverPicker, setTurnoverPicker] = useState(false);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");
  const currentIndex = useRef({
    i: 0,
    revenue: true,
  });
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [alias, setAlias] = useState("");

  const [organizaitonDetailsObject, setOrganizaitonDetailsObject] = useState({
    name: "",
    operation: "",
    no_of_employees: "",
    isin: "",
    year_of_assessment: "",
    country_name: "",
    country_code: "",
    address: [
      { landMark: "", state: "", pinCode: "", companyAddress: "", country: "" },
    ],
    aliases: [],
  });

  const handleAddAlias = () => {
    setOrganizaitonDetailsObject((prev) => {
      const updatedAliases = prev.aliases ? [...prev.aliases] : [];
      if (alias && !updatedAliases.includes(alias)) {
        updatedAliases.push(alias);
      }
      return {
        ...prev,
        aliases: updatedAliases,
      };
    });
    setAlias("");
  };

  const handleDeleteAlias = (aliasToDelete) => () => {
    setOrganizaitonDetailsObject((prev) => ({
      ...prev,
      aliases: prev.aliases.filter((a) => a !== aliasToDelete),
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddAlias();
      event.preventDefault();
    }
  };

  useEffect(() => {
    brmData?.organizationDetails[0] &&
      setOrganizaitonDetailsObject(brmData?.organizationDetails[0]);
    setCompanyLogoURI(brmData?.organizationDetails[0]?.logo?.url);
    setTurnoverData(brmData?.organizationDetails[0]?.turnover || []);
    setRevenueData(brmData?.organizationDetails[0]?.revenue || []);
  }, [brmData?.organizationDetails]);

  useEffect(() => {
    if (
      brmData?.organizationDetails &&
      brmData?.organizationDetails[0].sectors &&
      brmData.organizationDetails[0].sectors.length > 0
    ) {
      let masterSectors = [...brmData.sectorIndustries];
      masterSectors.map((masterSec, masterSecIdx) => {
        return (
          brmData.organizationDetails[0].sectors.some(
            (sec) => sec.sector === masterSec._id
          ) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            brmData.organizationDetails[0].sectors.map((basicSec) =>
              basicSec.industry.map((basicInd) =>
                masterInd._id === basicInd
                  ? (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = true)
                  : (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = false)
              )
            )
          )
        );
      });

      setSectors(masterSectors);
    } else {
      setSectors(brmData.sectorIndustries);
    }
  }, [brmData.sectorIndustries, brmData.organizationDetails]);

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    let sectorL = JSON.parse(JSON.stringify(sectors));

    sectorL = sectorL.map((sect, i) => {
      sect.industry_data.map((ind, j) => {
        if (sectorIndex === i && industryIndex === j) {
          ind.isSelected = true;
        } else {
          ind.isSelected = false;
        }
        return ind;
      });

      return sect;
    });

    setSectors(sectorL);
  };

  const handleClearCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoURI("");
  };
  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  useEffect(() => {
    // console.log("new mount", organizaitonDetailsObject.year_of_assessment);
    organizaitonDetailsObject.year_of_assessment &&
      setStartYear(organizaitonDetailsObject.year_of_assessment?.split("-")[0]);
    organizaitonDetailsObject.year_of_assessment &&
      setEndYear(organizaitonDetailsObject.year_of_assessment?.split("-")[1]);
  }, [organizaitonDetailsObject.year_of_assessment]);

  useEffect(() => {
    const data = [];
    if (!organizaitonDetailsObject.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (organizaitonDetailsObject.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(
          organizaitonDetailsObject.year_of_assessment.split("-")[0] - 3 + i
        );
      }
      setyearRange(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDatePickerDialogOpen]);

  useEffect(() => {
    const data = [];
    if (true || "!companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (false && "companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        // data.push(companyDetails.yearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turnoverPicker]);
  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      setTStartYear(year);
      setTEndYear("");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  const handleTurnover = (i, revenue) => {
    setTurnoverPicker(true);
    currentIndex.current = { i, revenue: revenue };
  };
  const handleSetYear = () => {
    !currentIndex.current.revenue &&
      setTurnoverData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });
    currentIndex.current.revenue &&
      setRevenueData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });
    // setCompanyDetails((prev) => ({
    //   ...prev,
    //   yearOfAssessment: `${startYear}-${endYear}`,
    // }));
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };

  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };

  const gridItemsArray = [
    {
      xs: 12,
      md: 11,
      content: (
        <Box my={2}>
          <CustomTextfield
            name="compName"
            variant="outlined"
            fullWidth
            size="small"
            label="Company Name"
            value={organizaitonDetailsObject?.name}
            onChange={(e) => {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                name: e.target.value,
              }));
            }}
          />
        </Box>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <CustomTextfield
          label="Alias"
          variant="outlined"
          fullWidth
          size="small"
          value={alias}
          onChange={(e) => setAlias(e.target.value)}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleAddAlias}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Grid item xs={12}>
          <div style={{ marginTop: 16 }}>
            {organizaitonDetailsObject?.aliases?.map((alias, index) => (
              <Chip
                key={index}
                label={alias}
                onDelete={handleDeleteAlias(alias)}
                deleteIcon={<CloseIcon />}
                style={{ marginRight: 8, marginBottom: 8 }}
              />
            ))}
          </div>
        </Grid>
      ),
    },

    {
      xs: 1,
      md: 1,
      content: (
        <>
          {!organizaitonDetailsObject?.name && (
            <span
              style={{
                color: "red",
                padding: "0px 3px",
                display: "inline",
              }}
            >
              *
            </span>
          )}
        </>
      ),
    },
    {
      xs: 12,
      md: 8,
      content: (
        <>
          <Box my={2}>
            <Typography className={classes.headerText}>Company Logo</Typography>
          </Box>
          <Box my={2}>
            {!companyLogoURI ? (
              <>
                <input
                  id="company-logo"
                  type="file"
                  onChange={(event) => handleUploadCompanyLogo(event)}
                  style={{ display: "none" }}
                  cursor="pointer"
                />
                <label htmlFor="company-logo">
                  <div className={classes.logoSelecter}>
                    <AddCircleOutlineIcon
                      name="addLogoIcon"
                      fontSize="large"
                      className={classes.addIcon}
                    />
                  </div>
                </label>
              </>
            ) : (
              <div className={classes.companyLogo}>
                <img
                  alt="company-logo"
                  accept="image/png, image/gif, image/jpeg"
                  src={companyLogoURI}
                />
                <IconButton
                  onClick={handleClearCompanyLogo}
                  className={classes.ClearImage}
                >
                  <CancelIcon style={{ color: "gray" }} fontSize="small" />
                </IconButton>
              </div>
            )}
          </Box>
        </>
      ),
    },
    {
      xs: 12,
      md: 9,
      content: (
        <Box my={2}>
          <Typography className={classes.headerText}>
            Sector & Industry
            {sectors?.length === 0 && (
              <span
                style={{
                  color: "red",
                  padding: "0px 3px",
                  display: "inline",
                }}
              >
                *
              </span>
            )}
          </Typography>
        </Box>
      ),
    },
    {
      xs: 12,
      md: 3,
      content: (
        <CustomButton
          onClick={() => {
            setIsSectorDialogOpen(true);
          }}
          variant="text"
          color="primary"
        >
          Add Industry & Sector
        </CustomButton>
      ),
    },
    {
      xs: 12,
      md: 8,
      content: (
        <Box my={2}>
          {sectors.length !== 0 ? (
            sectors.map((sector) => (
              <div style={{ marginBottom: 8 }}>
                {sector.industry_data.some((inds) => inds.isSelected) && (
                  <>
                    <Typography className={classes.selectedSectorTitle}>
                      {sector.Sector}
                    </Typography>
                    {sector.industry_data.map(
                      (industry) =>
                        industry.isSelected && (
                          <Chip
                            className={classes.sectorChip}
                            label={industry.Industry}
                          />
                        )
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <Chip className={classes.sectorChip} label="Non Selected" />
          )}
        </Box>
      ),
    },
    {
      xs: 12,
      md: 12,
      content: (
        <>
          <Box
            my={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.headerText}>
              Revenue Data{" "}
              {revenueData.length === 0 && (
                <span style={{ color: "red", padding: "0px 3px" }}>*</span>
              )}
            </Typography>
            <CustomButton
              name="addBranch"
              size="medium"
              onClick={() => {
                const initialPeer = {
                  revenue: "",
                  year: "",
                  currency: "",
                  figures: "",
                };
                const copy = [...revenueData];
                copy.push(initialPeer);
                setRevenueData(copy);
                window.scrollTo({
                  top: 50,
                  behavior: "smooth",
                });
                // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
              }}
              variant="outlined"
              color="primary"
            >
              Add Revenue
            </CustomButton>
          </Box>
          {revenueData.map((revenue, i) => {
            return (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={5}>
                  <CustomTextfield
                    name="turnovers"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Add Revenue"
                    value={revenue.revenue}
                    onChange={(e) => {
                      const copy = [...revenueData];
                      copy[i].revenue = parseInt(e.target.value);
                      setRevenueData(copy);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  role="button"
                  onClick={() => handleTurnover(i, true)}
                >
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Year"
                    onChange={(e) => {
                      const copy = [...revenueData];
                      copy[i].year = e.target.value;
                      setRevenueData(copy);
                    }}
                    value={revenue.year}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <Icon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const copy = [...revenueData];
                      copy.splice(i, 1);
                      setRevenueData(copy);
                    }}
                  >
                    remove
                  </Icon>
                </Grid>
                <Grid item xs={5} md={5}>
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    value={revenue.currency}
                    options={["USD", "AED", "INR"]}
                    classes={{
                      endAdornment: classes.icon,
                      root: {},
                    }}
                    // inputProps={{
                    //   classes: {
                    //     icon: classes.icon,
                    //     root:{}
                    //   },
                    // }}
                    autoHighlight
                    getOptionLabel={(option) => `${option}`}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Currency"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const copy = [...revenueData];
                        copy[i].currency = newValue;
                        setRevenueData(copy);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={5} md={6}>
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    value={revenue.figures}
                    options={["Million", "Billion", "Crore"]}
                    classes={{
                      endAdornment: classes.icon,
                      root: {},
                    }}
                    // inputProps={{
                    //   classes: {
                    //     icon: classes.icon,
                    //     root:{}
                    //   },
                    // }}
                    autoHighlight
                    getOptionLabel={(option) => `${option}`}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Figures"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const copy = [...revenueData];
                        copy[i].figures = newValue;
                        setRevenueData(copy);
                        // setBranchCountry(newValue);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </>
      ),
    },
    {
      xs: 12,
      md: 12,
      content: (
        <>
          <Box
            my={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.headerText}>
              Turnover Data
            </Typography>
            <CustomButton
              name="addBranch"
              size="medium"
              onClick={() => {
                const initialPeer = {
                  turnover: "",
                  year: "",
                  currency: "",
                  figures: "",
                };
                const copy = [...turnoverData];
                copy.push(initialPeer);
                setTurnoverData(copy);
                window.scrollTo({
                  top: 50,
                  behavior: "smooth",
                });
                // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
              }}
              variant="outlined"
              color="primary"
            >
              Add Turnover
            </CustomButton>
          </Box>
          {turnoverData.map((turnover, i) => {
            return (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={5}>
                  <CustomTextfield
                    name="turnovers"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Add Turnover"
                    value={turnover.turnover}
                    onChange={(e) => {
                      const copy = [...turnoverData];
                      copy[i].turnover = parseInt(e.target.value);
                      setTurnoverData(copy);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  role="button"
                  onClick={() => handleTurnover(i, false)}
                >
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Year"
                    onChange={(e) => {
                      const copy = [...turnoverData];
                      copy[i].year = e.target.value;
                      setTurnoverData(copy);
                    }}
                    value={turnover.year}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <Icon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const copy = [...turnoverData];
                      copy.splice(i, 1);
                      setTurnoverData(copy);
                    }}
                  >
                    remove
                  </Icon>
                </Grid>
                <Grid item xs={5} md={5}>
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    value={turnover.currency}
                    options={["USD", "AED", "INR"]}
                    classes={{
                      endAdornment: classes.icon,
                      root: {},
                    }}
                    // inputProps={{
                    //   classes: {
                    //     icon: classes.icon,
                    //     root:{}
                    //   },
                    // }}
                    autoHighlight
                    getOptionLabel={(option) => `${option}`}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Currency"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const copy = [...turnoverData];
                        copy[i].currency = newValue;
                        setTurnoverData(copy);
                        // setBranchCountry(newValue);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={5} md={6}>
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    value={turnover.figures}
                    options={["Million", "Billion", "Crore"]}
                    classes={{
                      endAdornment: classes.icon,
                      root: {},
                    }}
                    // inputProps={{
                    //   classes: {
                    //     icon: classes.icon,
                    //     root:{}
                    //   },
                    // }}
                    autoHighlight
                    getOptionLabel={(option) => `${option}`}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Figures"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const copy = [...turnoverData];
                        copy[i].figures = newValue;
                        setTurnoverData(copy);
                        // setBranchCountry(newValue);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Autocomplete
          id="country-select-demo"
          size="small"
          value={{
            country_name: organizaitonDetailsObject?.country_name,
          }}
          options={brmData.countries}
          classes={{
            option: classes.option,
          }}
          style={{ marginTop: "20px" }}
          autoHighlight
          getOptionLabel={(option) => option.country_name}
          renderOption={(option) => (
            <React.Fragment>{option.country_name}</React.Fragment>
          )}
          renderInput={(params) => (
            <CustomTextfield
              {...params}
              label="Choose a country"
              variant="outlined"
              // value={organizaitonDetailsObject?.country_name}
              inputProps={{
                ...params.inputProps,
                // autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                country_name: newValue?.country_name,
                country: newValue?.country_code,
                // country: { country_code: newValue?.country_code, name: newValue?.country_name },
              }));
              // setBranchCountry(newValue);
            }
          }}
        />
      ),
    },
    {
      xs: 1,
      md: 1,
      content: (
        <>
          {!organizaitonDetailsObject?.country_name && (
            <span
              style={{
                color: "red",
                padding: "0px 3px",
                display: "inline",
              }}
            >
              *
            </span>
          )}
        </>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Box my={2}>
          <CustomTextfield
            name="NumOfEmployee"
            variant="outlined"
            fullWidth
            size="small"
            type="text"
            label="ISIN Number"
            onChange={(e) => {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                isin: e.target.value,
              }));
            }}
            value={organizaitonDetailsObject?.isin}
          />
        </Box>
      ),
    },
    {
      xs: 1,
      md: 1,
      content: (
        <>
          {!organizaitonDetailsObject?.isin && (
            <span
              style={{
                color: "red",
                padding: "0px 3px",
                display: "inline",
              }}
            >
              *
            </span>
          )}
        </>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Box
          my={2}
          onClick={() => setIsDatePickerDialogOpen(true)}
          style={{
            border: "1px solid #00000050",
            padding: 12,
            borderRadius: 5,
            cursor: "pointer",
            minHeight: 45,
          }}
        >
          {organizaitonDetailsObject.year_of_assessment ? (
            <Typography variant="body1" name="yearOfAssesment">
              {`${
                organizaitonDetailsObject.year_of_assessment.split("-")[0]
              } - ${
                organizaitonDetailsObject.year_of_assessment.split("-")[1]
              }`}
            </Typography>
          ) : (
            <Typography style={{ color: "#878282e6", fontFamily: "Roboto" }}>
              Year Of Assessment
            </Typography>
          )}
        </Box>
      ),
    },
    {
      xs: 1,
      md: 1,
      content: (
        <>
          {
            <span
              style={{
                color: "red",
                padding: "0px 3px",
                display: "inline",
              }}
            >
              *
            </span>
          }
        </>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Box my={2}>
          <CustomTextfield
            name="compOperation"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            size="small"
            label="Company Operation"
            onChange={(e) => {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                operation: e.target.value,
              }));
            }}
            value={organizaitonDetailsObject?.operation}
          />
        </Box>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Box my={2}>
          <CustomTextfield
            name="NumOfEmployee"
            variant="outlined"
            fullWidth
            size="small"
            type="text"
            label="Number of Employees"
            onChange={(e) => {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                no_of_employees: e.target.value,
              }));
            }}
            value={organizaitonDetailsObject?.no_of_employees}
          />
        </Box>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <>
          <Box my={2}>
            <Typography className={classes.headerText}>
              Company Address
            </Typography>
          </Box>
          <Box my={2}>
            <CustomTextfield
              name="compAddress"
              variant="outlined"
              fullWidth
              size="small"
              label="Street/Location"
              value={
                organizaitonDetailsObject?.address &&
                organizaitonDetailsObject?.address[0]?.companyAddress
              }
              onChange={(e) => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  address: [
                    {
                      ...prev?.address[0],
                      companyAddress: e.target.value,
                    },
                  ],
                }));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RoomOutlinedIcon fontSize="small" color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box my={2}>
            <CustomTextfield
              variant="outlined"
              name="compLandmark"
              fullWidth
              size="small"
              label="Landmark"
              value={
                organizaitonDetailsObject?.address &&
                organizaitonDetailsObject?.address[0]?.landMark
              }
              onChange={(e) => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  address: [
                    {
                      ...prev?.address[0],
                      landMark: e.target.value,
                    },
                  ],
                }));
              }}
            />
          </Box>
          <Box my={2}>
            <CustomTextfield
              name="compPincode"
              variant="outlined"
              fullWidth
              size="small"
              label="Pincode"
              type="number"
              value={
                organizaitonDetailsObject?.address &&
                organizaitonDetailsObject?.address[0]?.pinCode
              }
              onChange={(e) => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  address: [
                    {
                      ...prev?.address[0],
                      pinCode: e.target.value,
                    },
                  ],
                }));
              }}
            />
          </Box>
          <Box my={2}>
            <CustomTextfield
              name="compState"
              variant="outlined"
              fullWidth
              size="small"
              label="State"
              value={
                organizaitonDetailsObject?.address &&
                organizaitonDetailsObject?.address[0]?.state
              }
              onChange={(e) => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  address: [
                    {
                      ...prev?.address[0],
                      state: e.target.value,
                    },
                  ],
                }));
              }}
            />
          </Box>
          <Box my={2}>
            <Autocomplete
              name="compCountry"
              id="countryCode"
              options={countryList}
              getOptionLabel={(option) => option}
              renderOption={(option) => option}
              size="small"
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label="Country"
                  variant="outlined"
                />
              )}
              value={
                organizaitonDetailsObject?.address &&
                organizaitonDetailsObject?.address[0]?.country
                  ? organizaitonDetailsObject?.address[0]?.country
                  : ""
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setOrganizaitonDetailsObject((prev) => ({
                    ...prev,
                    address: [
                      {
                        ...prev?.address[0],
                        country: newValue,
                      },
                    ],
                  }));
                }
              }}
            />
          </Box>
        </>
      ),
    },
    {
      xs: 12,
      md: 12,
      content: (
        <>
          <CustomButton
            style={{
              float: "right",
              textTransform: "none",
              fontSize: "13px",
              color: "#3374B9",
            }}
            onClick={() => {
              props.updateOrganization(
                login.token,
                brmData?.organizationDetails[0]?._id
                  ? brmData?.organizationDetails[0]?._id
                  : localStorage.getItem("organization_id"),
                organizaitonDetailsObject?.name,
                organizaitonDetailsObject?.isin,
                organizaitonDetailsObject?.year_of_assessment,
                companyLogo,
                sectors,
                organizaitonDetailsObject?.operation,
                organizaitonDetailsObject?.no_of_employees,
                organizaitonDetailsObject?.address,
                brmData.sectorIndustries,
                revenueData.filter(
                  (d) =>
                    d.revenue !== "" &&
                    d.year !== "" &&
                    d.currency !== "" &&
                    d.figures !== ""
                ),
                turnoverData.filter(
                  (d) =>
                    d.turnover !== "" &&
                    d.year !== "" &&
                    d.currency !== "" &&
                    d.figures !== ""
                ),
                organizaitonDetailsObject?.country,
                organizaitonDetailsObject?.aliases
              );
            }}
          >
            Update Portfolio
          </CustomButton>
          <CustomButton
            onClick={() =>
              brmData?.organizationDetails[0] &&
              setOrganizaitonDetailsObject(brmData?.organizationDetails[0])
            }
            style={{
              float: "right",
              textTransform: "none",
              fontSize: "13px",
              color: "#3374B9",
            }}
          >
            Cancel
          </CustomButton>
        </>
      ),
    },
    // ... Add more objects for other Grid items
  ];

  return (
    <>
      {turnoverPicker && (
        <TurnoverYearPickerDialog
          isOpen={turnoverPicker}
          onCancel={handleCancelYear}
          onSet={handleSetYear}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={tstartYear}
          end={tendYear}
          yearRange={yearRange}
          onYearClick={handleTurnoverYearClick}
          range={trange}
          onClear={() => {
            setTEndYear("");
            setTStartYear("");
          }}
        />
      )}
      {isDatePickerDialogOpen && (
        <TurnoverYearPickerDialog
          isOpen={isDatePickerDialogOpen}
          onCancel={() => {
            setOrganizaitonDetailsObject((prev) => ({
              ...prev,
              year_of_assessment: "",
            }));
            organizaitonDetailsObject.year_of_assessment &&
              setStartYear(
                organizaitonDetailsObject.year_of_assessment.split("-")[0]
              );
            organizaitonDetailsObject.year_of_assessment &&
              setEndYear(
                organizaitonDetailsObject.year_of_assessment.split("-")[1]
              );
            setIsDatePickerDialogOpen(false);
          }}
          onSet={() => {
            setOrganizaitonDetailsObject((prev) => ({
              ...prev,
              year_of_assessment: `${startYear}-${endYear}`,
            }));
            setIsDatePickerDialogOpen(false);
          }}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={startYear}
          end={endYear}
          yearRange={yearRange}
          onYearClick={handleYearClick}
          range={range}
          onClear={() => {
            setStartYear("");
            setEndYear("");
          }}
        />
      )}
      {isSectorDialogOpen && (
        <SectorIndustry
          isSectorDialogOpen={isSectorDialogOpen}
          sectors={sectors}
          handleIndustryChange={handleIndustryChange}
          handleCloseSector={() => setIsSectorDialogOpen(false)}
        />
      )}

      <div style={{ height: "63vh" }} className={classes.mainDiv}>
        <Typography className={classes.cardTypography}>
          My Company details
        </Typography>

        <div className="scroll" style={{ height: "61vh" }}>
          <Grid container>
            <OptimizeInitialRender chunkSize={1}>
              {gridItemsArray.map((item, index) => (
                <Grid item key={index} xs={item.xs} md={item.md}>
                  {item.content}
                </Grid>
              ))}
            </OptimizeInitialRender>

            <Grid xs={7}></Grid>
            <Grid item xs={9}></Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
